const version = '1.6.2';
// const env = 'production';
const env = 'dev';
const dev = {
  apiServer: '/api/',
  nkapiServer: '/nkapi/',
  // nkapsServer: '/nkapi/',
};
const uat = {
  apiServer: 'http://nkapi.deepsingularity.cn/',
  nkapiServer: 'http://nkapsapi.deepsingularity.cn/',
  // nkapsServer: 'http://37miao.qicp.vip/',
  // nkapsServer: 'http://nkapsapi.deepsingularity.cn/',
};
const prod = {
  // apiServer: 'https://apsapi.nk001.com/',
  // nkapiServer: 'https://apsnkapi.nk001.com/nkapi',
  // nkapsServer: 'http://37miao.qicp.vip/',
  // nkapsServer: 'https://apsnkapi.nk001.com/nkaps',
  // apiServer: 'http://tyapsapi.nk001.com:8088/',
  // nkapiServer: 'http://tyapsnkapi.nk001.com:8088/nkapi',
  // apiServer: 'http://tyapsvue.nk001.com:8088/',
  // nkapiServer: 'http://tyapsvue.nk001.com:8088/nkapi/',
  apiServer: 'https://dy.bla.emergen.cn:18067',
  nkapiServer: 'https://dy.bla.emergen.cn:18067/nkapi/',
};
let url = '';
// 生产环境
if (env === 'dev') {
  console.log('==========devUrl=============');
  url = dev;
} else if (env === 'production' || location.href.includes('tyapsvue.nk001.com')) {
  // console.log = function() {}
  url = prod;
  console.log('==========productionUrl=============');
} else if (
  env === 'uat' ||
  location.href.includes('redmine.deepsingularity.net') ||
  location.href.includes('nkaps.deepsingularity.cn')
) {
  // UAT环境
  console.log('==========uatUrl=============');
  url = uat;
} else {
  console.log('==========devUrl==============');
  url = dev;
}
export default {
  url,
  version,
};
