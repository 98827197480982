import React, { useState, useEffect, componentWillUpdate } from 'react';
import G6 from '@antv/g6';
import './relationChart.scss';
String.prototype.pxWidth = function (font) {
  // re-use canvas object for better performance
  var canvas =
      String.prototype.pxWidth.canvas ||
      (String.prototype.pxWidth.canvas = document.createElement('canvas')),
    context = canvas.getContext('2d');

  font && (context.font = font);
  var metrics = context.measureText(this);

  return metrics.width * 1.25;
};
export default class InventoryPlanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplyPaths: [],
    };
  }
  formatData(data) {
    // data = require('./url.json')
    data = data || require('./url.json');
    let res = { nodes: [], edges: [] };
    data.forEach((item) => {
      let children = [];
      // if (item.buffers) {
      //   children = item.buffers.map((buffer) => {
      //     return {
      //       id: (Math.random() * 10).toString(),
      //       label: buffer[0],
      //       parent: item.id,
      //       type: 'buffer',
      //     };
      //   });
      // }
      res.nodes.push(
        {
          id: item.id,
          label: item.operation,
          type: 'rectNode',
        },
        ...children.map((child) => {
          return {
            id: child.id,
            label: child.label || '',
            type: child.type,
          };
        })
      );
      res.edges.push(
        {
          source: item.id,
          target: item.parent,
        },
        ...children.map((child) => {
          return {
            source: child.id,
            target: child.parent,
          };
        })
      );
    });
    console.log(res, '工艺路径数据组装');
    return res;
  }
  graph = {};

  componentDidMount() {
    const { params } = this.props;
    // Custom real node
    G6.registerNode('rectNode', {
      draw: (cfg, group) => {
        console.log(cfg, group, '=====');
        //最外面的那层
        const shape = group.addShape('rect', {
          draggable: true,
          attrs: {
            x: 0,
            y: 0,
            // width: 125,
            // height: 40,
            width: cfg.label.pxWidth(),
            height: cfg.height || 40,
            fill: '#f0f4fe', //填充色
            stroke: '#7ca1f8', //边框
            radius: 4,
          },
        });
        //里面的那层
        group.addShape('rect', {
          draggable: true,
          attrs: {
            x: 0,
            y: 0,
            width: cfg.label.pxWidth(),
            height: cfg.height || 40,
            fill: '#f0f4fe', //填充色
            stroke: '', //边框
            radius: 4,
          },
        });
        //文字
        group.addShape('text', {
          attrs: {
            y: 25,
            x: 4,
            lineHeight: 10,
            text: cfg.label,
            fill: '#000',
            // cursor: 'pointer',
            fontSize: 12,
            fill: '#000',
            // opacity: 0.85,
            fontWeight: 400,
          },
          draggable: true,
        });
        shape.set('capture', false);
        return shape;
      },
    });
    G6.registerNode('buffer', {
      draw: (cfg, group) => {
        //最外面的那层
        const shape = group.addShape('rect', {
          draggable: true,
          attrs: {
            x: 0,
            y: 0,
            // width: 125,
            // height: 40,
            width: cfg.label.pxWidth(),
            height: cfg.height || 40,
            fill: '#92feff', //填充色
            stroke: '#7ca1f8', //边框
            radius: 4,
          },
        });
        //里面的那层
        group.addShape('rect', {
          draggable: true,
          attrs: {
            x: 0,
            y: 0,
            width: cfg.label.pxWidth(),
            height: cfg.height || 40,
            fill: '#92feff', //填充色
            stroke: '', //边框
            radius: 4,
          },
        });
        //文字
        group.addShape('text', {
          attrs: {
            y: 25,
            x: 4,
            lineHeight: 10,
            text: cfg.label,
            fill: '#000',
            // cursor: 'pointer',
            fontSize: 12,
            fill: '#000',
            // opacity: 0.85,
            fontWeight: 400,
          },
          draggable: true,
        });
        shape.set('capture', false);
        return shape;
      },
    });
    const container = document.getElementById('container');
    const width = container.scrollWidth;
    const height = container.scrollHeight || 500;
    this.graph = new G6.Graph({
      container: 'container',
      width,
      height,
      edgeStrength: 500,
      modes: {
        default: ['drag-canvas', 'drag-node', 'zoom-canvas'], //zoom-canvas
      },
      layout: {
        type: 'dagre', // force
        rankdir: 'LR',
        align: 'DL',
        controlPoints: true,
        // linkDistance: 50, // 可选，边长
        preventOverlap: true, // 防止重叠
        // nodeSize: [350, 5],
        nodesepFunc: () => 10,
        ranksepFunc: (node) => {
          console.log(node.label.pxWidth());
          return node.label.pxWidth() - 100;
        },
        // gravity: 1, //重力的大小，影响布局的紧凑程度
        // nodeSpacing: (node) => {
        //   console.log(node, 'node');
        //   //
        //   return 1000;
        //   // return node.label.length * 5;
        // },
        // comboPadding: 15,
        sortByCombo: true,
      },
      animate: true,
      defaultNode: {
        size: [30, 20],
        type: 'rect',
      },
      defaultEdge: {
        size: 1,
        color: '#9c9c9c',
        style: {
          endArrow: {
            path: 'M 0,0 L 8,4 L 8,-4 Z',
            fill: '#9c9c9c',
          },
        },
      },
    });
    // graph.data(formatData(require('./url.json')));
    this.graph.data(this.formatData(params));
    // this.graph.data(this.data);
    this.graph.render();
    if (typeof window !== 'undefined')
      window.onresize = () => {
        if (!this.graph || this.graph.get('destroyed')) return;
        if (!container || !container.scrollWidth || !container.scrollHeight) return;
        this.graph.changeSize(container.scrollWidth, container.scrollHeight);
      };
  }
  componentWillReceiveProps(props) {
    this.setState({ supplyPaths: this.formatData(this.props.params) });
    this.graph.changeData(this.formatData(this.props.params));
  }

  data = {
    nodes: [
      {
        id: '0',
        label: '00000000000',
        type: 'rectNode',
      },
      {
        id: '1',
        label: '111111111111111111111qqqqqqqqqqqq',
        type: 'text',
      },
      {
        id: '2',
        label: '222222222222222222222222222222222',
      },
      {
        id: '3',
        label: '3',
      },
      {
        id: '4',
        label: '4',
      },
      {
        id: '5',
        label: '5',
      },
      {
        id: '6',
        label: '6',
      },
      {
        id: '7',
        label: '7',
      },
      {
        id: '8',
        label: '8',
      },
      {
        id: '9',
        label: '9',
      },
      {
        id: '10',
        label: '10',
      },
      {
        id: '11',
        label: '11',
      },
      {
        id: '12',
        label: '12',
      },
      {
        id: '13',
        label: '13',
      },
      {
        id: '14',
        label: '14',
      },
      {
        id: '15',
        label: '15',
      },
      {
        id: '16',
        label: '16',
      },
      {
        id: '17',
        label: '17',
      },
      {
        id: '18',
        label: '18',
      },
      {
        id: '19',
        label: '19',
      },
      {
        id: '20',
        label: '20',
      },
      {
        id: '21',
        label: '21',
      },
      {
        id: '22',
        label: '22',
      },
      {
        id: '23',
        label: '23',
      },
      {
        id: '24',
        label: '24',
      },
      {
        id: '25',
        label: '25',
      },
      {
        id: '26',
        label: '26',
      },
      {
        id: '27',
        label: '27',
      },
      {
        id: '28',
        label: '28',
      },
      {
        id: '29',
        label: '29',
      },
      {
        id: '30',
        label: '30',
      },
      {
        id: '31',
        label: '31',
      },
      {
        id: '32',
        label: '32',
      },
      {
        id: '33',
        label: '33',
      },
    ],
    edges: [
      {
        source: '0',
        target: '1',
      },
      {
        source: '0',
        target: '2',
      },
      {
        source: '0',
        target: '3',
      },
      {
        source: '0',
        target: '4',
      },
      {
        source: '0',
        target: '5',
      },
      {
        source: '0',
        target: '7',
      },
      {
        source: '0',
        target: '8',
      },
      {
        source: '0',
        target: '9',
      },
      {
        source: '0',
        target: '10',
      },
      {
        source: '0',
        target: '11',
      },
      {
        source: '0',
        target: '13',
      },
      {
        source: '0',
        target: '14',
      },
      {
        source: '0',
        target: '15',
      },
      {
        source: '0',
        target: '16',
      },
      {
        source: '2',
        target: '3',
      },
      {
        source: '4',
        target: '5',
      },
      {
        source: '4',
        target: '6',
      },
      {
        source: '5',
        target: '6',
      },
      {
        source: '7',
        target: '13',
      },
      {
        source: '8',
        target: '14',
      },
      {
        source: '9',
        target: '10',
      },
      {
        source: '10',
        target: '22',
      },
      {
        source: '10',
        target: '14',
      },
      {
        source: '10',
        target: '12',
      },
      {
        source: '10',
        target: '24',
      },
      {
        source: '10',
        target: '21',
      },
      {
        source: '10',
        target: '20',
      },
      {
        source: '11',
        target: '24',
      },
      {
        source: '11',
        target: '22',
      },
      {
        source: '11',
        target: '14',
      },
      {
        source: '12',
        target: '13',
      },
      {
        source: '16',
        target: '17',
      },
      {
        source: '16',
        target: '18',
      },
      {
        source: '16',
        target: '21',
      },
      {
        source: '16',
        target: '22',
      },
      {
        source: '17',
        target: '18',
      },
      {
        source: '17',
        target: '20',
      },
      {
        source: '18',
        target: '19',
      },
      {
        source: '19',
        target: '20',
      },
      {
        source: '19',
        target: '33',
      },
      {
        source: '19',
        target: '22',
      },
      {
        source: '19',
        target: '23',
      },
      {
        source: '20',
        target: '21',
      },
      {
        source: '21',
        target: '22',
      },
      {
        source: '22',
        target: '24',
      },
      {
        source: '22',
        target: '25',
      },
      {
        source: '22',
        target: '26',
      },
      {
        source: '22',
        target: '23',
      },
      {
        source: '22',
        target: '28',
      },
      {
        source: '22',
        target: '30',
      },
      {
        source: '22',
        target: '31',
      },
      {
        source: '22',
        target: '32',
      },
      {
        source: '22',
        target: '33',
      },
      {
        source: '23',
        target: '28',
      },
      {
        source: '23',
        target: '27',
      },
      {
        source: '23',
        target: '29',
      },
      {
        source: '23',
        target: '30',
      },
      {
        source: '23',
        target: '31',
      },
      {
        source: '23',
        target: '33',
      },
      {
        source: '32',
        target: '33',
      },
    ],
  };

  data2 = {
    nodes: [
      {
        id: 'b0',
        label: '生产1153#贵妃红西餐桌',
      },
      {
        id: 'b1',
        label: '生产1153#贵妃红西餐桌1',
      },
      {
        id: 'b2',
        label: '生产1153#贵妃红西餐桌2',
      },
      {
        id: 'b3',
        label: '生产1153#贵妃红西餐桌3',
      },
      {
        id: 'b4',
        label: '生产1153#贵妃红西餐桌4',
      },
      {
        id: 'b5',
        label: '生产1153#贵妃红西餐桌5',
      },
    ],
    edges: [
      {
        id: 'be1',
        source: 'b0',
        target: 'b1',
      },
      {
        id: 'be2',
        source: 'b0',
        target: 'b2',
      },
      {
        id: 'be3',
        source: 'b0',
        target: 'b3',
      },
      {
        id: 'be4',
        source: 'b0',
        target: 'b4',
      },
      {
        id: 'be5',
        source: 'b0',
        target: 'b5',
      },
    ],
  };
  render() {
    return <div className="supply-chart-container" id="container"></div>;
  }
}
