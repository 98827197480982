import React, { PureComponent } from 'react';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
const { SubMenu } = Menu;

class BaseMenu extends PureComponent {
  renderMenu(data) {
    // console.log(data, 'data');
    if (data.length < 0) {
      return false;
    }
    return data?.map((item) => {
      if (item.subMenus) {
        return (
          <SubMenu key={item.key} title={item.title}>
            {this.renderMenu(item.subMenus)}
          </SubMenu>
        );
      } else {
        return (
          <Menu.Item key={item.key} title={item.title}>
            <Link to={item.to}>{item.title}</Link>
          </Menu.Item>
        );
      }
    });
  }

  state = {
    selectedKey: '',
    openKey: '',
  };
  handleClick = (e) => {
    // console.log('click ', e);
    this.setState({
      selectedKey: e.key,
    });
  };
  componentDidMount() {
    // 刷新菜单更新默认状态
    const pathname = window.location.pathname;

    let path = pathname.split('/');
    if (pathname.split('/').length > 2) {
      path = path[path.length - 2];
      this.setState({
        openKey: path,
      });
    }
    let path2 = pathname.split('/');
    if (pathname.split('/').length > 2) {
      path2 = path2[path2.length - 1];
      this.setState({
        selectedKey: path2,
      });
    }
    // 浏览器前进后退按钮更新菜单状态
    // if (window.history && window.history.pushState) {
    //   window.onpopstate = function () {
    //     window.location.reload(); //刷新页面
    //   };
    // }
  }
  onOpenChange = (k) => {
    // 展开收缩更新状态
    if (k.length > 1) {
      let val = k[k.length - 1];
      this.setState({ openKey: val });
    } else {
      this.setState({ openKey: '' });
    }
  };

  render() {
    // console.log('This.props;',this.props);

    const { menusData, location } = this.props;
    let selectedKeyNew = location.pathname.split('/')[2];
    // console.log('this.state---------------------',this.state);
    // console.log('this.state.selectedKey-----',this.state.selectedKey);
    // console.log('location-----',location);
    if (this.state.selectedKey == 'orders' && location.pathname == '/sales/supplyPath') {
      this.setState({
        selectedKey: 'orders',
      });
    } else if (
      this.state.selectedKey == 'capacityDispatch' &&
      location.pathname == '/sales/supplyPath'
    ) {
      this.setState({
        selectedKey: 'capacityDispatch',
      });
    } else if (location.pathname == '/dashboard') {
      this.setState({
        selectedKey: '',
      });
    } else {
      this.setState({
        selectedKey: selectedKeyNew,
      });
    }

    return (
      <Menu
        key="Menu"
        mode="inline"
        theme="dark"
        // onOpenChange={handleOpenChange}
        // selectedKeys={selectedKeys}
        // className={cls}
        // {...props}
        openKeys={[this.state.openKey]}
        selectedKeys={[this.state.selectedKey]}
        onClick={this.handleClick}
        onOpenChange={this.onOpenChange}
      >
        {this.renderMenu(menusData)}
      </Menu>
    );
  }
}
export default withRouter(BaseMenu);
