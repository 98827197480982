import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import http from '../../../http';
import { getDomHeight } from '../../../utils/common';
import { Table, Button, Modal, Form, Input, InputNumber, Select, Breadcrumb, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import BreadNav from '../../../components/breadNav';

const { confirm } = Modal;

export default function Applier() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [whichItem, setWhichItem] = useState('');
  const [staticData, setStaticData] = useState([]);
  const [searchStatus, setSearchStatus] = useState('');

  const formRef = useRef(null);

  //表头
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      width: 200,
      sorter: (a, b) => String(a.name).length - String(b.name).length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '描述',
      dataIndex: 'description',
      width: 200,
    },
    {
      title: '更新时间',
      width: 200,
      dataIndex: 'lastmodified',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
      render: (text) => text.split('.')[0],
    },
    // {
    //   title: '操作',
    //   dataIndex: '',
    //   width: 200,
    //   render: (text) => (
    //     <div>
    //       <Button
    //         type="link"
    //         text={JSON.stringify(text)}
    //         onClick={(e) => {
    //           let text = e.currentTarget.getAttribute('text');
    //           text = JSON.parse(text);
    //           setTimeout(() => {
    //             formRef.current.setFieldsValue({
    //               name: text.name,
    //               description: text.description,
    //             });
    //           });
    //           console.log('编辑：', text);
    //           setOptType('edit');
    //           setWhichItem(text.name);
    //           setName(text.name);
    //           console.log('text.description: ', text.description);
    //           if (text.description === undefined) {
    //             setDescription('');
    //           } else {
    //             setDescription(text.description);
    //           }
    //           setIsModalVisible(true);
    //           setContent({ title: '编辑供应商信息' });
    //         }}
    //       >
    //         编辑
    //       </Button>
    //       &nbsp;&nbsp;
    //       <Button
    //         type="text"
    //         danger
    //         name={text.name}
    //         onClick={(e) => {
    //           let name = e.target.parentNode.name;
    //           setOptType('delete');
    //           showDeleteConfirm('delete', name);
    //         }}
    //       >
    //         删除
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showDeleteConfirm = (type, name) => {
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          deleteItem(name);
        } else if (type === 'deleteBatch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  //删除
  const deleteItem = (name) => {
    //console.log('deleteName: ', deleteName);
    http.post(
      `data/input/supplier/${name}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
          alert(response.data.status);
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    http.postJSON(`data/input/supplier/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        pageList();
      } else {
        message.error('操作失败！');
      }
    });
  };
  //同步
  const synchronization = () => {
    http.customPost('/nkapi/sync/supplier').then((res) => {
      if (res.data.Success === true) {
        message.success('同步成功！');
        pageList();
      } else {
        message.error('同步失败！');
      }
    });
  };

  const addApplier = () => {
    if (!name) {
      return message.error('名称不能为空！');
    }
    http.post(
      'data/input/supplier/add/',
      {
        _save: '保存',
        name,
        description,
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          setIsModalVisible(false);
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };

  const editApplier = () => {
    if (!name) {
      return message.error('名称不能为空！');
    }
    http.post(
      `detail/input/supplier/${whichItem}/`,
      {
        csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        _save: '保存',
        name,
        description,
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //列表
  // const pageList = () => {
  //   http.get(
  //     'data/input/supplier/',
  //     {
  //       format: 'json',
  //       _search: false,
  //       rows: 10,
  //       page: 1,
  //       sidx: 'lastmodified',
  //       sord: 'desc',
  //     },
  //     function (response) {
  //       setData(response.data.rows);
  //       setStaticData(response.data.rows);
  //       setSearchStatus('ready');
  //     }
  //   );
  // };
  const pageList = () => {
    http.postNkJSON(
      'supplier/getdatalist',
      {
        SortField: 'name',
        SortType: '',
      },
      function (response) {
        if (response.data && response.data.Success) {
          setData(response.data.Data);
          setStaticData(response.data.Data);
          setSearchStatus('ready');
        }else{
          return
        }
      }
    );
  };

  const handleOk = () => {
    if (optType === 'add') {
      addApplier();
    } else if (optType === 'edit') {
      editApplier();
    }
  };

  const onSearch = (value) => {
    if (searchStatus !== '') {
      const arr = [];
      let val = value.replace(/(^\s*)|(\s*$)/g, '');
      if (val !== '') {
        staticData.forEach((item) => {
          if (item.name.match(val) != null) {
            arr.push(item);
          }
        });
        setData(arr);
      } else {
        setData(staticData);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    pageList();
  }, []);

  return (
    <div className="applier modal-list">
      <Modal
        title={content.title}
        visible={isModalVisible}
        maskClosable={false}
        okText="提交"
        cancelText="取消"
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} layout="horizontal" ref={formRef}>
          {/* ref={formRef} */}
          <Form.Item label="名称">
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                formRef.current.setFieldsValue(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="描述">
            <Input.TextArea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                formRef.current.setFieldsValue(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <div className="layout-content">
        <div className="content-header">
          <BreadNav />
          <div style={{ lineHeight: '32px' }}>
            名称&nbsp;:&nbsp;&nbsp;
            <Input.Search
              className="search"
              placeholder="名称"
              enterButton="查询"
              allowClear={true}
              size="middle"
              onSearch={onSearch}
            />
          </div>
          <br />
          {/* <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => {
              setName('');
              setDescription('');

              setOptType('add');
              setContent({ title: '新建供应商' });
              setIsModalVisible(true);
            }}
          >
            &nbsp;新建
          </Button>{' '}
          &nbsp;&nbsp;
          <Button
            type="primary"
            shape="round"
            size="middle"
            danger
            onClick={() => {
              setOptType('deleteBatch');
              showDeleteConfirm('deleteBatch');
            }}
          >
            删除
          </Button>
          <br />
          <br /> */}
          <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => {
              synchronization();
            }}
          >
            同步
          </Button>
          <br />
          <br />
        </div>
        <div className="content-body">
          <Table
            // rowSelection={rowSelection}
            size="small"
            pagination={{ pageSize: 15 }}
            columns={columns}
            dataSource={data}
            style={{
              height: getDomHeight(),
            }}
            scroll={{
              y: getDomHeight(),
              // x: '100vw',
            }}
            rowKey={(record) => record.name}
            bordered
          />
        </div>
      </div>
    </div>
  );
}
