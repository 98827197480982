import { Form, Input, Button, Select } from 'antd';
import './index.scss';

const SearchGroup = (props) => {
  const [form] = Form.useForm();
  const { onSearch, items, className } = props;
  const layout = {
    layout: 'inline',
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = (values) => {
    const keys = Object.keys(values).map((key) => {
      return {
        key,
        value: values[key],
      };
    });
    onSearch(keys);
    // console.log(keys, 'keys');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const groups = items.map((item) => {
    return {
      label: item.label,
      name: item.name,
      ...item,
    };
  });
  // const renderItem = ()=>{
  //   return
  // }

  return (
    <div className={className}>
      <Form
        form={form}
        {...layout}
        name="basic"
        // initialValues={{
        //   remember: true,
        // }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {groups.map((group, index) => {
          let item = null;
          // select
          if (group.type === 'select') {
            item = (
              <Select style={{ width: 200 }} showSearch allowClear={true}>
                {group.options.map((item, index) => (
                  <Select.Option value={item.value} key={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            );
          } else {
            // input
            item = <Input allowClear />;
          }
          return (
            <Form.Item {...group} key={index}>
              {item}
            </Form.Item>
          );
        })}
        <Form.Item {...tailLayout} className="search-group-btn">
          <Button type="primary" htmlType="submit" shape="round" size="middle">
            查询
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SearchGroup;
