import basic from '../../assets/imgs/basic.svg';
import sales_active from '../../assets/imgs/sales_active.svg';
import capacity_active from '../../assets/imgs/capacity_active.svg';
import inventory_active from '../../assets/imgs/inventory_active.svg';
import manufacturing_active from '../../assets/imgs/manufacturing_active.svg';
import purchasing_active from '../../assets/imgs/purchasing_active.svg';
import system_active from '../../assets/imgs/system_active.svg';

const menus = [
  {
    key: 'baseInfo',
    title: '基础信息管理',
    icon: <img src={basic} className="menuicon" />,
    children: [
      {
        title: '日历管理',
        key: 'calendars',
        to: '/manufacturing/calendars',
        icon: 'icon-22gongyegongcheng_mokuaihua',
      },
      {
        title: '工作班别管理',
        key: 'workshift',
        to: '/manufacturing/workshift',
        icon: 'icon-19gongyegongcheng_kucundan',
      },
    ],
  },
  {
    key: 'BOM',
    title: '产业超级BOM',
    icon: <img src={inventory_active} className="menuicon" />,
    children: [
      {
        title: '客户',
        key: 'customer',
        to: '/sales/customer',
        icon: 'icon-08gongyegongcheng_zhinenghua',
      },
      {
        title: '工厂',
        key: 'location',
        to: '/sales/location',
        icon: 'icon-01gongyegongcheng_gongchang',
      },
      {
        title: '产品',
        key: 'production',
        to: '/sales/production',
        icon: 'icon-22gongyegongcheng_mokuaihua',
      },
      {
        title: '供应商',
        key: 'applier',
        to: '/purchasing/applier',
        icon: 'icon-23gongyegongcheng_shujuku',
      },
      {
        title: '产品供应商',
        key: 'productionApplier',
        to: '/purchasing/productionApplier',
        icon: 'icon-25gongyegongcheng_jiagong-26',
      },
      {
        title: '库存',
        key: 'inventory',
        to: '/inventory/inventory',
        icon: 'icon-19gongyegongcheng_kucundan',
      },
      {
        title: '工艺路径',
        key: 'execution',
        to: '/manufacturing/execution',
        icon: 'icon-10gongyegongcheng_redian',
      },
      {
        title: '物料清单',
        key: 'planningList',
        to: '/manufacturing/planningList',
        icon: 'icon-15gongyegongcheng_chuku',
      },
      {
        title: '资源清单',
        key: 'skill',
        to: '/manufacturing/skill',
        icon: 'icon-04gongyegongcheng_jiazai',
      },
      {
        title: '产品配送',
        key: 'distribution',
        to: '/inventory/distribution',
        icon: 'icon-18gongyegongcheng_dingwei',
      },
      {
        title: '资源',
        key: 'resource',
        to: '/capacity/resource',
        icon: 'icon-12gongyegongcheng_lingjianguanli',
      },
      {
        title: '设备',
        key: 'device',
        to: '/devicePeople/device',
        icon: 'icon-shebei',
      },
      {
        title: '人员',
        key: 'people',
        to: '/devicePeople/people',
        icon: 'icon-user',
      },
    ],
  },
  {
    key: 'dispatchEngine',
    title: '调度排程优化引擎',
    icon: <img src={manufacturing_active} className="menuicon" />,
    children: [
      {
        title: '产业链调度优化',
        key: 'whatif',
        to: '/manufacturing/whatif',
        icon: 'icon-20gongyegongcheng_diaochuang',
      },
      {
        title: '销售订单',
        key: 'orders',
        to: '/sales/orders',
        icon: 'icon-16gongyegongcheng_wangluohua',
      },
    ],
  },
  {
    key: 'productionPlan',
    title: '产能计划管理',
    icon: <img src={sales_active} className="menuicon" />,
    children: [
      {
        title: '产能调度计划',
        key: 'capacityDispatch',
        to: '/sales/capacityDispatch',
        icon: 'icon-13gongyegongcheng_xinxi',
      },
      {
        title: '产能分析',
        key: 'planningGantt',
        to: '/manufacturing/planningGantt',
        icon: 'icon-02gongyegongcheng_jiagong',
      },
    ],
  },
  {
    key: 'materialPlan',
    title: '物料计划管理',
    icon: <img src={capacity_active} className="menuicon" />,
    children: [
      {
        title: '物料调度计划',
        key: 'purchaseorder',
        to: '/purchasing/purchaseorder',
        icon: 'icon-11gongyegongcheng_yuancheng',
      },
      {
        title: '库存报告',
        key: 'report',
        to: '/inventory/report',
        icon: 'icon-14gongyegongcheng_zhijiananquan',
      },
      {
        title: '库存计划报表',
        key: 'inventoryPlanning',
        to: '/manufacturing/inventoryPlanning',
        icon: 'icon-07gongyegongcheng_linggan',
      },
    ],
  },
  {
    key: 'productionLine',
    title: '生产计划管理',
    icon: <img src={purchasing_active} className="menuicon" />,
    children: [
      {
        title: '生产计划',
        key: 'productionList',
        to: '/manufacturing/productionList',
        icon: 'icon-06gongyegongcheng_jiqihua',
      },
      {
        title: '计划监控',
        key: 'resourceReport',
        to: '/capacity/resourceReport',
        icon: 'icon-09gongyegongcheng_chuanshu',
      },
      {
        title: '物流中心调度',
        key: 'logisticsCenter',
        to: '/productionLine/logisticsCenter',
        icon: 'icon-03gongyegongcheng_yunhuaguanli',
      },
    ],
  },
  {
    key: 'system',
    title: '系统设置',
    icon: <img src={system_active} className="menuicon" />,
    children: [
      {
        title: '用户管理',
        key: 'user',
        to: '/system/user',
        icon: 'icon-05gongyegongcheng_lingjianfenxi',
      },
      {
        title: '导入配置',
        key: 'importConfig',
        to: '/system/importConfig',
        icon: 'icon-13gongyegongcheng_xinxi',
      },
    ],
  },
];

export default menus;
