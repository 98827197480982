import React, { useState, useEffect } from 'react';
import http from '../../../http';
import { Table, Button, Modal, Form, Input, Select, Breadcrumb, message, InputNumber } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import SearchGroup from '../../../components/SearchGroup';
import { searchEngine, getDomHeight } from '../../../utils/common';
import moment from 'moment';
import BreadNav from '../../../components/breadNav';

import './index.scss';

const { confirm } = Modal;

export default function Planninglist() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [operation, setOperation] = useState('');
  const [item, setItem] = useState('');
  const [type, setType] = useState('');
  const [search, setSearch] = useState('');
  const [quantity, setQuantity] = useState('');
  const [name, setName] = useState('');
  const [priority, setPriority] = useState('');
  const [effective_start_0, setEffective_start_0] = useState('');
  const [effective_start_1, setEffective_start_1] = useState('');
  const [effective_end_0, setEffective_end_0] = useState('');
  const [effective_end_1, setEffective_end_1] = useState('');
  const [whichItem, setWhichItem] = useState('');
  const [itemList, setItemList] = useState([]);
  const [gyList, setGyList] = useState([]);
  const [operationKeyword, setOperationKeyword] = useState('');
  const [itemKeyword, setItemKeyword] = useState('');
  //表头
  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
      width: 80,
    },
    {
      title: '工艺路径',
      dataIndex: 'operation',
      width: 200,
      sorter: (a, b) => a.operation.localeCompare(b.operation),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '产品',
      dataIndex: 'item',
      width: 200,
      sorter: (a, b) => a.item.localeCompare(b.item),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '类型',
      width: 100,
      dataIndex: 'type',
      render: (text) => {
        switch (text) {
          case 'start':
            return '开始';
          case 'end':
            return '结束';
          case 'transfer_batch':
            return '批量转移';
          default:
            return '';
        }
      },
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      width: 100,
      render: (value) => {
        return parseFloat(value).toFixed(2);
      },
    },
    // {
    //   title: '生效开始时间',
    //   dataIndex: 'effective_start',
    // },
    // {
    //   title: '生效结束时间',
    //   dataIndex: 'effective_end',
    // },
    {
      title: '名称',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: '优先级',
      width: 100,
      dataIndex: 'priority',
    },
    {
      title: '搜索模式',
      width: 100,
      dataIndex: 'search',
      render: (value) => {
        const target = searchModes.find((mode) => mode.value === value) || {};
        return target.label;
      },
    },
    {
      title: '更新时间',
      width: 200,
      dataIndex: 'lastmodified',
      sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
      render: (text) => text.split('.')[0],
    },
    {
      title: '操作',
      dataIndex: '',
      width: 200,
      render: (text) => (
        <div>
          <Button
            type="link"
            text={JSON.stringify(text)}
            onClick={(e) => {
              let text = e.currentTarget.getAttribute('text');
              text = JSON.parse(text);
              setEditValue(text, 'edit');
            }}
          >
            编辑
          </Button>
          &nbsp;&nbsp;
          <Button
            type="text"
            danger
            name={text.name}
            onClick={(e) => {
              let name = e.target.parentNode.name;
              setOptType('delete');
              showDeleteConfirm('delete', text.id);
            }}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];

  const searchModes = [
    { value: 'PRIORITY', label: '优先级' },
    { value: 'MINCOST', label: '最低成本' },
    { value: 'MINPENALTY', label: '最小罚款' },
    { value: 'MINCOSTPENALTY', label: '最低成本加罚款' },
  ];

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  // 产品列表
  const getProductionList = () => {
    http.get(
      'data/input/item/',
      {
        format: 'json',
        rows: 10000,
        page: 1,
        filters: {
          groupOp: 'AND',
          rules: [
            { field: 'owner', op: 'ne', data: '全部' },
            { field: 'name', op: 'ne', data: '全部' },
            { field: 'name', op: 'ne', data: 'All items', filtercount: 0 },
          ],
        },
      },
      function (response) {
        setItemList(response.data.rows);
      }
    );
  };

  const getGyList = () => {
    http.get(
      'data/input/operation/',
      {
        format: 'json',
        rows: 10000,
        page: 1,
      },
      function (response) {
        setGyList(response.data.rows);
      }
    );
  };
  //删除提示
  const showDeleteConfirm = (optType, id) => {
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (optType === 'delete') {
          deleteItem(id);
        } else if (optType === 'deleteBatch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  //编辑
  const editItem = () => {
    console.log('name: ', name);
    http.post(
      `detail/input/operationmaterial/${whichItem}/`,
      {
        _save: '保存',
        name,
        operation,
        item,
        type,
        quantity,
        priority,
        search,
        effective_start_0,
        effective_start_1,
        effective_end_0,
        effective_end_1,
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //删除
  const deleteItem = (id) => {
    http.post(
      `data/input/operationmaterial/${id}/delete/`,
      {
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    console.log(selectedRowKeys);
    http.postJSON(
      `data/input/operationmaterial/`,
      [{ delete: selectedRowKeys }],
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //表单提交
  const handleOK = () => {
    if (operation === '') {
      return message.error('工艺路径不能为空');
    }
    if (item === '') {
      return message.error('产品不能为空');
    }
    if (type === '') {
      return message.error('类型不能为空');
    }
    if (quantity == 'NaN') {
      return message.error('数量不能为空');
    }

    if (optType === 'add') {
      addPlanninglist();
    } else if (optType === 'edit') {
      editItem();
    }
  };

  const addPlanninglist = () => {
    http.post(
      'data/input/operationmaterial/add/',
      {
        _save: '保存',
        operation,
        item,
        type,
        quantity,
        name,
        priority,
        search,
        effective_start_0,
        effective_start_1,
        effective_end_0,
        effective_end_1,
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('添加成功');
          pageList();
        } else {
          alert(response.data.status);
        }
      }
    );
  };

  const pageList = () => {
    http.get(
      'data/input/operationmaterial/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        setData(response.data.rows);
        setDataCopy(response.data.rows);
      }
    );
  };
  const setEditValue = (item, type) => {
    console.log(item, type, '========');

    setContent({ title: type === 'edit' ? '编辑信息' : '新增信息' });
    setOptType(type);
    setOperation(item.operation || '');
    setName(item.name || '');
    setWhichItem(item.id || '');
    setItem(item.item || '');
    setType(item.type || '');
    setSearch(item.search || '');
    setQuantity(parseFloat(item.quantity).toFixed(2) || '');
    setPriority(item.priority || '');
    setEffective_start_0(item.effective_start_0 || '');
    setEffective_start_1(item.effective_start_1 || '');
    setEffective_end_0(item.effective_end_0 || '');
    setEffective_end_1(item.effective_end_1 || '');
    setIsModalVisible(true);
  };

  const onSearch = (keys) => {
    if (keys.every((key) => !key.value)) {
      pageList();
    } else {
      const arr = searchEngine(dataCopy, keys);
      setData(arr);
    }
  };

  useEffect(() => {
    pageList();
    getProductionList();
    getGyList();
  }, []);
  return (
    <div className="planninglist layout-content">
      <Modal
        title={content.title}
        visible={isModalVisible}
        maskClosable={false}
        okText="提交"
        cancelText="取消"
        onOk={handleOK}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{
            priority: 10,
            status: 'open',
          }}
        >
          <Form.Item label="工艺路径">
            <Select
              showSearch
              onSelect={(value) => {
                setOperation(value);
              }}
              value={operation}
            >
              {gyList &&
                gyList.map((item, index) => (
                  <Select.Option key={index} value={item.name} key={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="产品">
            <Select
              showSearch
              onSelect={(value) => {
                setItem(value);
              }}
              value={item}
            >
              {itemList &&
                itemList.map((item, index) => (
                  <Select.Option key={index} value={item.name} key={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="类型">
            <Select
              onSelect={(value) => {
                setType(value);
              }}
              value={type}
            >
              <Select.Option value="start">开始</Select.Option>
              <Select.Option value="end">结束</Select.Option>
              {/* <Select.Option value="transfer_batch">批量转移</Select.Option> */}
            </Select>
          </Form.Item>
          <Form.Item label="数量">
            <InputNumber
              value={Number(quantity) ? Number(quantity) : '-'}
              defaultValue={`-`}
              onChange={(value) => {
                setQuantity(value);
              }}
              style={{ display: type === 'start' ? 'block' : 'none' }}
            />
            <InputNumber
              value={Number(quantity)}
              onChange={(value) => {
                setQuantity(value);
              }}
              style={{ display: type !== 'start' ? 'block' : 'none' }}
            />
          </Form.Item>
          <Form.Item label="名称">
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="优先级">
            <Input
              value={priority}
              onChange={(e) => {
                setPriority(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="搜索模式">
            <Select
              allowClear
              onSelect={(value) => {
                setSearch(value);
              }}
              value={search}
              onChange={() => {
                setSearch('');
              }}
            >
              {searchModes.map((mode, index) => (
                <Select.Option key={index} value={mode.value}>
                  {mode.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item label="生效开始日期">
            <ConfigProvider locale={locale}>
              <DatePicker
                format={dateFormat}
                onChange={(date, dateStr) => {
                  setEffective_start_0(dateStr);
                }}
              />
              <TimePicker
                format={format}
                onChange={(date, dateStr) => {
                  setEffective_start_1(dateStr);
                }}
              />
            </ConfigProvider>
          </Form.Item>
          <Form.Item label="生效结束日期">
            <ConfigProvider locale={locale}>
              <DatePicker
                format={dateFormat}
                onChange={(date, dateStr) => {
                  setEffective_end_0(dateStr);
                }}
              />
              <TimePicker
                format={format}
                onChange={(date, dateStr) => {
                  setEffective_end_1(dateStr);
                }}
              />
            </ConfigProvider>
          </Form.Item> */}
        </Form>
      </Modal>
      <div className="content-header">
        <BreadNav />
        <SearchGroup
          items={[
            {
              label: '工艺路径',
              name: 'operation',
            },
            {
              label: '产品',
              name: 'item',
            },
          ]}
          onSearch={onSearch}
        ></SearchGroup>
        <br />
        <Button
          type="primary"
          shape="round"
          size="middle"
          onClick={() => {
            setEditValue({}, 'add');
            setIsModalVisible(true);
          }}
        >
          &nbsp;新建
        </Button>{' '}
        &nbsp;&nbsp;
        <Button
          type="primary"
          shape="round"
          size="middle"
          danger
          onClick={() => {
            setOptType('deleteBatch');
            showDeleteConfirm('deleteBatch');
          }}
        >
          删除
        </Button>
        <br />
        <br />
      </div>
      <div className="content-body">
        <Table
          size="small"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.id}
          bordered
          pagination={{ pageSize: 15 }}
          style={{
            height: getDomHeight(),
          }}
          scroll={{
            y: getDomHeight(),
            // x: '100vw',
          }}
        />
      </div>
    </div>
  );
}
