import React, { useState, useEffect } from 'react';
import http from '../../../http';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { Table, Button, Modal, Form, Input, InputNumber, Select, Breadcrumb, message } from 'antd';
import SearchGroup from '../../../components/SearchGroup';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import {
  requiredLabel,
  getFilters,
  timeToDuration,
  searchEngine,
  getDomHeight,
} from '../../../utils/common';
import BreadNav from '../../../components/breadNav';
const { confirm } = Modal;

export default function Execution() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [item, setItem] = useState('');
  const [location, setLocation] = useState('');
  const [duration, setDuration] = useState('');
  const [duration_per, setDuration_per] = useState('');
  const [sizeminimum, setSizeminimum] = useState();
  const [sizemultiple, setSizemultiple] = useState('');
  const [sizemaximum, setSizemaximum] = useState('');
  const [owner, setOwner] = useState('');
  const [priority, setPriority] = useState();
  const [cost, setCost] = useState('');
  const [effective_start_0, setEffective_start_0] = useState('');
  const [effective_start_1, setEffective_start_1] = useState('');
  const [effective_end_0, setEffective_end_0] = useState('');
  const [effective_end_1, setEffective_end_1] = useState('');
  const [itemList, setItemList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [calendarList, setCalendarList] = useState([]);
  const [whichItem, setWhichItem] = useState('');
  const [day, setDay] = useState('0');
  const [hour, setHour] = useState('0');
  const [minute, setMinute] = useState('0');
  const [second, setSecond] = useState('0');

  const [dayPer, setDayPer] = useState('0');
  const [hourPer, setHourPer] = useState('0');
  const [minutePer, setMinutePer] = useState('0');
  const [secondPer, setSecondPer] = useState('0');

  //表头
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      width: 200,
      fixed: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '描述',
      dataIndex: 'description',
      width: 200,
    },
    {
      title: '类型',
      dataIndex: 'type',
      width: 200,
      render: (value) => {
        const type = {
          fixed_time: '固定时间',
          time_per: '单位时间',
          routing: '串连工序（顺序号）',
          alternate: '备选工序（优先级）',
          split: '并连工序（%)',
        };
        return <span>{type[value]}</span>;
      },
    },
    {
      title: '产品',
      dataIndex: 'item',
      width: 200,
      sorter: (a, b) => a.item.localeCompare(b.item),
      sortDirections: ['descend', 'ascend'],
      render: (value) => {
        return <span>{value}</span>;
      },
    },
    {
      title: '工厂',
      dataIndex: 'location',
      width: 200,
      sorter: (a, b) => a.location.localeCompare(b.location),
      sortDirections: ['descend', 'ascend'],
      render: (value) => {
        return <span>{value}</span>;
      },
    },
    {
      title: '持续时间',
      dataIndex: 'duration',
      width: 200,
      render: (value) => {
        return <span>{value ? timeToDuration(value) : ''}</span>;
      },
    },
    {
      title: '每单位的持续时间',
      dataIndex: 'duration_per',
      width: 200,
      render: (value) => {
        return <span>{value ? timeToDuration(value) : ''}</span>;
      },
    },
    {
      title: '最小量',
      dataIndex: 'sizeminimum',
      width: 100,
      render: (value) => {
        return value && parseFloat(value).toFixed(2);
      },
    },
    {
      title: '最大量',
      dataIndex: 'sizemaximum',
      width: 100,
      render: (value) => {
        return value && parseFloat(value).toFixed(2);
      },
    },
    {
      title: '大小的倍数',
      dataIndex: 'sizemultiple',
      width: 100,
      render: (value) => {
        return value && parseFloat(value).toFixed(2);
      },
    },
    {
      title: '父名称',
      dataIndex: 'owner',
      width: 200,
      render: (value) => {
        return <span>{value}</span>;
      },
    },
    {
      title: '优先级',
      dataIndex: 'priority',
      width: 100,
      render: (value) => {
        return <span>{value}</span>;
      },
    },
    {
      title: '成本',
      dataIndex: 'cost',
      width: 100,
      render: (value) => {
        return value && parseFloat(value).toFixed(2);
      },
    },
    {
      title: '更新时间',
      dataIndex: 'lastmodified',
      width: 200,
      sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
      render: (text) => text.split('.')[0],
    },
    {
      title: '操作',
      dataIndex: '',
      width: 200,
      fixed: 'right',
      render: (text) => (
        <div>
          <Button
            type="link"
            text={JSON.stringify(text)}
            onClick={(e) => {
              let text = e.currentTarget.getAttribute('text');
              text = JSON.parse(text);
              setEditValue(text, 'edit');
              setIsModalVisible(true);
            }}
          >
            编辑
          </Button>
          &nbsp;&nbsp;
          <Button
            type="text"
            danger
            name={text.name}
            onClick={(e) => {
              let name = e.target.parentNode.name;
              setOptType('delete');
              showDeleteConfirm('delete', name);
            }}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];

  const setEditValue = (item, type) => {
    console.log(item, type, '========');

    if (item.cost) {
      item.cost = parseFloat(item.cost).toString();
    }
    if (item.sizemultiple) {
      item.sizemultiple = parseFloat(item.sizemultiple).toString();
    }
    if (item.sizeminimum) {
      item.sizeminimum = parseFloat(item.sizeminimum).toString();
    }
    if (item.sizemaximum) {
      item.sizemaximum = parseFloat(item.sizemaximum).toString();
    }

    setContent({ title: type === 'edit' ? '编辑信息' : '新增信息' });
    setOptType(type);
    setName(item.name || '');
    setWhichItem(item.name || '');
    setDescription(item.description || '');
    setType(item.type || '');
    setItem(item.item || '');
    setLocation(item.location || '');
    setDuration(item.duration || '');
    setDuration_per(item.duration_per || '');
    setSizeminimum(item.sizeminimum || '');
    setSizemultiple(item.sizemultiple || '');
    setSizemaximum(item.sizemaximum || '');
    setOwner(item.owner || '');
    setPriority(item.priority || '');
    setCost(item.cost || '');
    setEffective_start_0(item.effective_start_0 || '');
    setEffective_start_1(item.effective_start_1 || '');
    setEffective_end_0(item.effective_end_0 || '');
    setEffective_end_1(item.effective_end_1 || '');

    setDay(parseInt(item.duration / 86400) || '');
    setHour(parseInt((item.duration / 3600) % 24) || '');
    setMinute(parseInt((item.duration / 60) % 60) || '');
    setSecond(parseInt(item.duration % 60) || '');

    setDayPer(parseInt(item.duration_per / 86400) || '');
    setHourPer(parseInt((item.duration_per / 3600) % 24) || '');
    setMinutePer(parseInt((item.duration_per / 60) % 60) || '');
    setSecondPer(parseInt(item.duration_per % 60) || '');
  };

  const onSearch = (keys) => {
    if (keys.every((key) => !key.value)) {
      pageList();
    } else {
      const arr = searchEngine(dataCopy, keys);
      setData(arr);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showDeleteConfirm = (optType, name) => {
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (optType === 'delete') {
          deleteItem(name);
        } else if (optType === 'deleteBatch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const addExecution = () => {
    http.post(
      'data/input/operation/add/',
      {
        _save: '保存',
        name,
        description,
        type,
        item,
        location,
        duration: (day * 86400 + hour * 3600 + minute * 60 + second * 1).toString(),
        duration_per: (dayPer * 86400 + hourPer * 3600 + minutePer * 60 + secondPer * 1).toString(),
        sizeminimum,
        sizemultiple,
        sizemaximum,
        owner,
        priority,
        cost,
        effective_start_0,
        effective_start_1,
        effective_end_0,
        effective_end_1,
      },
      function (response) {
        console.log(response, '=====');
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('添加成功');
          pageList();
        } else {
          console.log(response, '=====');
          message.error('创建失败，请检查输入是否正确或名称重复');
        }
      }
    );
  };

  const pageList = (keywords) => {
    const data = keywords || {};
    const params = {
      format: 'json',
      _search: data._search || false,
      filters: getFilters(data.rules),
      rows: 10,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
    };
    http.get('data/input/operation/', params, function (response) {
      setData(response.data.rows);
      setDataCopy(response.data.rows);
    });
  };

  //编辑
  const editItem = () => {
    console.log('name: ', name);
    const url = `detail/input/operation/${whichItem}/`;
    http.post(
      encodeURIComponent(url),
      {
        _save: '保存',
        name,
        description,
        type,
        item,
        location,
        duration: (day * 86400 + hour * 3600 + minute * 60 + second * 1).toString(),
        duration_per: (dayPer * 86400 + hourPer * 3600 + minutePer * 60 + secondPer * 1).toString(),
        sizeminimum,
        sizemultiple,
        sizemaximum,
        owner,
        priority,
        cost,
        effective_start_0,
        effective_start_1,
        effective_end_0,
        effective_end_1,
      },
      function (response) {
        console.log(response, 'response');
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //删除
  const deleteItem = (name) => {
    http.post(
      `data/input/operation/${name}/delete/`,
      {
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    console.log(selectedRowKeys);
    http.postJSON(`data/input/operation/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        pageList();
      } else {
        message.error('操作失败！');
      }
    });
  };
  //表单提交
  const handleOK = () => {
    if (optType === 'add') {
      addExecution();
    } else if (optType === 'edit') {
      editItem();
    }
  };
  // 工厂列表
  const getLocationList = () => {
    http.get(
      'data/input/location/',
      {
        format: 'json',
        rows: 10000,
        page: 1,
      },
      function (response) {
        setLocationList(response.data.rows);
      }
    );
  };
  // 产品列表
  const getProductionList = () => {
    http.get(
      'data/input/item/',
      {
        format: 'json',
        rows: 10000,
        page: 1,
        filters: {
          groupOp: 'AND',
          rules: [
            { field: 'owner', op: 'ne', data: '全部' },
            { field: 'name', op: 'ne', data: '全部' },
            { field: 'name', op: 'ne', data: 'All items', filtercount: 0 },
          ],
        },
      },
      function (response) {
        setItemList(response.data.rows);
      }
    );
  };
  //日历列表
  const getCalendarList = () => {
    http.get(
      'data/input/operation/',
      {
        format: 'json',
      },
      function (response) {
        setCalendarList(response.data.rows);
      }
    );
  };
  useEffect(() => {
    pageList();
    getLocationList();
    getProductionList();
    getCalendarList();
  }, []);

  return (
    <div className="execution layout-content">
      <Modal
        title={content.title}
        visible={isModalVisible}
        maskClosable={false}
        okText="提交"
        cancelText="取消"
        onOk={handleOK}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{
            priority: 10,
            status: 'open',
          }}
        >
          <Form.Item label={requiredLabel('名称')}>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="描述">
            <Input.TextArea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="类型">
            <Select
              allowClear={true}
              onSelect={(value) => {
                setType(value);
              }}
              value={type}
              onChange={() => {
                setType('');
              }}
            >
              {/* <Select.Option value="" selected="">
                ---------
              </Select.Option> */}
              <Select.Option value="fixed_time">固定时间</Select.Option>
              <Select.Option value="time_per">单位时间</Select.Option>
              {/* <Select.Option value="routing">串连工序（顺序号）</Select.Option>
              <Select.Option value="alternate">备选工序（优先级）</Select.Option>
              <Select.Option value="split">并连工序（%）</Select.Option> */}
            </Select>
          </Form.Item>
          <Form.Item label="产品">
            <Select
              showSearch
              allowClear={true}
              onSelect={(value) => {
                setItem(value);
              }}
              value={item}
              onChange={() => {
                setItem('');
              }}
            >
              {itemList &&
                itemList.map((item) => (
                  <Select.Option value={item.name} key={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label={requiredLabel('工厂')}>
            <Select
              showSearch
              onSelect={(value) => {
                setLocation(value);
              }}
              value={location}
            >
              {locationList &&
                locationList.map((item) => (
                  <Select.Option value={item.name} key={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {/* <Form.Item label="持续时间(秒)">
            <Input
              value={duration}
              onChange={(e) => {
                setDuration(e.target.value);
              }}
            />
          </Form.Item> */}
          <Form.Item label="持续时间">
            <InputNumber
              value={day}
              max="10000"
              min="0"
              onChange={(value) => {
                setDay(value);
              }}
            />
            （天）
            <InputNumber
              value={hour}
              max="23"
              min="0"
              onChange={(value) => {
                setHour(value);
              }}
            />
            （时）
            <InputNumber
              value={minute}
              max="59"
              min="0"
              style={{ marginTop: '10px' }}
              onChange={(value) => {
                setMinute(value);
              }}
            />
            （分）
            <InputNumber
              value={second}
              max="59"
              min="0"
              onChange={(value) => {
                setSecond(value);
              }}
            />
            （秒）
          </Form.Item>
          {/* <Form.Item label="每单位的持续时间(秒)">
            <Input
              value={duration_per}
              onChange={(e) => {
                setDuration_per(e.target.value);
              }}
            />
          </Form.Item> */}
          <Form.Item label="每单位的持续时间">
            <InputNumber
              value={dayPer}
              max="10000"
              min="0"
              onChange={(value) => {
                setDayPer(value);
              }}
            />
            （天）
            <InputNumber
              value={hourPer}
              max="23"
              min="0"
              onChange={(value) => {
                setHourPer(value);
              }}
            />
            （时）
            <InputNumber
              value={minutePer}
              max="59"
              min="0"
              style={{ marginTop: '10px' }}
              onChange={(value) => {
                setMinutePer(value);
              }}
            />
            （分）
            <InputNumber
              value={secondPer}
              max="59"
              min="0"
              onChange={(value) => {
                setSecondPer(value);
              }}
            />
            （秒）
          </Form.Item>
          <Form.Item label="最小量">
            <InputNumber
              step="1"
              value={sizeminimum}
              onChange={(value) => {
                setSizeminimum(value);
              }}
            />
          </Form.Item>
          <Form.Item label="最大量">
            <InputNumber
              step="1"
              value={sizemaximum}
              onChange={(value) => {
                setSizemaximum(value);
              }}
            />
          </Form.Item>
          <Form.Item label="大小的倍数">
            <InputNumber
              value={sizemultiple}
              onChange={(value) => {
                setSizemultiple(value);
              }}
            />
          </Form.Item>
          <Form.Item label="父名称">
            <Select
              showSearch
              allowClear={true}
              onSelect={(value) => {
                setOwner(value);
              }}
              value={owner}
              onChange={() => {
                setOwner('');
              }}
            >
              {' '}
              {data &&
                data.map((item) => (
                  <Select.Option value={item.name} key={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="优先级">
            <InputNumber
              value={priority}
              onChange={(value) => {
                setPriority(value);
              }}
            />
          </Form.Item>
          <Form.Item label="成本">
            <InputNumber
              value={cost}
              onChange={(value) => {
                setCost(value);
              }}
            />
          </Form.Item>
          {/* <Form.Item label="生效开始日期">
            <ConfigProvider locale={locale}>
              <DatePicker
                format={dateFormat}
                onChange={(date, dateStr) => {
                  setEffective_start_0(dateStr);
                }}
              />
              <TimePicker
                format={format}
                onChange={(date, dateStr) => {
                  setEffective_start_1(dateStr);
                }}
              />
            </ConfigProvider>
          </Form.Item>
          <Form.Item label="生效结束日期">
            <ConfigProvider locale={locale}>
              <DatePicker
                format={dateFormat}
                onChange={(date, dateStr) => {
                  setEffective_end_0(dateStr);
                }}
              />
              <TimePicker
                format={format}
                onChange={(date, dateStr) => {
                  setEffective_end_1(dateStr);
                }}
              />
            </ConfigProvider>
          </Form.Item> */}
        </Form>
      </Modal>

      <div className="content-header">
        <BreadNav />
        <SearchGroup
          items={[
            {
              label: '名称',
              name: 'name',
            },
            {
              label: '产品',
              name: 'item',
            },
          ]}
          onSearch={onSearch}
        ></SearchGroup>
        <br />
        <Button
          type="primary"
          shape="round"
          size="middle"
          onClick={() => {
            setOptType('add');
            setIsModalVisible(true);
            setEditValue({ sizeminimum: 1.0, priority: 1 }, 'add');
          }}
        >
          &nbsp;新建
        </Button>{' '}
        &nbsp;&nbsp;
        <Button
          type="primary"
          shape="round"
          size="middle"
          danger
          onClick={() => {
            setOptType('deleteBatch');
            showDeleteConfirm('deleteBatch');
          }}
        >
          删除
        </Button>
        <br />
        <br />
      </div>

      <div className="content-body">
        <Table
          size="small"
          // scroll={{ x: 1500 }}
          sticky
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.name}
          bordered
          pagination={{
            pageSize: 15,
          }}
          style={{
            height: getDomHeight(),
          }}
          scroll={{
            y: getDomHeight(),
            // x: '100vw',
          }}
        />
      </div>
    </div>
  );
}
