import React from 'react';
import 'moment/locale/zh-cn';
import http from '../../../http';
import filterItem from '../../../utils/filterItem';
import formatTime from '../../../utils/formatTime';
import { Table, message, Button, Modal, Form, Input, Select, InputNumber } from 'antd';
import BreadNav from '../../../components/breadNav';
import { getDomHeight, timeToDuration } from '../../../utils/common';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
const axios = require('axios');

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
export default class Distribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isModalVisible: false,
      optType: '',
      data: [],
      staticData: [],
      locations: [],
      items: [],
      origins: [],
      content: { title: '' },
      pageStatus: '',
      status: '',
      type: '',
      /**
       * 接口参数
       */
      location: '',
      item: '',
      origin: '',
      leadtime: '',
      sizeminimum: 0,
      cost: '',
      priority: 10,
      resource: '',
      resource_qty: '',
      effective_start_0: '',
      effective_end_1: '',
      effective_end_0: '',
      effective_end_1: '',
      day: '0',
      hour: '0',
      minute: '0',
      second: '0',
    };
    this.formRef = React.createRef();

    //表头
    this.columns = [
      {
        title: '编号',
        dataIndex: 'id',
        width: 200,
        sorter: (a, b) => a.id - b.id,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '转入地点',
        dataIndex: 'location_id',
        width: 200,
        sorter: (a, b) => String(a.location_id).length - String(b.location_id).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '产品',
        dataIndex: 'item_id',
        width: 200,
        sorter: (a, b) => String(a.item_id).length - String(b.item_id).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '转出地点',
        dataIndex: 'origin_id',
        width: 200,
      },
      {
        /**
         * 数据处理
         */
        title: '交期提前期',
        dataIndex: 'leadtime',
        width: 200,
        render: (value) => {
          return <span>{value ? timeToDuration(value) : ''}</span>;
        },
      },
      {
        title: '最小量',
        width: 100,
        dataIndex: 'sizeminimum',
        // render: (text) => text && text.split('.')[0],
      },
      {
        title: '优先级',
        width: 100,
        dataIndex: 'priority',
      },
      {
        title: '成本',
        width: 100,
        dataIndex: 'cost',
      },
      {
        title: '更新时间',
        width: 200,
        dataIndex: 'lastmodified',
        render: (text) => text && text.split('.')[0],
      },
      // {
      //   title: '操作',
      //   dataIndex: '',
      //   width: 200,
      //   render: (text) => {
      //     let { pageStatus } = this.state;
      //     return (
      //       <div>
      //         <Button
      //           type="link"
      //           text={JSON.stringify(text)}
      //           onClick={(e) => {
      //             if (!pageStatus) return;
      //             let { location, item, origin, leadtime, sizeminimum, priority, cost, id } =
      //               JSON.parse(e.currentTarget.getAttribute('text'));
      //             this.setState(
      //               {
      //                 item,
      //                 location,
      //                 origin,
      //                 leadtime,
      //                 sizeminimum,
      //                 priority,
      //                 cost,
      //                 pageStatus: 'edit',
      //                 type: 'edit',
      //                 content: { title: '编辑产品配送信息' },
      //                 whichItem: id,
      //                 isModalVisible: true,
      //                 day: parseInt(leadtime / 86400) || '',
      //                 hour: parseInt((leadtime / 3600) % 24) || '',
      //                 minute: parseInt((leadtime / 60) % 60) || '',
      //                 second: parseInt(leadtime % 60) || '',
      //               },
      //               () => {
      //                 if (this.formRef.current && this.formRef.current.setFieldsValue) {
      //                   this.formRef.current.setFieldsValue({
      //                     item,
      //                     location,
      //                     origin,
      //                     leadtime,
      //                     sizeminimum,
      //                     priority,
      //                     cost,
      //                     day: parseInt(leadtime / 86400) || '',
      //                     hour: parseInt((leadtime / 3600) % 24) || '',
      //                     minute: parseInt((leadtime / 60) % 60) || '',
      //                     second: parseInt(leadtime % 60) || '',
      //                   });
      //                 }
      //               }
      //             );
      //           }}
      //         >
      //           编辑
      //         </Button>
      //         &nbsp;&nbsp;
      //         <Button
      //           type="text"
      //           danger
      //           id={text.id}
      //           onClick={(e) => {
      //             if (!pageStatus) return;
      //             let id = e.target.parentNode.id;
      //             console.log('e: ', e.target.parentNode);
      //             this.setState(
      //               {
      //                 pageStatus: 'delete',
      //                 type: 'delete',
      //                 whichItem: id,
      //               },
      //               this.showDeleteConfirm
      //             );
      //           }}
      //         >
      //           删除
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ];
  }

  //表格选中行
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  //对话框
  showDeleteConfirm = () => {
    let { type } = this.state;
    let _this = this;
    confirm({
      title: '您确定要删除该项数据吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          _this.deleteItem();
        } else if (type === 'deleteBatch') {
          _this.deleteBatch();
        }
      },
      onCancel() {},
    });
  };
  //列表
  // itemList = () => {
  //   let _this = this;

  //   http.get(
  //     'data/input/itemdistribution/',
  //     {
  //       format: 'json',
  //       _search: false,
  //       rows: 10,
  //       page: 1,
  //       sidx: 'lastmodified',
  //       sord: 'desc',
  //     },
  //     function (response) {
  //       if (response.data && response.data.rows) {
  //         let data = response.data.rows;
  //         data.forEach((item) => {
  //           item.sizeminimum =
  //             item.sizeminimum && item.sizeminimum === '0E-8' ? 0 : item.sizeminimum;
  //           item.cost = item.cost && Number(item.cost).toFixed(2);
  //         });

  //         _this.setState({
  //           staticData: data,
  //           data,
  //           pageStatus: 'ready',
  //         });
  //       }
  //     }
  //   );
  // };
  itemList = () => {
    let _this = this;

    http.postNkJSON(
      'itemdistribution/getdatalist',
      {
        SortField: 'item_id',
        SortType: '',
      },
      function (response) {
        if (response.data && response.data.Success) {
          let data = response.data.Data;
          data.forEach((item) => {
            item.sizeminimum =
              item.sizeminimum && item.sizeminimum === '0E-8' ? 0 : item.sizeminimum;
            item.cost = item.cost && Number(item.cost).toFixed(2);
          });

          _this.setState({
            staticData: data,
            data,
            pageStatus: 'ready',
          });
        }else{
          return
        }
      }
    );
  };
  //产品配送列表
  distributionList = () => {
    return http.customGet('/api/data/input/itemdistribution/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
    });
  };
  //产品列表
  productionList = () => {
    return http.customGet('/api/data/input/item/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
      filters: {
        groupOp: 'AND',
        rules: [
          { field: 'owner', op: 'ne', data: '全部' },
          { field: 'name', op: 'ne', data: '全部' },
          { field: 'name', op: 'ne', data: 'All items', filtercount: 0 },
        ],
      },
    });
  };
  //工厂列表
  locationList = () => {
    return http.customGet('/api/data/input/location/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
    });
  };
  //请求所有数据
  allList = () => {
    let _this = this;
    axios
      .all([this.productionList(), this.locationList(), this.distributionList()])
      .then((results) => {
        let items = filterItem(results[0].data.rows, 'name');
        let locations = filterItem(results[1].data.rows, 'name');
        let data = results[2].data.rows;
        data.forEach((item) => {
          item.sizeminimum = item.sizeminimum && item.sizeminimum === '0E-8' ? 0 : item.sizeminimum;
          item.cost = item.cost && Number(item.cost).toFixed(2);
        });

        _this.setState({
          items,
          locations,
          origins: locations,
          data,
          staticData: data,
          pageStatus: 'ready',
        });
      });
  };
  //新增
  addItem = () => {
    let {
      item,
      location,
      origin,
      leadtime,
      sizeminimum,
      priority,
      cost,
      day,
      hour,
      minute,
      second,
    } = this.state;
    let _this = this;
    http.post(
      'data/input/itemdistribution/add/',
      {
        _save: '保存',
        item,
        location,
        origin,
        leadtime: (day * 86400 + hour * 3600 + minute * 60 + second * 1).toString(),
        sizeminimum,
        priority,
        cost,
        resource: '',
        resource_qty: '',
        effective_start_0: '',
        effective_start_1: '',
        effective_end_0: '',
        effective_end_1: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //编辑
  editItem = () => {
    let {
      item,
      location,
      origin,
      leadtime,
      sizeminimum,
      priority,
      cost,
      whichItem,
      day,
      hour,
      minute,
      second,
    } = this.state;
    let _this = this;
    http.post(
      `detail/input/itemdistribution/${whichItem}/`,
      {
        csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        _save: '保存',
        item,
        location,
        origin,
        leadtime: (day * 86400 + hour * 3600 + minute * 60 + second * 1).toString(),
        sizeminimum,
        priority,
        cost,
        resource: '',
        resource_qty: '',
        effective_start_0: '',
        effective_start_1: '',
        effective_end_0: '',
        effective_end_1: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //删除
  deleteItem = () => {
    let { whichItem } = this.state;
    let _this = this;
    http.post(
      `data/input/itemdistribution/${whichItem}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.itemList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  deleteBatch = () => {
    let { type, selectedRowKeys } = this.state;
    let _this = this;
    if (type !== 'deleteBatch') return;
    http.postJSON(
      `data/input/itemdistribution/`,
      [{ delete: selectedRowKeys }],
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.itemList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //表单提交
  handleSubmit = () => {
    let { type } = this.state;
    if (type === 'add') {
      this.addItem();
    } else if (type === 'edit') {
      this.editItem();
    }
  };

  //模糊查询
  handleSearch = (searched) => {
    let { staticData } = this.state;
    let arr = staticData.filter((item) => {
      let val = searched.replace(/(^\s*)|(\s*$)/g, '');
      return item.item.includes(val);
    });
    this.setState({
      data: arr,
    });
  };

  //同步
  synchronization = () => {
    http.customPost('/nkapi/sync/itemsupplier').then((res) => {
      if (res.data.Success === true) {
        message.success('同步成功！');
        this.itemList();
      } else {
        message.error('同步失败！');
      }
    });
  };
  componentDidMount() {
    this.itemList();
    // this.allList();
  }

  render() {
    let {
      locations,
      items,
      origins,
      data,
      selectedRowKeys,
      content,
      isModalVisible,
      day,
      hour,
      minute,
      second,
      /**
       * 接口参数
       */
      item,
      location,
      origin,
      leadtime,
      sizeminimum,
      priority,
      cost,
    } = this.state;

    return (
      <div className="modal-list layout-content">
        <Modal
          className="inventory-distribution-modal"
          title={content.title}
          visible={isModalVisible}
          maskClosable={false}
          cancelText="取消"
          onCancel={() => {
            this.setState({
              isModalVisible: false,
            });
          }}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            ayout="horizontal"
            ref={this.formRef}
            initialValues={{
              item,
              location,
              origin,
              leadtime,
              sizeminimum,
              priority,
              cost,
            }}
            onValuesChange={(changedValues, allValues) => {
              this.setState({
                ...allValues,
              });
            }}
            onFinish={this.handleSubmit}
          >
            <Form.Item
              label="产品"
              name="item"
              rules={[{ required: true, message: '请选择产品名' }]}
            >
              <Select showSearch>
                {items.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="转入地点"
              name="location"
              rules={[{ required: true, message: '请选择工厂' }]}
            >
              <Select showSearch>
                {locations.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="转出地点"
              name="origin"
              rules={[{ required: true, message: '请选择转出地点' }]}
            >
              <Select showSearch>
                {origins.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item label="交货提前期(秒)" name="leadtime">
              <InputNumber />
            </Form.Item>*/}
            <Form.Item label="交货提前期">
              <InputNumber
                value={day}
                max="10000"
                min="0"
                onChange={(value) => {
                  this.setState({
                    day: value,
                  });
                }}
              />
              （天）
              <InputNumber
                value={hour}
                max="23"
                min="0"
                onChange={(value) => {
                  this.setState({
                    hour: value,
                  });
                }}
              />
              （时）
              <InputNumber
                value={minute}
                max="59"
                min="0"
                style={{ marginTop: '10px' }}
                onChange={(value) => {
                  this.setState({
                    minute: value,
                  });
                }}
              />
              （分）
              <InputNumber
                value={second}
                max="59"
                min="0"
                onChange={(value) => {
                  this.setState({
                    second: value,
                  });
                }}
              />
              （秒）
            </Form.Item>
            <Form.Item label="最小量" name="sizeminimum">
              <InputNumber />
            </Form.Item>
            <Form.Item label="优先级" name="priority">
              <InputNumber />
            </Form.Item>
            <Form.Item label="成本" name="cost">
              <InputNumber />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  position: 'absolute',
                  left: '416px',
                  top: '91px',
                }}
              >
                提交
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <div className="content-header">
          <BreadNav />
          <div className="search-container">
            产品名称&nbsp;:&nbsp;&nbsp;
            <Search
              className="search"
              enterButton="查询"
              size="middle"
              allowClear
              onSearch={(value) => {
                this.handleSearch(value);
              }}
            />
          </div>
          <br />
          <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => {
              this.synchronization();
            }}
          >
            同步
          </Button>
          <br />
          <br />
          {/* <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => {
              if (this.formRef.current && this.formRef.current.setFieldsValue) {
                this.formRef.current.setFieldsValue({
                  item: '',
                  location: '',
                  origin: '',
                  leadtime: '',
                  sizeminimum: '0',
                  priority: '10',
                  cost: '',
                });
              }
              this.setState({
                type: 'add',
                isModalVisible: true,
                day: '',
                hour: '',
                minute: '',
                second: '',
                content: { title: '新建产品配送信息' },
              });
            }}
          >
            &nbsp;新建
          </Button>{' '}
          &nbsp;&nbsp;
          <Button
            type="primary"
            shape="round"
            size="middle"
            danger
            onClick={() => {
              this.setState(
                {
                  type: 'deleteBatch',
                },
                () => {
                  this.showDeleteConfirm('deleteBatch');
                }
              );
            }}
          >
            删除
          </Button>
          <br />
          <br /> */}
        </div>
        <div className="content-body">
          <Table
            size="small"
            className="table-normal"
            // rowSelection={{
            //   selectedRowKeys,
            //   onChange: this.onSelectChange,
            // }}
            columns={this.columns}
            dataSource={data}
            rowKey={(record) => record.id}
            bordered
            pagination={{ pageSize: 15 }}
            style={{
              height: getDomHeight(),
            }}
            scroll={{
              y: getDomHeight(),
              // x: '100vw',
            }}
          />
        </div>
      </div>
    );
  }
}
