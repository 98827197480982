import React, { useState, useEffect } from 'react';
import BreadNav from 'components/breadNav';
import { Input, Table, Button, Select, message } from 'antd';
import { getDomHeight } from '../../../utils/common';
import http from '../../../http';
const { Option } = Select;

export default function Device() {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [total, setTotal] = useState();
  const [equCode, setEquCode] = useState();
  const [orgId, setOrgId] = useState();
  const [equName, setEquName] = useState();
  const [pageRows, setPageRows] = useState(20);
  const [selectArr, setSelectArr] = useState([]);

  //表头
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 150,
      key: 'index',
      render: (val, obj, index) => {
        return <span>{(currentIndex - 1) * 20 + (index + 1)}</span>;
      },
    },
    {
      title: '所属企业',
      dataIndex: 'comPanyName',
      width: 400,
      key: 'comPanyName',
    },
    {
      title: '设备编号',
      dataIndex: 'equCode',
      width: 200,
      key: 'equCode',
    },
    {
      title: '设备名称',
      dataIndex: 'equName',
      width: 200,
      key: 'equName',
    },
    {
      title: '设备品牌',
      dataIndex: 'equMake',
      width: 200,
      key: 'equMake',
    },
  ];
  //搜索
  const btnSearch = () => {
    locationList(1, 20, equCode, orgId, equName);
  };

  //每次切换页码的时候,更新之前初始化的页码
  const pagination = {
    pageSize: pageRows,
    total: total,
    onChange: (page, pageSize) => {
      setCurrentIndex(page);
      locationList(page, pageRows, equCode, orgId, equName);
    },
  };

  //渲染下拉
  const selectLoad = () => {
    http.customGet('/nkapi/center/company/all').then((res) => {
      if (res.data && res.data.Success) {
        setSelectArr(res.data.Data);
      }else{
        setSelectArr([]);
      }
    });
  };

  //所属企业下拉
  const selectChange = (value) => {
    setOrgId(value);
  };

  //渲染表格
  const locationList = (index = 1, rows = 20, equCode, orgId, equName) => {
    let equCodeNew, equNameNew;
    if (equCode) {
      equCodeNew = equCode.replace(/(^\s*)|(\s*$)/g, '');
    } else {
      equCodeNew = '';
    }
    if (equName) {
      equNameNew = equName.replace(/(^\s*)|(\s*$)/g, '');
    } else {
      equNameNew = '';
    }

    let params = {
      search: {
        equCode: equCodeNew,
        orgId: orgId,
        equName: equNameNew,
      },
      pageIndex: index,
      pageSize: rows,
      noCount: false,
      orderBy: '',
      countSql: '',
    };
    http.customPost_device('/nkapi/sync/equipments', params).then((res) => {
      if (res.data && res.data.Success) {
        const { rows, total } = res.data.Data;
        setData(rows);
        setTotal(total);
      }else{
        return
      }
    });
  };

  //挂载
  useEffect(() => {
    locationList();
    selectLoad();
  }, []);

  return (
    <div className="modal-list layout-content">
      <div className="content-header">
        <BreadNav />
        <div className="search-container">
          所属企业&nbsp;:&nbsp;&nbsp;
          <Select
            placeholder="所属企业"
            showSearch
            // defaultValue=""
            allowClear
            style={{ width: 200 }}
            onChange={selectChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {selectArr.map((item, index) => {
              return (
                <Option value={item.value} key={index}>
                  {item.text}
                </Option>
              );
            })}
          </Select>
          &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;设备编号&nbsp;:&nbsp;&nbsp;
          <Input
            placeholder="设备编号"
            allowClear
            style={{ width: 200 }}
            onChange={(e) => {
              setEquCode(e.target.value);
            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;设备名称&nbsp;:&nbsp;&nbsp;
          <Input
            placeholder="设备名称"
            allowClear
            style={{ width: 200 }}
            onChange={(e) => {
              setEquName(e.target.value);
            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button type="primary" shape="round" onClick={btnSearch}>
            搜索
          </Button>
        </div>
        <br />
      </div>
      <div className="content-body">
        <Table
          bordered
          size="small"
          className="table-normal"
          columns={columns}
          dataSource={data}
          pagination={pagination} //分页属性
          style={{
            height: getDomHeight(),
          }}
          scroll={{
            y: getDomHeight(),
            // x: '100vw',
          }}
        />
      </div>
    </div>
  );
}
