import React from 'react';
import { Layout, Menu, Dropdown, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import routes from '../../router/index';
import { Link } from 'react-router-dom';
import http from '../../http';
import icon from '../../assets/imgs/icon.png';
import './userLayout.scss';
import menus from './menus';
import * as actions from '../../redux/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import config from '../../config/index';
import BaseMenu from '../SiderMenu/BaseMenu';

const { Header, Content, Sider } = Layout;
const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/login">退出登录</Link>
    </Menu.Item>
  </Menu>
);
class UserLayOut extends React.Component {
  constructor(props) {
    super(props);
    let url = window.location.pathname;
    this.state = {
      selectedKeys: [url],
      openKeys: [url.slice(0, url.lastIndexOf('/'))],
      envs: [],
      envsCopy: [
        { label: '默认', value: 'default' },
        { label: '场景 1', value: 'scenario1' },
        { label: '场景 2', value: 'scenario2' },
        { label: '场景 3', value: 'scenario3' },
      ],
      env: window.env,
      menus: [],
    };
  }
  componentDidMount() {
    this.getEnv();
    this.menusFilter();
  }

  async menusFilter() {
    await this.props.GetAuthMenus(localStorage.getItem('userName'));
    const { authMenus } = this.props;
    if (authMenus) {
      const auth = authMenus;
      const res = menus.filter((item) => {
        if (item.children) {
          item.subMenus = item.children.filter((child) => auth.includes(child.key));
        }
        return auth.includes(item.key);
      });
      this.props.SetCurrentMenus(res);
    }
  }

  envChange(env) {
    console.log(env, 'env');
    this.setState({ env });
    window.env = env;
    localStorage.setItem('env', env);
    window.location.reload();
  }
  async getEnv() {
    const res = await http.post('scenario/launch/show_scenario/');
    const freeEnvs = (res.data && res.data.data && res.data.data.free_scenarios) || [];
    const currEnvs = this.state.envsCopy.filter((env) => freeEnvs.indexOf(env.value) < 0);
    this.setState({ envs: currEnvs });
  }

  render() {
    const userName = localStorage.getItem('userName') || '我的';

    return (
      <Layout className="userlayout">
        <Header className="header">
          <div className="icon-box">
            <img className="icon" src={icon} />
            中国家具产业智联网
          </div>
          <div className="aps">南康家具产业APS</div>
          <div className="welcome">欢迎您</div>

          <div className="description">APS智能引擎功能集</div>
          <Select
            className="env-select"
            style={{ width: 200 }}
            onFocus={() => {
              this.getEnv();
            }}
            onChange={(data) => {
              this.envChange(data);
            }}
            value={this.state.env}
          >
            {this.state.envs.map((env, index) => (
              <Select.Option key={index} value={env.value}>
                {env.label}
              </Select.Option>
            ))}
          </Select>
          <Dropdown overlay={menu}>
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
              {userName} <DownOutlined />
            </a>
          </Dropdown>
        </Header>
        <Layout className="layout">
          <Sider width={220} className="site-layout-background side-bar">
            <BaseMenu menusData={this.props.currentMenus} />
          </Sider>
          <Layout className="layout-right">
            <Content
              className="site-layout-background"
              style={{
                padding: '0 24px',
                margin: 0,
                minHeight: 280,
              }}
            >
              {routes()}
              {/* <Switch>
                {routes.map((item) => {
                  return (
                    <Route path={`${item.path}`} key={item.path}>
                      {item.component}
                    </Route>
                  );
                })}
              </Switch> */}
            </Content>
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                left: 4,
                opacity: 0.3,
              }}
            >
              version:{config.version}
            </div>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  (state) => ({ currentMenus: state.currentMenus, authMenus: state.authMenus }),
  matchDispatchToProps
)(UserLayOut);
