import React from 'react';
import 'moment/locale/zh-cn';
import http from '../../../http';
import axios from 'axios';
import filterItem from '../../../utils/filterItem';
import { Table, message, Button, Modal, Form, Input, Select, InputNumber } from 'antd';
import BreadNav from '../../../components/breadNav';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import { searchEngine, getDomHeight } from '../../../utils/common';
import SearchGroup from '../../../components/SearchGroup';

const { confirm } = Modal;
const { Search, Group } = Input;
const { Option } = Select;
export default class Inventory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /**
       * 操作相关
       */
      actionType: '',
      pageStatus: '',
      isModalVisible: false,
      content: { title: '' },
      selectedRowKeys: [],
      data: [],
      dataCopy: [],
      locations: [],
      items: [],
      type: '',
      searchLocation: '',

      /**
       * 接口参数
       */
      item: '',
      location: '',
      onhand: '',
      minimum: '',
      batch: '',
      description: '',
      minimum_calendar: '',
      min_interval: '',
      itemType: '',

      deleteName: '',
      whichItem: '',
    };

    this.formRef = React.createRef();

    //表头
    this.columns = [
      {
        title: '编号',
        dataIndex: 'id',
        width: 100,
        sorter: (a, b) => a.id - b.id,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '工厂',
        dataIndex: 'location',
        width: 200,
        sorter: (a, b) => String(a.location).length - String(b.location).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '产品',
        dataIndex: 'item',
        width: 200,
        sorter: (a, b) => String(a.item).length - String(b.item).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '现有库存',
        dataIndex: 'onhand',
        width: 100,
        render: (text) => (text === '0E-8' ? 0 : text && text.split('.')[0]),
        sorter: (a, b) => a.onhand - b.onhand,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '类型',
        dataIndex: 'type',
        width: 80,
        render: (text) =>
          (text === 'default' && '默认') || (text === 'infinite' && '无限的') || text,
        sorter: (a, b) => String(a.type).length - String(b.type).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '安全库存',
        dataIndex: 'minimum',
        width: 100,
        render: (text) => (text === '0E-8' ? 0 : text && text.split('.')[0]),
        sorter: (a, b) => a.minimum - b.minimum,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '更新时间',
        width: 200,
        dataIndex: 'lastmodified',
        render: (text) => text.split('.')[0],
      },
      {
        title: '描述',
        dataIndex: 'description',
        width: 200,
        sorter: (a, b) => String(a.description).length - String(b.description).length,
        sortDirections: ['descend', 'ascend'],
      },
      // {
      //   title: '操作',
      //   dataIndex: '',
      //   width: 200,
      //   render: (text) => {
      //     let { pageStatus } = this.state;
      //     return (
      //       <div>
      //         <Button
      //           type="link"
      //           text={JSON.stringify(text)}
      //           onClick={(e) => {
      //             console.log('edit');
      //             if (!pageStatus) return;
      //             let { id, description, item, location, onhand, minimum, batch, type } =
      //               JSON.parse(e.currentTarget.getAttribute('text'));
      //             minimum = parseInt(minimum) ? parseInt(minimum) : '';
      //             onhand = parseInt(onhand) ? parseInt(onhand) : '';
      //             this.setState(
      //               {
      //                 id,
      //                 description,
      //                 item,
      //                 location,
      //                 onhand,
      //                 minimum,
      //                 batch,
      //                 type,

      //                 pageStatus: 'edit',
      //                 actionType: 'edit',
      //                 content: { title: '编辑库存信息' },
      //                 whichItem: id,
      //                 isModalVisible: true,
      //               },
      //               () => {
      //                 if (this.formRef.current && this.formRef.current.setFieldsValue) {
      //                   this.formRef.current.setFieldsValue({
      //                     whichItem: id,
      //                     description,
      //                     item,
      //                     location,
      //                     onhand,
      //                     minimum,
      //                     type,
      //                   });
      //                 }
      //               }
      //             );
      //           }}
      //         >
      //           编辑
      //         </Button>
      //         &nbsp;&nbsp;
      //         <Button
      //           type="text"
      //           danger
      //           id={text.id}
      //           onClick={(e) => {
      //             if (!pageStatus) return;
      //             let id = e.target.parentNode.id;
      //             this.setState(
      //               {
      //                 pageStatus: 'delete',
      //                 actionType: 'delete',
      //                 whichItem: id,
      //               },
      //               this.showDeleteConfirm
      //             );
      //           }}
      //         >
      //           删除
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ];
  }

  //表格选中行
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  //对话框
  showDeleteConfirm = () => {
    let { actionType } = this.state;
    let _this = this;
    confirm({
      title: '您确定要删除该项数据吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (actionType === 'delete') {
          _this.deleteItem();
        } else if (actionType === 'deleteBatch') {
          _this.deleteBatch();
        }
      },
      onCancel() {},
    });
  };
  //列表
  itemList = () => {
    let _this = this;

    http.get(
      'data/input/buffer/',
      {
        format: 'json',
        _search: false,
        rows: 100,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        if (response.data && response.data.rows) {
          let data = response.data.rows;
          _this.setState({
            staticData: data,
            data,
            pageStatus: 'ready',
          });
        }
      }
    );
  };

  //产品列表
  productionList = () => {
    return http.get('data/input/item/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
      filters: {
        groupOp: 'AND',
        rules: [
          { field: 'owner', op: 'ne', data: '全部' },
          { field: 'name', op: 'ne', data: '全部' },
          { field: 'name', op: 'ne', data: 'All items', filtercount: 0 },
        ],
      },
    });
  };

  //工厂列表
  locationList = () => {
    return http.get('data/input/location/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
    });
  };

  //库存列表
  inventoryList = () => {
    return http.get('data/input/buffer/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
    });
  };

  //请求所有数据
  allList = () => {
    let _this = this;
    axios
      .all([this.productionList(), this.locationList(), this.inventoryList()])
      .then((results) => {
        let items = results[0].data && filterItem(results[0].data.rows, 'name');
        let locations = results[1].data && filterItem(results[1].data.rows, 'name');
        let data = results[2].data && results[2].data.rows;

        _this.setState({
          dataCopy: data,
          data,
          locations,
          items,
          pageStatus: 'ready',
        });
      });
  };
  //新建
  addItem = () => {
    let { description, item, location, onhand, minimum, type } = this.state;
    let _this = this;
    http.post(
      'data/input/buffer/add/',
      {
        csrfmiddlewaretoken: 'mV3EB7B9SJLO7LZTHNU1fcakr9z75W1npDcy8YjuzB6waYxxBihC76xbLqdOcIGg',
        _save: '保存',
        item,
        location,
        onhand,
        minimum,
        description,
        type,
        batch: '',
        minimum_calendar: '',
        min_interval: '',
        category: '',
        subcategory: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //编辑
  editItem = () => {
    let {
      whichItem,
      description,
      item,
      location,
      onhand,
      minimum,
      batch,
      type,
      minimum_calendar,
      min_interval,
    } = this.state;
    let _this = this;
    http.post(
      `data/input/buffer/`,
      [
        {
          id: whichItem,
          // csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
          description,
          item,
          location,
          onhand,
          minimum,
          batch,
          type,
        },
      ],
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //删除
  deleteItem = () => {
    let { whichItem } = this.state;
    let _this = this;
    http.post(
      `data/input/buffer/${whichItem}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.itemList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  deleteBatch = () => {
    let { actionType, selectedRowKeys } = this.state;
    let _this = this;
    if (actionType !== 'deleteBatch') return;
    http.postJSON(`data/input/buffer/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        _this.itemList();
      } else {
        message.error('操作失败！');
      }
    });
  };
  //表单提交
  handleSubmit = () => {
    let { actionType } = this.state;
    if (actionType === 'add') {
      this.addItem();
    } else if (actionType === 'edit') {
      this.editItem();
    }
  };
  //同步
  synchronization = () => {
    http.customPost('/nkapi/sync/buffers').then((res) => {
      if (res.data.Success === true) {
        message.success('同步成功！');
        this.itemList();
      } else {
        message.error('同步失败！');
      }
    });
  };
  onSearch(keys) {
    if (keys.every((key) => !key.value)) {
      this.allList();
    } else {
      const arr = searchEngine(this.state.dataCopy, keys);
      this.setState({
        data: arr,
      });
    }
  }

  componentDidMount() {
    this.allList();
  }

  render() {
    let {
      isModalVisible,
      content,
      data,
      selectedRowKeys,
      locations,
      items,
      /**
       * 表单相关
       */
      description,
      item,
      location,
      onhand,
      minimum,
      type,
    } = this.state;
    return (
      <div className="modal-list inventory-inventory">
        <Modal
          className="inventory-inventory-modal"
          title={content.title}
          visible={isModalVisible}
          maskClosable={false}
          cancelText="取消"
          onCancel={() => {
            this.setState({
              isModalVisible: false,
            });
          }}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            ref={this.formRef}
            initialValues={{
              description,
              item,
              location,
              onhand,
              minimum,
              type,
            }}
            onValuesChange={(changedValues, allValues) => {
              this.setState({
                ...allValues,
              });
            }}
            onFinish={this.handleSubmit}
          >
            <Form.Item
              label="产品"
              name="item"
              rules={[{ required: true, message: '请选择产品名' }]}
            >
              <Select placeholder="请选择产品名" showSearch>
                {items &&
                  items.map((item) => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="工厂"
              name="location"
              rules={[{ required: true, message: '请选择工厂' }]}
            >
              <Select placeholder="请选择工厂" showSearch>
                {locations &&
                  locations.map((item) => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="现有库存量" name="onhand">
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="安全库存"
              name="minimum"
              rules={[{ required: true, message: '请输入安全库存值' }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item name="description" label="描述">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="类型" name="type">
              <Select>
                {[
                  { cn: '默认', en: 'default' },
                  { cn: '无限的', en: 'infinite' },
                ].map((item) => (
                  <Option value={item.en} key={item.en}>
                    {item.cn}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  position: 'absolute',
                  left: '416px',
                  top: '91px',
                }}
              >
                提交
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <div className="layout-content">
          <div className="content-header">
            <BreadNav />
            <div className="search-container">
              <SearchGroup
                items={[
                  {
                    label: '工厂名称: ',
                    name: 'location',
                  },
                  {
                    label: '产品名称: ',
                    name: 'item',
                  },
                ]}
                onSearch={this.onSearch.bind(this)}
              ></SearchGroup>
            </div>
            <br />
            {/* <Button
              type="primary"
              shape="round"
              size="middle"
              onClick={() => {
                if (this.formRef.current && this.formRef.current.setFieldsValue) {
                  this.formRef.current.setFieldsValue({
                    description: '',
                    item: '',
                    location: '',
                    onhand: '',
                    minimum: '',
                    type: '',
                  });
                }
                this.setState({
                  actionType: 'add',
                  isModalVisible: true,
                  content: { title: '新建库存信息' },
                });
              }}
            >
              &nbsp;新建
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              type="primary"
              shape="round"
              size="middle"
              danger
              onClick={() => {
                this.setState(
                  {
                    pageStatus: 'delete',
                    actionType: 'deleteBatch',
                  },
                  () => {
                    this.showDeleteConfirm('deleteBatch');
                  }
                );
              }}
            >
              删除
            </Button>
            &nbsp;&nbsp;&nbsp; */}
            <Button
              type="primary"
              shape="round"
              size="middle"
              onClick={() => {
                this.synchronization();
              }}
            >
              同步
            </Button>
            <br />
            <br />
          </div>
          <div className="content-body">
            <Table
              size="small"
              className="table-normal"
              // rowSelection={{
              //   selectedRowKeys,
              //   onChange: this.onSelectChange,
              // }}
              columns={this.columns}
              dataSource={data}
              rowKey={(record) => record.id}
              pagination={{ pageSize: 15 }}
              bordered
              style={{
                height: getDomHeight(),
              }}
              scroll={{
                y: getDomHeight(),
                // x: '100vw',
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
