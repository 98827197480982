import React, { Component } from 'react';
import Gantt from '../../../../../components/GanttChart';
import http from '../../../../../http';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const switchRoute = (event, id, type) => {
  console.log(event, id, type, 'event=====');
  // const target = data.find((item) => item == item.text);
  // console.log(target, 'target');
  // if (type === 'MO') {
  //   this.props.history.push({
  //     pathname: '/main/manufacturing/productionList',
  //     params: id,
  //   });
  // } else {
  //   this.props.history.push({
  //     pathname: '/main/purchasing/purchaseorder',
  //   });
  // }
};
window.switchRoute = switchRoute;
class PlanningGantt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: {
        data: [
          // {
          //   id: '1',
          //   text: '施药*',
          //   level:1,
          //   type: 'PO',
          //   startDate: '2030-12-31',
          //   duration: '0',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: '2',
          //   text: '施药*',
          //   level:1,
          //   type: 'PO',
          //   startDate: '01-05-2020',
          //   duration: '0.01',
          //   progress: 1,
          //   open: true,
          //   parent:'1'
          // },
          // {
          //   id: 2,
          //   text: '施药1',
          //   type: 'PO',
          //   startDate: '02-05-2020',
          //   duration: '1',
          //   parent: '1',
          //   progress: 0.6,
          //   open: true,
          // },
          // {
          //   id: 3,
          //   text: '施药2',
          //   type: 'MO',
          //   startDate: '03-05-2020',
          //   duration: '1',
          //   parent: '1',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 4,
          //   text: '施药3',
          //   type: 'MO',
          //   startDate: '04-05-2020',
          //   duration: '1',
          //   parent: '1',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 5,
          //   text: '农事*',
          //   type: 'MO',
          //   startDate: '01-05-2020',
          //   duration: '0.01',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 6,
          //   text: '农事1',
          //   type: 'MO',
          //   startDate: '02-05-2020',
          //   duration: '1',
          //   parent: '5',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 7,
          //   text: '农事2',
          //   type: 'MO',
          //   startDate: '03-05-2020',
          //   duration: '1',
          //   parent: '5',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 8,
          //   text: '农事3',
          //   type: 'MO',
          //   startDate: '04-07-2020',
          //   duration: '1',
          //   parent: '5',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 9,
          //   text: '施肥*',
          //   type: 'PO',
          //   startDate: '01-05-2020',
          //   duration: '0.01',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 10,
          //   text: '施肥1',
          //   type: 'PO',
          //   startDate: '02-05-2020',
          //   duration: '1',
          //   parent: '9',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 11,
          //   text: '施肥2',
          //   type: 'PO',
          //   startDate: '30-05-2020',
          //   duration: '1',
          //   parent: '9',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 12,
          //   text: '采集*',
          //   type: 'PO',
          //   startDate: '01-05-2020',
          //   duration: '0.01',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 13,
          //   text: '采集1',
          //   type: 'PO',
          //   startDate: '02-05-2020',
          //   duration: '1',
          //   parent: '12',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 14,
          //   text: '采集2',
          //   type: 'PO',
          //   startDate: '03-05-2020',
          //   duration: '1',
          //   parent: '12',
          //   progress: 1,
          //   open: true,
          // },
          // {
          //   id: 15,
          //   text: '采集2.1',
          //   type: 'PO',
          //   startDate: '03-05-2020',
          //   duration: '1',
          //   parent: '12',
          //   progress: 1,
          //   open: true,
          // },
        ],
      },
      columns: [
        {
          name: 'level',
          label: '层级',
          align: 'left',
          tree: true,
          width: 350,
          template: (task) => {
            let htmladd = '';
            htmladd += '<div>';
            htmladd += task.level;
            htmladd += ' </div>';
            return htmladd;
          },
        },
        {
          name: 'text',
          label: '操作',
          align: 'left',
          width: 250,
          template: (task) => {
            let htmladd = '';
            htmladd += `<div class="custom_left_event ${
              task.isShow ? 'underline' : ''
            }" onclick="switchRoute(this,'${task.id}','${task.type}')">`;
            htmladd += task.text;
            htmladd += ' </div>';
            return htmladd;
          },
        },

        { name: 'type', label: '类型', align: 'left', width: 100 },
        { name: 'resources', label: '资源', align: 'left', width: 100 },
        { name: 'demand', label: '需求', align: 'left' },
        // { name: 'add', align: 'left' },
      ],
    };
  }
  fixStckDate(data) {
    data.forEach((item, index) => {
      if (item.Type === 'STCK') {
        item.StartDate = moment(data[index - 1].StartDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        console.log(item.StartDate, 'item.start_date');
      }
    });
  }
  async componentDidMount() {
    console.log('init1');
    let plan = {};
    // const treeData = this.parseData(require('./plan.json').rows,'operationplans')
    try {
      const res = await this.getPlans();
      this.fixStckDate(res.data.Data);
      // const data = {
      //   Data: [
      //     {
      //       Id: '1',
      //       Level: '0',
      //       Text: 'Ship 2440*1220*18mm橡胶木齿接板AA @ 共享备料中心',
      //       StartDate: '2021-07-09',
      //       Duration: 0.0,
      //       Type: 'DLVR',
      //       Parent: '0',
      //       Open: true,
      //       Demand: '10.00',
      //       Resources: '',
      //       IsShow: false,
      //     },
      //     {
      //       Id: '2',
      //       Level: '1',
      //       Text: '生产2440*1220*18mm橡胶木齿接板AA',
      //       StartDate: '2021-07-09',
      //       Duration: 0.005555555555555556,
      //       Type: 'MO',
      //       Parent: '1',
      //       Open: true,
      //       Demand: '10.00',
      //       Resources: '',
      //       IsShow: true,
      //     },
      //     {
      //       Id: '4',
      //       Level: '3',
      //       Text: 'Ship 橡胶木1.1m*3in*3/8inAB from 赣州市龙平外贸有限公司 to 共享备料中心',
      //       StartDate: '2021-07-08',
      //       Duration: 1.0,
      //       Type: 'DO',
      //       Parent: '2',
      //       Open: true,
      //       Demand: '14.00',
      //       Resources: '',
      //       IsShow: false,
      //     },
      //     {
      //       Id: '5',
      //       Level: '4',
      //       Text: 'Inventory 橡胶木1.1m*3in*3/8inAB @ 赣州市龙平外贸有限公司',
      //       StartDate: '1971-01-01',
      //       Duration: 0.0,
      //       Type: 'STCK',
      //       Parent: '4',
      //       Open: true,
      //       Demand: '14.00',
      //       Resources: '',
      //       IsShow: false,
      //     },
      //   ],
      //   Success: true,
      //   ErrorCode: 0,
      //   Msg: '操作成功',
      // };
      // const res = { data };
      plan = {
        data: this.adapterPlans(res.data.Data),
        // data: this.adapterPlans([
        //   {
        //     Id: '1',
        //     Text: '施药*',
        //     Level: 1,
        //     Type: 'PO',
        //     StartDate: '04-05-2020',
        //     Duration: '1',
        //     Progress: 1,
        //     Resource: 'resource',
        //     RequiredQuantity: 'RequiredQuantity',
        //     Open: true,
        //   },
        // ]),
      };
      if (res.data && res.data.Data.length > 0) {
        this.setState({ plan });
      }
      console.log(this.state.plan, '组装好的甘特图数据');
    } catch (e) {
      console.log(e, 'getPlans error');
    }

    // const plan = {
    //   data:treeToArr(treeData,'operationplans').map(item=>{
    //     item.start_date = item.startdate || '2021-04-09 16:37:00'
    //     // item.duration = 1
    //     // item.progress = 1
    //     item.open = true
    //     return item
    //   })
    // }
    // console.log(JSON.stringify(plan.data),'result ====================')
  }
  componentWillReceiveProps() {}

  adapterPlans(plans) {
    return plans.map((plan) => {
      return {
        id: plan.Id && plan.Id != '0' ? plan.Id : 'id' + plan.Id,
        level: plan.Level,
        text: plan.Text || '',
        type: plan.Type || 'MO',
        start_date: plan.StartDate || '',
        duration: plan.Duration || 1,
        parent: plan.Parent < 1 ? '' : plan.Parent,
        progress: plan.Progress || '',
        resources: plan.Resources || '',
        demand: plan.Demand || '',
        open: plan.Open,
        isShow: plan.IsShow,
        reference: plan.Reference,
        // PO跳采购订单
        // MO 跳生产单
      };
    });
  }

  async getPlans() {
    const url = `/nkapi/plan/demand/${encodeURIComponent(this.props.id)}`;
    console.log(this.props.id, url, 'url=====');

    const params = {};
    return http.customGet(
      url
      // {params}
    );
  }

  parseData(tree, key) {
    const parseData = (treeData) => {
      if (treeData && treeData.length > 0) {
        treeData.forEach((item) => {
          const id = (Math.random() * 10).toString();
          item.id = id;
          delete item.parent;
          const children = item[key];
          if (children && children.length) {
            children.forEach((child) => {
              child.parent = item.id;
              child.id = (Math.random() * 10).toString();
              parseData(child);
            });
          }
        });
      }
    };
    parseData(tree);
    console.log(tree, '给数据添加id，parent');
    return tree;
  }

  addMessage(message) {
    const maxLogLength = 5;
    const newMessate = { message };
    const messages = [newMessate, ...this.state.messages];

    if (messages.length > maxLogLength) {
      messages.length = maxLogLength;
    }
    this.setState({ messages });
  }

  logDataUpdate = (type, action, item, id) => {
    let text = item && item.text ? ` (${item.text})` : '';
    let message = `${type} ${action}: ${id} ${text}`;
    if (type === 'link' && action !== 'delete') {
      message += ` ( source: ${item.source}, target: ${item.target} )`;
    }
    this.addMessage(message);
  };

  handleZoomChange = (zoom) => {
    this.setState({
      currentZoom: zoom,
    });
  };
  // 左侧双击甘特图跳转函数
  taskDbclickCb = (data) => {
    console.log(data, 'taskDbclickCb');
    if (data && data.isShow) {
      this.props.history.push({
        pathname: '/sales/planDetail',//  销售/销售订单>计划
        params: data,
      });
    }
  };
  // 右侧点击甘特图跳转函数
  taskClickCb = (data) => {
    console.log(data, 'taskClickCb');
    if (data.type === 'MO') {
      this.props.history.push({
        pathname: '/manufacturing/productionList',
        params: data,
      });
    } else if (data.type === 'DO') {
      this.props.history.push({
        pathname: '/productionLine/logisticsCenter',
        params: data,
      });
    } else if (data.type === 'PO') {
      this.props.history.push({
        pathname: '/purchasing/purchaseorder',
        params: data,
      });
    }
  };

  render() {
    const { currentZoom, messages } = this.state;
    console.log(this.state.plan, 'this.state.plan');
    return (
      <div>
        {/* <div className="zoom-bar">
          <Toolbar zoom={currentZoom} onZoomChange={this.handleZoomChange} />
        </div> */}
        <div className="gantt-container">
          {/* <Gantt columns={columns} tasks={data} zoom={'Days'} onDataUpdated={this.logDataUpdate} /> */}
          {/* <Gantt columns={columns} tasks={data} zoom={'Days'} onDataUpdated={this.logDataUpdate} /> */}
          {this.state.plan && this.state.plan.data && this.state.plan.data.length > 0 && (
            <Gantt
              columns={this.state.columns}
              tasks={this.state.plan}
              zoom={'Days'}
              onDataUpdated={this.logDataUpdate}
              taskDbclickCb={this.taskDbclickCb}
              taskClickCb={this.taskClickCb}
            />
          )}
        </div>
        {/* <MessageArea messages={messages} /> */}
      </div>
    );
  }
}

export default withRouter(PlanningGantt);
