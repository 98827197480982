import React, { useState, useEffect } from 'react';
import http from '../../../http';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import {
  requiredLabel,
  searchEngine,
  timeToDuration,
  getSelectOptions,
  demandsDataAdapter,
  isCanDelete,
  getDomHeight,
} from '../../../utils/common';
import SearchGroup from '../../../components/SearchGroup';
import { useLocation } from 'react-router-dom';
import BreadNav from '../../../components/breadNav';

import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Breadcrumb,
  DatePicker,
  ConfigProvider,
  message,
  Descriptions,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import { dateFix } from '../../../utils/common';

const { confirm } = Modal;
const format = 'HH:mm';
const dateFormat = 'YYYY/MM/DD HH:mm';

export default function Productionlist() {
  const [isModalVisibleInfo, setIsModalVisibleInfo] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [reference, setReference] = useState('');
  const [opplan_net_duration, setOpplan_net_duration] = useState('');
  const [quantity, setQuantity] = useState('1');
  const [status, setStatus] = useState('');
  const [operation, setOperation] = useState('');
  const [startdate, setStartdate] = useState('');
  const [enddate, setEnddate] = useState('');
  const [whichItem, setWhichItem] = useState('');
  const [gyList, setGyList] = useState([]);
  const [itemStatus, setItemStatus] = useState([
    { key: 'proposed', value: '已提议' },
    { key: 'completed', value: '已采用' },
    { key: 'closed', value: '不采用' },
    { key: 'approved', value: '协商' },
  ]);
  const [itemStatusValue, setItemStatusValue] = useState();
  const [dataCopy, setDataCopy] = useState([]);
  const location = useLocation();

  //报工记录
  const [recordVisible, setRecordVisible] = useState(false);
  const [recordArr, setRecordArr] = useState([]);

  //人员信息
  const [addr, setAddr] = useState();
  const [contactMobile, setContactMobile] = useState();
  const [contactName, setContactName] = useState();
  const [name, setName] = useState();

  //表头
  const columns = [
    {
      title: '编号',
      dataIndex: 'reference',
      fixed: 'left',
      width: 100,
    },
    {
      title: '状态',
      dataIndex: 'computed_color',
      width: 100,
      render: (value) => {
        if (parseInt(value) > 0) {
          return <span>提前</span>;
        } else if (parseInt(value) < 0) {
          return <span>延迟</span>;
        } else {
          return <span>准时</span>;
        }
      },
    },
    {
      title: '产品名称',
      dataIndex: 'item__name',
      width: 200,
    },
    {
      title: '工厂',
      dataIndex: 'operation__location__name',
      width: 200,
    },
    {
      title: '工艺路径',
      dataIndex: 'operation',
      width: 200,
    },
    {
      title: '开始时间',
      dataIndex: 'startdate',
      width: 200,
      render: (value) => {
        return dateFix(value);
      },
    },
    {
      title: '结束时间',
      dataIndex: 'enddate',
      width: 200,
      render: (value) => {
        return dateFix(value);
      },
    },
    {
      title: '持续时间',
      dataIndex: 'opplan_duration',
      width: 200,
      render: (value) => {
        return timeToDuration(value);
      },
    },
    {
      title: '加工时间',
      dataIndex: 'opplan_net_duration',
      width: 200,
      render: (value) => {
        return timeToDuration(value);
      },
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      width: 200,
      render: (value) => {
        return value && parseFloat(value).toFixed(2);
      },
    },
    {
      title: '需求',
      dataIndex: 'demands',
      width: 200,
    },
    {
      title: '计划状态',
      dataIndex: 'status',
      width: 200,
      render: (text) => {
        switch (text) {
          case 'proposed':
            return '已提议';
          case 'completed':
            return '已采用';
          case 'closed':
            return '不采用';
          case 'approved':
            return '协商';
          default:
            return '';
        }
      },
    },
    {
      title: '更新时间',
      dataIndex: 'lastmodified',
      width: 200,
      sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
      render: (text) => {
        return dateFix(text);
      },
    },
    {
      title: '操作',
      dataIndex: '',
      width: 250,
      fixed: 'right',
      render: (text) => (
        <div>
          <Button
            type="link"
            onClick={(e) => {
              setRecordVisible(true);
              recordDetails(text.reference);
            }}
          >
            报工记录
          </Button>
          <Button
            type="link"
            text={JSON.stringify(text)}
            onClick={(e) => {
              text.quantity = parseInt(text.quantity);
              setEditValue(text, 'edit');
              setIsModalVisible(true);
            }}
          >
            编辑
          </Button>
          <Button
            type="text"
            danger
            onClick={(e) => {
              setOptType('delete');
              console.log(text, 'text');
              showDeleteConfirm('delete', text.reference);
            }}
          >
            删除
          </Button>
          {text.status == 'completed' && text.source ? (
            <Button type="link" onClick={() => setInfo(text, 'clientele')}>
              客户
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ];
  const getGyList = () => {
    http.get(
      'data/input/operation/',
      {
        format: 'json',
        rows: 10000,
        page: 1,
      },
      function (response) {
        setGyList(response.data.rows);
      }
    );
  };
  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };
  //获取报工记录
  const recordDetails = (params) => {
    http.postNkJSON(
      'operationplanresource/getdatalist',
      {
        SortField: 'operationplan_id',
        SortType: '',
        Search: {
          Condition: 'operationplan_id',
          Keyword: params,
        },
      },
      function (response) {
        if (response.data && response.data.Data) {
          let data = response.data.Data;
          setRecordArr(data);
        }
      }
    );
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  //查看客户
  const setInfo = (text, info) => {
    if (info === 'clientele') {
      //客户
      setIsModalVisibleInfo(true);
      http.customGet(`/nkapi/center/company/${text.source}`).then((res) => {
        if (res.data) {
          const { addr, contactMobile, contactName, name } = res.data.Data;
          setAddr(addr);
          setContactMobile(contactMobile);
          setContactName(contactName);
          setName(name);
        }
      });
    }
  };
  //删除提示
  const showDeleteConfirm = (optType, name) => {
    // 已采用数据不可删除
    const arr = optType === 'delete' ? [name] : selectedRowKeys;
    if (isCanDelete(dataCopy, arr, 'reference')) {
      return false;
    }
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (optType === 'delete') {
          deleteItem(name);
        } else if (optType === 'deleteBatch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const handleDateParams = () => {
    const _dateFormat = 'YYYY/MM/DD';
    const _format = 'HH:mm';
    return {
      startdate_0: moment(startdate).format(_dateFormat),
      startdate_1: moment(startdate).format(_format),
      enddate_0: moment(enddate).format(_dateFormat),
      enddate_1: moment(enddate).format(_format),
    };
  };
  //编辑
  const editItem = () => {
    http.post(
      `detail/input/manufacturingorder/${whichItem}/`,
      {
        _save: '保存',
        reference,
        opplan_net_duration,
        quantity,
        operation,
        status,
        // startdate_0,
        // startdate_1,
        // enddate_0,
        // enddate_1,
        ...handleDateParams(),
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //删除
  const deleteItem = (name) => {
    http.post(
      `data/input/manufacturingorder/${name}/delete/`,
      {
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    console.log(selectedRowKeys);
    http.postJSON(
      `data/input/manufacturingorder/`,
      [{ delete: selectedRowKeys }],
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
          setSelectedRowKeys([]); //批量删除时重置setSelectedRowKeys
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //表单提交
  const handleOK = () => {
    if (!reference) {
      return message.error('请输入编号');
    }
    if (!quantity) {
      return message.error('请输入数量');
    }
    if (!operation) {
      return message.error('请输入工艺路径');
    }
    if (!status) {
      return message.error('请输入状态');
    }
    if (!startdate) {
      return message.error('请输入开始日期');
    }
    if (!enddate) {
      return message.error('请输入结束日期');
    }

    if (optType === 'add') {
      addProductionlist();
    } else if (optType === 'edit') {
      editItem();
    }
  };

  const addProductionlist = () => {
    http.post(
      'data/input/manufacturingorder/add/',
      {
        _save: '保存',
        reference,
        opplan_net_duration,
        quantity,
        operation,
        status,
        // startdate_0,
        // startdate_1,
        // enddate_0,
        // enddate_1,
        ...handleDateParams(),
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('添加成功');
          pageList();
        } else {
          alert(response.data.status);
        }
      }
    );
  };

  const pageList = (initFlag) => {
    http.get(
      'data/input/manufacturingorder/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        const res = demandsDataAdapter(response.data.rows);
        setData(res);
        setDataCopy(res);
        if (initFlag) {
          const params = location.params || {};
          onSearch(
            [
              {
                key: 'reference',
                value: params.reference,
              },
            ],
            res
          );
        }
      }
    );
  };

  const saveOrders = async (items, status) => {
    const params = items.map((item) => {
      return {
        id: item,
        status,
      };
    });
    const url = `data/input/manufacturingorder/`;
    return http.post(url, params);
  };
  const setEditValue = (item, type) => {
    // console.log(item, type, '========');
    setContent({ title: type === 'edit' ? '计划调整' : '新增信息' });
    setOptType(type);
    setWhichItem(item.reference);
    setReference(item.reference);
    setQuantity(item.quantity);
    setOperation(item.operation);
    setStatus(item.status);
    // setStartdate_0(item.startdate_0);
    // setStartdate_1(item.startdate_1);
    // setEnddate_1(item.enddate_1);
    // setEnddate_0(item.enddate_0);
    setStartdate(item.startdate ? moment(item.startdate, dateFormat) : '');
    setEnddate(item.enddate ? moment(item.enddate, dateFormat) : '');
    setIsModalVisible(true);
  };

  const onSearch = (keys, list) => {
    list = list || dataCopy;
    if (keys.every((key) => !key.value)) {
      pageList();
    } else {
      const arr = searchEngine(list, keys);
      setData(arr);
    }
  };

  useEffect(() => {
    pageList('init');
    getGyList();
  }, []);

  return (
    <div className="layout-content">
      <div className="content-header">
        <div className="productionlist">
          <Modal
            title="客户信息"
            visible={isModalVisibleInfo}
            maskClosable={false}
            onCancel={() => {
              setIsModalVisibleInfo(false);
            }}
            footer={null}
          >
            <Descriptions column={1}>
              <Descriptions.Item label="地址">{addr}</Descriptions.Item>
              <Descriptions.Item label="电话">{contactMobile}</Descriptions.Item>
              <Descriptions.Item label="姓名">{contactName}</Descriptions.Item>
              <Descriptions.Item label="企业">{name}</Descriptions.Item>
            </Descriptions>
          </Modal>
          <Modal
            title="报工记录"
            visible={recordVisible}
            maskClosable={false}
            onCancel={() => {
              setRecordVisible(false);
            }}
            footer={null}
          >
            {/* <Descriptions column={1}>
              <Descriptions.Item label="开始时间">{recordStartdate}</Descriptions.Item>
              <Descriptions.Item label="结束时间">{recordEnddate}</Descriptions.Item>
              <Descriptions.Item label="数量">{recordQuantity}</Descriptions.Item>
            </Descriptions> */}
            <Table
              size="small"
              dataSource={recordArr}
              pagination={false}
              rowKey={(data) => data.id}
              columns={[
                {
                  title: '开始时间',
                  dataIndex: 'startdate',
                  render: (value) => {
                    return dateFix(value);
                  },
                },
                {
                  title: '结束时间',
                  dataIndex: 'enddate',
                  render: (value) => {
                    return dateFix(value);
                  },
                },
                {
                  title: '数量',
                  dataIndex: 'quantity',
                },
              ]}
              bordered
            />
          </Modal>
          <Modal
            title={content.title}
            visible={isModalVisible}
            maskClosable={false}
            okText="提交"
            cancelText="取消"
            onOk={handleOK}
            onCancel={() => {
              setIsModalVisible(false);
            }}
          >
            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal">
              <Form.Item label={requiredLabel('编号')}>
                <Input
                  value={reference}
                  onChange={(e) => {
                    setReference(e.target.value);
                  }}
                  placeholder="唯一标识符"
                  disabled={optType === 'edit'}
                />
              </Form.Item>

              <Form.Item label={requiredLabel('数量')}>
                <Input
                  value={quantity}
                  defaultValue=""
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label={requiredLabel('工艺路径')}>
                <Select
                  showSearch
                  onSelect={(value) => {
                    setOperation(value);
                  }}
                  value={operation}
                >
                  {gyList.map((item) => (
                    <Select.Option value={item.name} key={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={requiredLabel('状态')}>
                <Select
                  onSelect={(value) => {
                    setStatus(value);
                  }}
                  value={status}
                >
                  {itemStatus.map((item) => {
                    return <Select.Option value={item.key}>{item.value}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item label={requiredLabel('开始日期')}>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    format={dateFormat}
                    value={startdate}
                    showTime={{ defaultValue: moment('00:00', format) }}
                    onChange={(date, dateStr) => {
                      setStartdate(date);
                    }}
                  />
                </ConfigProvider>
              </Form.Item>
              <Form.Item label={requiredLabel('结束日期')}>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    format={dateFormat}
                    value={enddate}
                    showTime={{ defaultValue: moment('00:00', format) }}
                    onChange={(date, dateStr) => {
                      setEnddate(date);
                    }}
                  />
                </ConfigProvider>
              </Form.Item>
            </Form>
          </Modal>
          <BreadNav />
          <SearchGroup
            items={[
              {
                label: '产品名称',
                name: 'item__name',
              },
              {
                label: '工厂名称',
                name: 'operation__location__name',
              },
              {
                label: '需求',
                name: 'require',
                type: 'select',
                className: 'requireClass',
                options: getSelectOptions(dataCopy, 'require'),
              },
              {
                label: '状态',
                name: 'status',
                type: 'select',
                options: getSelectOptions(itemStatus, 'key'),
              },
            ]}
            onSearch={onSearch}
          ></SearchGroup>
          <br />
          <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => {
              setEditValue({}, 'add');
              setIsModalVisible(true);
            }}
          >
            &nbsp;新建
          </Button>{' '}
          &nbsp;&nbsp;
          <Button
            type="primary"
            shape="round"
            size="middle"
            danger
            onClick={() => {
              setOptType('deleteBatch');
              showDeleteConfirm('deleteBatch');
            }}
          >
            删除
          </Button>
          {/* TODO: */}
          <Select
            style={{ width: 200, marginLeft: 20 }}
            placeholder="状态修改"
            allowClear
            value={itemStatusValue}
            onSelect={(value) => {
              console.log(value, '=====');
              setItemStatusValue(value);
              selectedRowKeys.forEach((name) => {
                data.forEach((target) => {
                  if (target.reference == name) {
                    target.status = value;
                  }
                });
              });
              setData(data);
            }}
            onClear={() => {
              setItemStatusValue('');
            }}
          >
            {itemStatus.map((item) => {
              // TODO:
              return (
                <Select.Option value={item.key} key={item.key}>
                  {item.value}
                </Select.Option>
              );
            })}
          </Select>
          <Button
            type="primary"
            shape="round"
            size="middle"
            style={{ marginLeft: 20 }}
            onClick={async () => {
              try {
                const res = await saveOrders(selectedRowKeys, itemStatusValue);
                console.log('save success');
                if (res.data.status == 'success') {
                  message.success('操作成功！');
                  getGyList();
                } else {
                  message.error('服务异常！');
                }
              } catch (e) {
                console.log(e);
                message.error('服务异常！');
              }
            }}
          >
            发布/回传
          </Button>
          {/* <Button
        type="primary"
        shape="round"
        size="middle"
        style={{ marginLeft: 20 }}
        onClick={async () => {
          const key = 'updatable';
          message.loading({ content: 'Loading...', key });
          setTimeout(() => {
            message.success({ content: '执行成功!', key, duration: 2 });
          }, 1000);
        }}
      >
        发布/回传
      </Button> */}
          <br />
          <br />
        </div>
      </div>
      <div className="content-body">
        <Table
          scroll={{ x: '100vw' }}
          size="small"
          sticky
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.reference}
          bordered
          style={{
            height: getDomHeight(),
          }}
          scroll={{
            y: getDomHeight(),
            // x: '100vw',
          }}
          pagination={{
            pageSize: 15,
          }}
        />
      </div>
    </div>
  );
}
