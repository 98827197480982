import React from 'react';
import moment from 'moment';
import http from '../../../http';
import filterItem from '../../../utils/filterItem';
import { Table, message, Button, Modal, Form, Input, Select } from 'antd';
import BreadNav from '../../../components/breadNav';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import { searchEngine, getDomHeight } from '../../../utils/common';
import SearchGroup from '../../../components/SearchGroup';

const { confirm } = Modal;
const { Option } = Select;
export default class Production extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isModalVisible: false,
      isCreateModalVisible: false,
      content: { title: '' },
      type: '',
      staticData: [],
      data: [],
      name: '',
      description: '',
      owner: '',
      owners: [],
      names: [],
      whichItem: '',
      status: '',
      height: '',
      classify: [],
      currentItem: { name: '全部' },
    };
    this.classifyFormRef = React.createRef();
    this.formRef = React.createRef();

    //表头
    this.columns = [
      // {
      //   title: '分类',
      //   dataIndex: 'owner',
      //   sorter: (a, b) => String(a.owner).length - String(b.owner).length,
      //   sortDirections: ['descend', 'ascend'],
      //   render: (text) => (text === 'All items' ? '全部' : text),
      // },
      {
        title: '名称',
        dataIndex: 'name',
        width: 200,
        sorter: (a, b) => String(a.name).length - String(b.name).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '描述',
        dataIndex: 'description',
        width: 200,
        sorter: (a, b) => String(a.description).length - String(b.description).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '更新时间',
        dataIndex: 'lastmodified',
        width: 200,
        sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
        render: (text) => text.split('.')[0],
      },
      // {
      //   title: '操作',
      //   dataIndex: '',
      //   width: 180,
      //   fixed: 'right',
      //   render: (text) => {
      //     let { status } = this.state;

      //     return (
      //       <div>
      //         <Button
      //           type="link"
      //           text={JSON.stringify(text)}
      //           onClick={(e) => {
      //             if (!status) return;
      //             let { name, description, owner } = JSON.parse(
      //               e.currentTarget.getAttribute('text')
      //             );
      //             this.setState(
      //               {
      //                 name,
      //                 description,
      //                 owner,
      //                 content: { title: '编辑产品信息' },
      //                 type: 'edit',
      //                 isModalVisible: true,
      //                 status: 'edit',
      //                 whichItem: name,
      //               },
      //               () => {
      //                 if (this.formRef.current && this.formRef.current.setFieldsValue) {
      //                   this.formRef.current.setFieldsValue({
      //                     name,
      //                     description,
      //                     owner,
      //                   });
      //                 }
      //               }
      //             );
      //           }}
      //         >
      //           编辑
      //         </Button>
      //         <Button
      //           type="text"
      //           danger
      //           name={text.name}
      //           onClick={(e) => {
      //             if (!status) return;
      //             let name = e.target.parentNode.name;
      //             this.setState(
      //               {
      //                 status: 'delete',
      //                 type: 'delete',
      //                 whichItem: name,
      //               },
      //               this.showDeleteConfirm
      //             );
      //           }}
      //         >
      //           删除
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ];
    this.bodyRef = React.createRef();
  }

  //表格选中行
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  //对话框
  showDeleteConfirm = () => {
    let { type } = this.state;
    let _this = this;
    confirm({
      title: '您确定要删除该项数据吗？对应的销售订单也会同时删除！',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          _this.deleteItem();
        } else if (type === 'deleteBatch') {
          _this.deleteBatch();
        }
      },
      onCancel() {},
    });
  };
  //列表
  itemList = () => {
    let rules = [];
    if (this.state.currentItem.name === '全部') {
      rules = [
        { field: 'owner', op: 'ne', data: this.state.currentItem.name },
        { field: 'name', op: 'ne', data: '全部' },
        { field: 'name', op: 'ne', data: 'All items', filtercount: 0 },
      ];
    } else {
      rules = [
        { field: 'owner', op: 'eq', data: this.state.currentItem.name },
        { field: 'name', op: 'ne', data: 'All items', filtercount: 0 },
      ];
    }
    http.get(
      'data/input/item/',
      {
        format: 'json',
        _search: false,
        rows: 100,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
        filters: {
          groupOp: 'AND',
          rules,
        },
      },
      (response) => {
        if (response.data && response.data.rows) {
          let data = response.data.rows;
          let owners = filterItem(data, 'name');
          owners.push('');
          this.setState({ owners });
          this.setState({
            staticData: data,
            data,
            status: 'ready',
          });
        }
      }
    );
  };
  //列表
  classifyList = () => {
    http.get(
      'data/input/item/',
      {
        format: 'json',
        _search: false,
        rows: 100,
        page: 1,
        sidx: 'lastmodified',
        sord: 'asc',
        filters: {
          groupOp: 'OR',
          rules: [
            // { field: 'name', op: 'eq', data: '全部' },
            { field: 'owner', op: 'eq', data: '全部' },
          ],
        },
      },
      (response) => {
        if (response.data && response.data.rows) {
          let data = response.data.rows;
          let owners = filterItem(data, 'name');
          owners.push('');
          const classify = this.getClassify(response.data.rows);

          this.setState({ owners });
          this.setState({
            status: 'ready',
            classify,
          });
        }
      }
    );
  };
  //新建
  addItem = (type) => {
    let { name, description, owner } = this.state;
    let _this = this;
    if (type === 'classify') {
      owner = name === '全部' ? '' : '全部';
    }

    http.post(
      'data/input/item/add/',
      {
        csrfmiddlewaretoken: 'mV3EB7B9SJLO7LZTHNU1fcakr9z75W1npDcy8YjuzB6waYxxBihC76xbLqdOcIGg',
        _save: '保存',
        name,
        description,
        owner,
        // category: name,
        subcategory: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
              isCreateModalVisible: false,
            },
            () => {
              _this.itemList();
              _this.classifyList();
            }
          );
        } else {
          message.error('操作失败，请检查数据是否存在！');
        }
      }
    );
  };
  //编辑
  editItem = () => {
    let { name, description, owner, whichItem } = this.state;
    let _this = this;

    http.post(
      `detail/input/item/${whichItem}/`,
      {
        csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        _save: '保存',
        name,
        description,
        owner,
        category: '',
        subcategory: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
              _this.classifyList();
            }
          );
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //删除
  deleteItem = () => {
    let { whichItem } = this.state;
    let _this = this;
    http.post(
      `data/input/item/${whichItem}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.itemList();
          _this.classifyList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  deleteBatch = () => {
    let { type, selectedRowKeys } = this.state;
    let _this = this;

    if (type !== 'deleteBatch') return;
    http.postJSON(`data/input/item/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        _this.itemList();
        _this.classifyList();
      } else {
        message.error('操作失败！');
      }
    });
  };
  //表单提交
  handleOK = async (flag, form) => {
    try {
      await form.current.validateFields();
      let { type } = this.state;
      if (type === 'add') {
        this.addItem(flag);
      } else if (type === 'edit') {
        this.editItem(flag);
      }
    } catch (e) {}
  };

  //模糊查询
  onSearch = (keys) => {
    console.log('onSearch');
    const { staticData } = this.state;
    if (keys.every((key) => !key.value)) {
      this.itemList();
      this.classifyList();
    } else {
      const arr = searchEngine(staticData, keys);
      this.setState({
        data: arr,
      });
    }
  };
  getHeight = (bodyRef) => {
    return bodyRef.current?.offsetHeight - 100;
  };
  getClassify = (data) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    return [...new Set([{ name: '全部' }, ...dataCopy.map((item) => item)])];
  };
  classifyChange = (currentItem) => {
    this.setState({ currentItem }, () => {
      this.itemList();
      this.classifyList();
    });
    // if (currentItem === '全部') {
    //   this.itemList();
    //   return false;
    // }
    // const { staticData } = this.state;
    // const keys = [
    //   {
    //     key: 'owner',
    //     value: currentItem,
    //   },
    // ];
    // const arr = searchEngine(staticData, keys);
    // this.setState({
    //   data: arr,
    // });
  };

  componentDidMount() {
    this.itemList();
    this.classifyList();
    setTimeout(() => {
      this.setState({ height: this.getHeight(this.bodyRef) });
    });
  }

  render() {
    const {
      name,
      description,
      owner,
      owners,
      isModalVisible,
      isCreateModalVisible,
      content,
      data,
      selectedRowKeys,
      height,
      classify,
      currentItem,
    } = this.state;
    const { onSearch, bodyRef } = this;
    return (
      <div className="modal-list">
        <Modal
          title={content.title}
          visible={isModalVisible}
          maskClosable={false}
          okText="提交"
          cancelText="取消"
          onOk={() => {
            console.log('product ok');
            this.handleOK('product', this.formRef);
          }}
          onCancel={() => {
            this.setState({ isModalVisible: false });
          }}
        >
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            ref={this.formRef}
            initialValues={{
              name: '',
              description: '',
              owner: '',
            }}
            onValuesChange={(changedValues, allValues) => {
              this.setState({
                ...changedValues,
              });
            }}
          >
            <Form.Item label="名称" name="name" rules={[{ required: true, message: '请输入名称' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="描述" name="description">
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              label="分类"
              name="owner"
              rules={[{ required: true, message: '请输入分类' }]}
            >
              <Select showSearch>
                {classify
                  .filter((item) => item.name != '全部')
                  .map((item) => (
                    <Option value={item.name} key={item.name}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={content.title}
          visible={isCreateModalVisible}
          maskClosable={false}
          okText="提交"
          cancelText="取消"
          onOk={() => {
            console.log('classify ok');
            this.handleOK('classify', this.classifyFormRef);
          }}
          onCancel={() => {
            this.setState({ isCreateModalVisible: false });
          }}
        >
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            ref={this.classifyFormRef}
            initialValues={{
              name: '',
              description,
            }}
            onValuesChange={(changedValues, allValues) => {
              this.setState({
                ...changedValues,
              });
            }}
          >
            <Form.Item
              label="分类名称"
              name="name"
              rules={[{ required: true, message: '请输入分类名称' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="描述" name="description">
              <Input.TextArea />
            </Form.Item>
          </Form>
        </Modal>
        <div className="layout-content">
          <div className="content-header">
            <BreadNav />
          </div>

          <div className="content-body">
            <div className="list-left">
              <div className="classify-wrapper">
                <div className="classify-header">
                  分类
                  <Button
                    type="link"
                    size="middle"
                    style={{ padding: '0px' }}
                    onClick={() => {
                      if (
                        this.classifyFormRef.current &&
                        this.classifyFormRef.current.setFieldsValue
                      ) {
                        this.classifyFormRef.current.setFieldsValue({
                          name: '',
                          description: '',
                          owner: '',
                        });
                      }
                      this.setState({
                        type: 'add',
                        isCreateModalVisible: true,
                        content: { title: '新建分类' },
                      });
                    }}
                  >
                    +&nbsp;新建分类
                  </Button>
                </div>
                <div className="classify-content">
                  {classify.map((item) => {
                    return (
                      <div
                        className="classify-item"
                        key={item.name}
                        style={{ background: item.name === currentItem.name ? '#d6eafd' : '' }}
                        onClick={() => {
                          this.classifyChange(item);
                        }}
                      >
                        <div>{item.name}</div>
                        {item.name != '全部' && (
                          <Button
                            type="link"
                            size="middle"
                            style={{ padding: '0px' }}
                            danger
                            onClick={() => {
                              console.log('删除分类');
                              this.setState(
                                {
                                  status: 'delete',
                                  type: 'delete',
                                  whichItem: item.name,
                                },
                                this.showDeleteConfirm
                              );
                            }}
                          >
                            删除
                          </Button>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="list-right">
              <div className="right-header">
                <SearchGroup
                  className="search-group"
                  items={[
                    {
                      label: '名称',
                      name: 'name',
                    },
                  ]}
                  onSearch={onSearch}
                ></SearchGroup>
                {/* <Button
                  type="primary"
                  shape="round"
                  size="middle"
                  onClick={() => {
                    console.log('add product');
                    if (this.formRef.current && this.formRef.current.setFieldsValue) {
                      this.formRef.current.setFieldsValue({
                        name: '',
                        description: '',
                        owner: '',
                      });
                    }
                    this.setState({
                      type: 'add',
                      isModalVisible: true,
                      content: { title: '新建产品信息' },
                    });
                  }}
                >
                  +&nbsp;新建
                </Button>
                &nbsp;&nbsp;
                <Button
                  type="primary"
                  shape="round"
                  size="middle"
                  danger
                  onClick={() => {
                    this.setState(
                      {
                        type: 'deleteBatch',
                      },
                      () => {
                        this.showDeleteConfirm('deleteBatch');
                      }
                    );
                  }}
                >
                  删除
                </Button>
                <br />
                <br /> */}
              </div>
              <div className="right-content">
                <Table
                  style={{
                    height: getDomHeight('right-content', 0),
                  }}
                  size="small"
                  // rowSelection={{
                  //   selectedRowKeys,
                  //   onChange: this.onSelectChange,
                  // }}
                  columns={this.columns}
                  dataSource={data}
                  rowKey={(record) => record.name}
                  bordered
                  pagination={{
                    defaultPageSize: 20,
                  }}
                  scroll={{ y: getDomHeight('right-content', 0) }}
                  scrollToFirstRowOnChange={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
