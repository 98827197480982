import React, { useState, useEffect } from 'react';
import http from '../../../http';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import {
  Table,
  Button,
  Modal,
  Form,
  InputNumber,
  Select,
  Breadcrumb,
  message,
  DatePicker,
  ConfigProvider,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import { searchEngine, getDomHeight } from '../../../utils/common';
import SearchGroup from '../../../components/SearchGroup';
import BreadNav from '../../../components/breadNav';
const { confirm } = Modal;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
let today = moment().format(dateFormat); // 当天日期
let addToday = moment().add(1, 'years').format(dateFormat); // 一年后日期

export default function Skill() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [operation, setOperation] = useState('');
  const [resource, setResource] = useState('');
  const [quantity, setQuantity] = useState('');
  const [startdate, setStartdate] = useState('');
  const [enddate, setEnddate] = useState('');
  const [whichItem, setWhichItem] = useState('');
  const [id, setId] = useState('');
  const [gyList, setGyList] = useState([]);
  const [resourceList, setResourceList] = useState([]);
  const [effective_start, setEffective_start] = useState();
  const [effective_end, setEffective_end] = useState();
  //表头
  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
      width: 80,
    },
    {
      title: '工艺路径',
      dataIndex: 'operation',
      width: 200,
      sorter: (a, b) => a.operation.localeCompare(b.operation),
      sortDirections: ['descend', 'ascend'],
      render: (value) => {
        return <span>{value}</span>;
      },
    },
    {
      title: '资源',
      dataIndex: 'resource',
      width: 200,
      sorter: (a, b) => a.resource.localeCompare(b.resource),
      sortDirections: ['descend', 'ascend'],
      render: (value) => {
        return <span>{value}</span>;
      },
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      width: 100,
      render: (value) => {
        return <span>{parseFloat(value).toFixed(2)}</span>;
      },
    },
    {
      title: '更新时间',
      dataIndex: 'lastmodified',
      width: 200,
      sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
      render: (text) => text.split('.')[0],
    },
    {
      title: '有效开始时间',
      dataIndex: 'effective_start',
      width: 200,
    },
    {
      title: '有效结束时间',
      dataIndex: 'effective_end',
      width: 200,
    },
    {
      title: '操作',
      dataIndex: '',
      width: 200,
      render: (text) => (
        <div>
          <Button
            type="link"
            onClick={(e) => {
              setEditValue(text, 'edit');
            }}
          >
            编辑
          </Button>
          <Button
            type="text"
            danger
            onClick={() => {
              showDeleteConfirm('delete', text.id);
            }}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const getGyList = () => {
    http.get(
      'data/input/operation/',
      {
        format: 'json',
        rows: 10000,
        page: 1,
      },
      function (response) {
        setGyList(response.data.rows);
      }
    );
  };

  const getResourceList = () => {
    http.get(
      'data/input/resource/',
      {
        format: 'json',
        rows: 10000,
        page: 1,
      },
      function (response) {
        setResourceList(response.data.rows);
      }
    );
  };
  //删除提示
  const showDeleteConfirm = (type, id) => {
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        console.log('123123123');
        if (type === 'delete') {
          deleteItem(id);
        } else if (type === 'batch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const setEditValue = (item, type) => {
    setContent({ title: type === 'edit' ? '编辑信息' : '新增信息' });
    setOptType(type);
    setId(item.id);
    setWhichItem(item.operation);
    setOperation(item.operation);
    setResource(item.resource);
    setQuantity(item.quantity);
    setStartdate(item.startdate);
    setEnddate(item.enddate);
    setIsModalVisible(true);

    if (type === 'add') {
      setEffective_start(today);
      setEffective_end(addToday);
    } else {
      setEffective_start(item.effective_start);
      setEffective_end(item.effective_end);
    }
  };
  //删除
  const deleteItem = (id) => {
    http.post(
      `data/input/operationresource/${id}/delete/`,
      {
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    console.log(selectedRowKeys);
    http.postJSON(
      `data/input/operationresource/`,
      [{ delete: selectedRowKeys }],
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  // 处理时间字段
  const handleDateParams = () => {
    const _dateFormat = 'YYYY/MM/DD';
    const _format = 'HH:mm';
    return {
      effective_start_0: moment(startdate).format(_dateFormat),
      effective_start_1: moment(startdate).format(_format),
      effective_end_0: moment(enddate).format(_dateFormat),
      effective_end_1: moment(enddate).format(_format),
    };
  };
  const addSkill = () => {
    http.post(
      'data/input/operationresource/add/',
      {
        _save: '保存',
        operation,
        resource,
        quantity,
        effective_start,
        effective_end,
        ...handleDateParams(),
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('添加成功');
          pageList();
        } else {
          alert(response.data.status);
        }
      }
    );
  };
  const editSkill = () => {
    const url = `data/input/operationresource/`;
    http.post(
      url,
      [
        {
          id,
          operation,
          resource,
          quantity,
          effective_start,
          effective_end,
          // ...handleDateParams()
        },
      ],
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('修改成功');
          pageList();
        } else {
          alert(response.data.status);
        }
      }
    );
  };

  const pageList = () => {
    http.get(
      'data/input/operationresource/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        setData(response.data.rows);
        setDataCopy(response.data.rows);
      }
    );
  };
  const onSearch = (keys) => {
    if (keys.every((key) => !key.value)) {
      pageList();
    } else {
      const arr = searchEngine(dataCopy, keys);
      setData(arr);
    }
  };

  useEffect(() => {
    pageList();
    getGyList();
    getResourceList();
  }, []);

  return (
    <div className="skill layout-content">
      <Modal
        title={content.title}
        visible={isModalVisible}
        maskClosable={false}
        okText="提交"
        cancelText="取消"
        onOk={() => {
          if (optType === 'add') {
            if (!operation) return message.error('工艺路径不能为空');
            if (!resource) return message.error('资源不能为空');
            if (!quantity) return message.error('数量不能为空');
            if (!effective_start) return message.error('有效开始时间不能为空');
            if (!effective_end) return message.error('有效结束时间不能为空');
            addSkill();
            setIsModalVisible(false);
          } else {
            if (!operation) return message.error('工艺路径不能为空');
            if (!resource) return message.error('资源不能为空');
            if (!quantity) return message.error('数量不能为空');
            if (!effective_start) return message.error('有效开始时间不能为空');
            if (!effective_end) return message.error('有效结束时间不能为空');
            editSkill();
            setIsModalVisible(false);
          }
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal">
          <Form.Item label="工艺路径">
            <Select
              showSearch
              allowClear={true}
              onSelect={(value) => {
                setOperation(value);
              }}
              value={operation}
              onChange={() => {
                setOperation('');
              }}
            >
              {gyList &&
                gyList.map((item) => (
                  <Select.Option value={item.name} key={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="资源">
            <Select
              showSearch
              allowClear={true}
              onSelect={(value) => {
                setResource(value);
              }}
              value={resource}
              onChange={() => {
                setResource('');
              }}
            >
              {resourceList &&
                resourceList.map((item) => (
                  <Select.Option value={item.name} key={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="数量">
            <InputNumber
              value={quantity}
              onChange={(value) => {
                setQuantity(value);
              }}
            />
          </Form.Item>
          <Form.Item label="有效开始时间" name="effective_start">
            <ConfigProvider locale={locale}>
              <DatePicker
                format={dateFormat}
                showTime
                value={effective_start ? moment(effective_start) : ''}
                onChange={(date, dateStr) => {
                  setEffective_start(dateStr);
                }}
              />
            </ConfigProvider>
          </Form.Item>
          <Form.Item label="有效结束时间" name="effective_end">
            <ConfigProvider locale={locale}>
              <DatePicker
                format={dateFormat}
                showTime
                value={effective_end ? moment(effective_end) : ''}
                onChange={(date, dateStr) => {
                  setEffective_end(dateStr);
                }}
              />
            </ConfigProvider>
          </Form.Item>
        </Form>
      </Modal>
      <div className="content-header">
        <BreadNav />
        <SearchGroup
          items={[
            {
              label: '工艺路径',
              name: 'operation',
            },
          ]}
          onSearch={onSearch}
        ></SearchGroup>
        <br />
        <Button
          type="primary"
          shape="round"
          size="middle"
          onClick={() => {
            setEditValue({}, 'add');
            setIsModalVisible(true);
          }}
        >
          &nbsp;新建
        </Button>{' '}
        &nbsp;&nbsp;
        <Button
          type="primary"
          shape="round"
          size="middle"
          danger
          onClick={() => {
            showDeleteConfirm('batch');
          }}
        >
          删除
        </Button>
        <br />
        <br />
      </div>
      <div className="content-body">
        <Table
          size="small"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.id}
          bordered
          pagination={{
            pageSize: 15,
          }}
          style={{
            height: getDomHeight(),
          }}
          scroll={{
            y: getDomHeight(),
            // x: '100vw',
          }}
        />
      </div>
    </div>
  );
}
