import React from 'react';
import { Breadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import menus from '../layout/menus';

export const checkPath = function (menus = menus, pathname) {
  let title, subTitle;
  let key, subKey;
  for (let i = 0; i < menus.length; i++) {
    for (let j = 0; j < menus[i].children.length; j++) {
      let to = menus[i].children[j].to;
      if (to === pathname) {
        key = i;
        title = menus[i].title;
        subKey = menus[i].key;
        subTitle = menus[i].children[j].title;
        return { title, subTitle, key, subKey };
      }
    }
  }
};

export default function BreadNav() {
  const history = useHistory();
  let pathname = history.location.pathname;
  let data = checkPath(menus, pathname);

  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      <Breadcrumb.Item
        style={{ cursor: 'pointer' }}
        onClick={() => {
          history.push({
            pathname: '/dashboard',
          });
        }}
      >
        {'主页'}
      </Breadcrumb.Item>
      <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
      <Breadcrumb.Item>{data.subTitle}</Breadcrumb.Item>
    </Breadcrumb>
  );
}
