import React, { Component } from 'react';
import * as echarts from 'echarts';

class Echarts extends Component {
  state = {};

  chart = null;

  componentDidMount() {
    const { chartId, option } = this.props; //继承父级传递的值
    const chartIdDiv = document.getElementById(chartId);
    if (chartIdDiv) {
      this.chart = echarts.init(chartIdDiv); //实例化echats画布
      if (this.chart) {
        this.renderChart(this.chart, option); //加载数据
        this.handleResize()
      }
    }
    window.addEventListener('resize', this.handleResize, false); //监听改变画布大小
  }

  /**
   * @description: 根据父级传递的值，是否更新画布
   * @param {type}
   * @return {type}
   */
  componentDidUpdate(prevProps) {
    console.log('echart componentDidUpdate')
    const { option } = this.props;
    if (prevProps.option !== option && this.chart) {
      this.renderChart(this.chart, option);
    }
  }
  componentWillReceiveProps() {
    console.log('echart componentWillReceiveProps')

  }

  /**
   * @description: 注销时，去除监听事件以及销毁echats
   * @param {type}
   * @return {type}
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false);
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }

  // 放大缩小重新布局
  handleResize = () => {
    if (this.chart) {
      setTimeout(() => {
        this.chart.resize();
      });
    }
  };
  // 图表配置及渲染
  renderChart = (chart, option) => {
    const { handleClick } = this.props;
    chart.clear();
    chart.setOption(option);
    chart.off('click'); // 要是不加上这行，事件会重复n次
    chart.on('click', (params) => {
      if (handleClick) {
        handleClick(params);
      }
    });
  };

  render() {
    const { chartId } = this.props;
    return <div id={chartId} style={{ width: '100%', height: '100%' }} />;
  }
}

export default Echarts;
