import React from 'react';
import * as echarts from 'echarts';
import { Table } from 'antd';
import BreadNav from '../../../components/breadNav';
import http from '../../../http';
import './index.scss';
const axios = require('axios');
export default class InventoryPlanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      at: 0,
      size: 10,
      data: [],
      dataGraph: [],
      dates: [],
      safetystockArr: [],
      consumedArr: [],
      consumedMOArr: [],
      producedArr: [],
    };
    this.columns = [
      {
        title: '产品名称',
        dataIndex: 'item',
        key: 'item',
        width: 200,
      },
      {
        title: '工厂',
        dataIndex: 'location',
        key: 'location',
        width: 200,
      },
      {
        title: '库存状态',
        dataIndex: 'color',
        key: 'color',
        width: 100,
        render: (text) => `${text}%`,
      },
      {
        title: '交货提前期',
        dataIndex: 'leadtime',
        key: 'leadtime',
        width: 160,
      },
      {
        title: '安全库存',
        dataIndex: 'safetystock',
        key: 'safetystock',
        width: 100,
        render: (text) => {
          return text ? parseInt(text) : '';
        },
      },
      {
        /**哪个字段 */
        title: '在采购数',
        dataIndex: 'reorderquanlity',
        key: 'reorderquanlity',
        width: 100,
        render: (text) => {
          return text ? parseInt(text) : '';
        },
      },

      {
        title: '现有库存',
        dataIndex: 'onhand',
        key: 'onhand',
        width: 100,
        render: (text) => {
          return text ? parseInt(text) : '';
        },
      },
    ];
  }

  //获取资源数据
  itemList = () => {
    let _this = this;

    http.get('data/get_buffer_plans/').then((response) => {
      let data = (response.data && response.data.rows) || [];
      data.forEach((item, index) => (item.key = index));
      _this.setState({
        data,
      });
      this.state.data[0] && this.itemGraph(this.state.data[0]);
    });
  };

  //获取单项计划数据
  itemGraph = (item) => {
    let _this = this;

    http
      .get('data/get_buffer_reports/', { productName: item.item, location_id: item.location })
      .then((response) => {
        let dataGraph = (response.data && response.data.rows) || [];
        let { dates, safetystockArr, consumedArr, consumedMOArr, producedArr } =
          _this.handleData(dataGraph);

        _this.setState(
          {
            dataGraph,
            safetystockArr,
            consumedArr,
            consumedMOArr,
            producedArr,
            dates,
          },
          () => {
            let container = document.getElementById('chart');
            _this.paintChart(container);
          }
        );
      });
  };

  //绘制图表
  paintChart = (ele) => {
    let myChart = echarts.init(ele);
    let option;
    let { dataGraph } = this.state;
    if (!dataGraph || !dataGraph[0]) return;

    let { safetystockArr, consumedArr, consumedMOArr, producedArr, dates } = this.state;

    option = {
      legend: {},
      tooltip: {
        show: true,
        trigger: 'axis',
        position: function (params, a) {
          return params;
        },
        formatter: function (params) {
          let index = params[0].dataIndex;
          let date = params[0].axisValue;
          let item = dataGraph[index];
          //期初、期末、安全库存  销售订单消耗    ${item.consumedSO}</br>  产品配送  ${item.producedDO}</br>  生产消耗  ${item.consumedMO}</br> 产品采购       ${item.producedPO}</br>
          let res = `<div>
                     <div>${date}</div>
                     开始时间      ${item.startdate}</br>
                     结束时间      ${item.enddate}</br>
                     安全库存      ${parseInt(item.safetystock)}</br>
                     已消耗        ${item.consumed}</br>
                     配送消耗       ${item.consumedDO}</br>
                     已生产         ${item.produced}</br>
                     产品生产       ${item.producedMO}</br></div>`;
          return res;
        },
      },
      xAxis: {
        type: 'category',
        data: dates,
        name: '日期',
        // show: false,
      },
      yAxis: {
        type: 'value',
        splitLine: { show: false },
        splitNumber: 4,
        name: '数量',
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
      },
      series: [
        {
          name: '生产数',
          data: producedArr,
          type: 'bar',
          itemStyle: {
            normal: {
              color: 'blue',
              lineStyle: {
                color: 'blue',
              },
            },
          },
        },
        {
          name: '消耗数',
          data: consumedArr,
          type: 'bar',
          itemStyle: {
            normal: {
              color: 'yellow',
              lineStyle: {
                color: 'yellow',
              },
            },
          },
        },
        {
          name: '安全库存',
          data: safetystockArr,
          type: 'line',
          itemStyle: {
            normal: {
              color: 'red',
              lineStyle: {
                color: 'red',
              },
            },
          },
        },
        {
          name: '开始库存',
          data: consumedMOArr,
          type: 'line',
          itemStyle: {
            normal: {
              color: 'green',
              lineStyle: {
                color: 'green',
              },
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  };

  //处理数据
  handleData = (data) => {
    /**
     *  日期
     * 期初库存
     * 生产
     * 消耗
     * 再订货数量
     * 安全库存 safetystock
     */
    let dates = [];
    let safetystockArr = [];
    let consumedArr = [];
    let consumedMOArr = [];
    let producedArr = [];

    data.forEach((item) => {
      // console.log('item: ', item);
      dates.push(item.bucket);
      safetystockArr.push(item.safetystock);
      consumedArr.push(item.consumed);
      consumedMOArr.push(item.onhand || 0);
      producedArr.push(item.produced);
    });

    return { dates, safetystockArr, consumedArr, consumedMOArr, producedArr };
  };

  componentDidMount() {
    this.itemList();
  }

  render() {
    return (
      <div>
        <BreadNav />
        <div className="ant-table-wrapper chart-container">
          <div id="chart" width={893} height={300}></div>
        </div>
        <Table
          columns={this.columns}
          dataSource={this.state.data}
          rowKey={(record) => record.key}
          size="small"
          onRow={(record) => {
            return {
              onClick: (event) => {
                this.itemGraph(record);
              }, // 点击行
            };
          }}
          bordered
        />
      </div>
    );
  }
}
