import React, { Component } from 'react';
import { gantt } from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { withRouter } from 'react-router-dom';
// const ganttInst = gantt.getGanttInstance()
// console.log(ganttInst,'ganttInst')

class Gantt extends Component {
  constructor(props) {
    super(props);
    this.initZoom();
  }

  // instance of gantt.dataProcessor
  dataProcessor = null;

  initZoom() {
    gantt.ext.zoom.init({
      levels: [
        {
          name: 'Hours',
          scale_height: 60,
          min_column_width: 30,
          scales: [
            { unit: 'day', step: 1, format: '%d %M' },
            { unit: 'hour', step: 1, format: '%H' },
          ],
        },
        {
          name: 'Days',
          scale_height: 60,
          min_column_width: 30,
          scales: [
            { unit: 'week', step: 1, format: '%Y-%m-%d' },
            { unit: 'day', step: 1, format: '%d' },
          ],
        },
        {
          name: 'Months',
          scale_height: 60,
          min_column_width: 70,
          scales: [
            { unit: 'month', step: 1, format: '%F' },
            { unit: 'week', step: 1, format: '#%W' },
          ],
        },
      ],
    });
  }

  setZoom(value) {
    gantt.ext.zoom.setLevel(value);
  }

  initGanttDataProcessor() {
    /**
     * type: "task"|"link"
     * action: "create"|"update"|"delete"
     * item: data object object
     */
    const onDataUpdated = this.props.onDataUpdated;
    this.dataProcessor = gantt.createDataProcessor((type, action, item, id) => {
      return new Promise((resolve, reject) => {
        if (onDataUpdated) {
          onDataUpdated(type, action, item, id);
        }

        // if onDataUpdated changes returns a permanent id of the created item, you can return it from here so dhtmlxGantt could apply it
        // resolve({id: databaseId});
        return resolve();
      });
    });
  }

  shouldComponentUpdate(nextProps) {
    // console.log(this.props.tasks, '=====this.props.tasks');
    return this.props.zoom !== nextProps.zoom;
  }

  myFunc(task) {
    if (task.id == 1) return "<div class='important'>" + task.text + ' (' + task.users + ') </div>';
    return task.text + ' (' + task.id + ')';
  }
  // tooltip
  linkTypeToString(linkType) {
    switch (linkType) {
      case gantt.config.links.start_to_start:
        return 'Start to start';
      case gantt.config.links.start_to_finish:
        return 'Start to finish';
      case gantt.config.links.finish_to_start:
        return 'Finish to start';
      case gantt.config.links.finish_to_finish:
        return 'Finish to finish';
      default:
        return '';
    }
  }
  getResourceAssignments(resource, store) {
    var assignments = [];
    if (store.hasChild(resource.id)) {
      store.eachItem(function (res) {
        assignments = assignments.concat(gantt.getResourceAssignments(res.id));
      }, resource.id);
    } else {
      assignments = gantt.getResourceAssignments(resource.id);
    }
    return assignments;
  }

  clearTooltip() {
    const doms = document.getElementsByClassName('gantt_tooltip');
    for (var i = 0; i < doms.length; i++) {
      doms[i].style.display = 'none';
    }
  }

  componentDidMount() {
    gantt.config.xml_date = '%Y-%m-%d %H:%i';
    gantt.templates.format_date = function (date) {
      return date;
    };
    gantt.config.columns = this.props.columns;
    gantt.i18n.setLocale('cn'); // 设置语言
    gantt.attachEvent('onTaskClick', (id, mode, e) => {
      // console.log('onTaskClick');

      if (mode.target.className === 'gantt_task_content') {
        const target = this.props.tasks.data.find((task) => task.id == id);
        this.props.taskClickCb(target);
        // if (targets && targets[0] && targets[0].type === 'MO') {
        //   this.props.history.push({
        //     pathname: '/main/manufacturing/productionList',
        //   });
        // } else {
        //   this.props.history.push({
        //     pathname: '/main/purchasing/purchaseorder',
        //   });
        // }
      }
      return true;
    });
    gantt.attachEvent('onTaskDblclick', (id, mode, e) => {
      // console.log('onTaskDblclick');
      if (mode.target.className.indexOf('custom_left_event') > -1) {
        const target = this.props.tasks.data.find((task) => task.id == id);
        this.props.taskDbclickCb(target);
      }
      return true;
    });
    gantt.config.quick_info_detached = false;

    // 双击task时，弹出lightbox弹出框
    gantt.config.details_on_dblclick = true;
    // 允许鼠标通过按住的lightbox头部拖拽的方式，调整lightbox的位置
    gantt.config.drag_lightbox = false;
    gantt.config.readonly = true;

    gantt.config.fit_tasks = true;
    gantt.config.min_column_width = 50;

    gantt.config.scale_height = 54;
    gantt.config.grid_resizer_attribute = 'gridresizer';

    // tooltip
    gantt.plugins({
      tooltip: true,
    });
    gantt.attachEvent('onGanttReady', function () {
      var tooltips = gantt.ext.tooltips;
      gantt.templates.tooltip_text = function (start, end, task) {
        var store = gantt.getDatastore('resource');
        var assignments = task[gantt.config.resource_property] || [];

        var owners = [];
        assignments.forEach(function (assignment) {
          var owner = store.getItem(assignment.resource_id);
          owners.push(owner.text);
        });
        return (
          '<b>操作:</b> ' +
          task.text +
          '<br/>' +
          '<b>类型:</b>' +
          task.type +
          '<br/>' +
          '<b>资源:</b>' +
          task.resources +
          '<br/>' +
          '<b>需求:</b>' +
          task.demand +
          '<br/>' +
          '<b>开始时间:</b> ' +
          gantt.templates.tooltip_date_format(start) +
          '<br/><b>结束时间:</b> ' +
          gantt.templates.tooltip_date_format(end)
        );
      };

      tooltips.tooltipFor({
        selector: '.gantt_task_link',
        html: function (event, node) {
          var linkId = node.getAttribute(gantt.config.link_attribute);
          if (linkId) {
            var link = gantt.getLink(linkId);
            var from = gantt.getTask(link.source);
            var to = gantt.getTask(link.target);

            return [
              '<b>Link:</b> ' + this.linkTypeToString(link.type),
              '<b>From: </b> ' + from.text,
              '<b>To: </b> ' + to.text,
            ].join('<br>');
          }
        },
      });

      tooltips.tooltipFor({
        selector: '.gantt_row[resource_id]',
        html: function (event, node) {
          var resourceId = node.getAttribute('resource_id');
          var store = gantt.getDatastore(gantt.config.resource_store);
          var resource = store.getItem(resourceId);
          var assignments = this.getResourceAssignments(resource, store);

          var totalDuration = 0;
          for (var i = 0; i < assignments.length; i++) {
            var task = gantt.getTask(assignments[i].task_id);
            totalDuration += task.duration * assignments[i].value;
          }

          return [
            '<b>Resource:</b> ' + resource.text,
            '<b>Tasks assigned:</b> ' + assignments.length,
            '<b>Total load: </b>' + (totalDuration || 0) + 'h',
          ].join('<br>');
        },
      });

      tooltips.tooltipFor({
        selector: '.gantt_scale_cell',
        html: function (event, node) {
          var relativePosition = gantt.utils.dom.getRelativeEventPosition(event, gantt.$task_scale);
          return gantt.templates.tooltip_date_format(gantt.dateFromPos(relativePosition.x));
        },
      });

      tooltips.tooltipFor({
        selector: '.gantt_resource_marker',
        html: function (event, node) {
          var dataElement = node.querySelector('[data-recource-tasks]');
          var ids = JSON.parse(dataElement.getAttribute('data-recource-tasks'));

          var date = gantt.templates.parse_date(dataElement.getAttribute('data-cell-date'));
          var resourceId = dataElement.getAttribute('data-resource-id');

          var relativePosition = gantt.utils.dom.getRelativeEventPosition(event, gantt.$task_scale);

          var store = gantt.getDatastore('resource');

          var html = [
            '<b>' +
              store.getItem(resourceId).text +
              '</b>' +
              ', ' +
              gantt.templates.tooltip_date_format(date),
            '',
            ids
              .map(function (id, index) {
                var task = gantt.getTask(id);
                var assignenment = gantt.getResourceAssignments(resourceId, task.id);
                var amount = '';
                var taskIndex = index + 1;
                if (assignenment[0]) {
                  amount = ' (' + assignenment[0].value + 'h) ';
                }
                return 'Task #' + taskIndex + ': ' + amount + task.text;
              })
              .join('<br>'),
          ].join('<br>');

          return html;
        },
      });
    });

    // 处理tooltip显示问题
    setTimeout(() => {
      const gantt = document.getElementsByClassName('gantt-container')[0];
      gantt.onmouseover = function () {
        const doms = document.getElementsByClassName('gantt_tooltip');
        for (var i = 0; i < doms.length; i++) {
          doms[i].style.display = 'block';
          doms[i].onmouseover = function () {
            this.style.display = 'none';
          };
        }
      };
      gantt.onmouseleave = () => {
        this.clearTooltip();
      };
    });

    // 自定义label
    // function renderLabel(progress, sum) {
    //   // const to

    //   // var relWidth = progress / sum * 100;

    //   // var cssClass = "custom_progress ";
    //   // if (progress > 0.6) {
    //   //   cssClass += "nearly_done";
    //   // } else if (progress > 0.3) {
    //   //   cssClass += "in_progress";
    //   // } else {
    //   //   cssClass += "idle";
    //   // }
    //   return "<div class='" + 1 + "' onclick='alert(1)' + style='width:" + 1 + "%'>" + 1 + "</div>";

    // }
    // gantt.templates.task_text = function (start, end, task) {
    //   var summ = task.progress1 + task.progress2 + task.progress3;
    //   return renderLabel(11111111);
    // };

    // gantt.config.layout = {
    //   css: "gantt_container",
    //   rows:[
    //       {
    //         // the default layout
    //         cols: [
    //           {resizer: true, width: 1},
    //         ]
    //       },
    //       // {resizer: true, width: 1},
    //       // {
    //       //   // a custom layout
    //       //   cols: [
    //       //     {view: "grid", id: "resourceGrid", bind:"resource"},
    //       //     {resizer: true, width: 1},
    //       //     {view: "scrollbar", id:"resourceVScroll"}
    //       //   ]
    //       // },
    //       // {view: "scrollbar", id:"scrollHor"}
    //   ]
    // };
    // gantt.form_blocks["my_editor"]={
    //   render:function(sns){ //sns - the section's configuration object
    //       return "html code of the editor here";
    //   },
    //   set_value:function(node,value,task,section){
    //       //node - an html object related to the html defined above
    //       //value - a value defined by the map_to property
    //       //task - the task object
    //       //section- the section's configuration object
    //      console.log('set_value')
    //   },
    //   get_value:function(node,task,section){
    //       //node - an html object related to the html defined above
    //       //task - the task object
    //       //section - the section's configuration object
    //       return "current value from editor";
    //   },
    //   focus:function(node){
    //       //node - an html object related to the html defined above
    //       console.log('focus')

    //   }
    // }
    // gantt.addMarker({
    //   start_date: '18818',
    //   css: "today",
    //   text: "Today",
    //   title: "Today: "
    // });
    // gantt.$click.advanced_details_button=function(e, id, trg){
    //   console.log(e, id, trg)
    //   return false; //blocks the default behavior
    // };
    // gantt.config.calendar_property = "name";
    // gantt.config.start_date = new Date(2020, 10, 5);
    // gantt.config.end_date = new Date(2021, 10, 20);
    // config left width
    // var myCalendar = new dhtmlXDoubleCalendar('i1')
    // myCalendar.setDateFormat('%Y-%m-%d')
    // myCalendar.setDates("2012-08-07","2012-08-23")
    // myCalendar.show() // 上面三行代码执行完，并没有绘制到页面上，所以要show一下
    gantt.config.autofit = true;
    gantt.config.grid_width = 800;
    // gantt.config.autoscroll = true;
    const { tasks } = this.props;
    gantt.clearAll();
    gantt.init(this.ganttContainer);
    this.initGanttDataProcessor();
    gantt.parse(tasks);
  }

  componentWillUnmount() {
    console.log('componentWillUnmount========');
    this.clearTooltip();
    // if (this.dataProcessor) {
    //   this.dataProcessor.destructor();
    //   this.dataProcessor = null;
    // }
  }

  render() {
    const { zoom } = this.props;
    this.setZoom(zoom);
    return (
      <div
        ref={(input) => {
          // console.log(input, 'input');
          this.ganttContainer = input;
        }}
        style={{ width: '100%', height: '100%' }}
      ></div>
    );
  }
}

export default withRouter(Gantt);
