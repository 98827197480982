import React from 'react';
import moment from 'moment';
import http from '../../../http';
import filterItem from '../../../utils/filterItem';
import { Table, message, Button, Modal, Form, Input, Tree } from 'antd';
import BreadNav from '../../../components/breadNav';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import menus from '../../../components/layout/menus';
import { getAuthMenus } from '../../../api/user';
import { getDomHeight } from '../../../utils/common';
const { confirm } = Modal;
const { Search } = Input;
export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      content: { title: '' },
      type: '',
      staticData: [],
      data: [],
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      password1: '',
      password2: '',
      whichItem: '',
      status: '',
      // 密码修改弹窗
      isPasswordModalVisible: false,
      // 菜单权限
      // 已选中菜单
      checkedKeys: [],
    };
    this.formRef = React.createRef();
    this.passwordFormRef = React.createRef();

    //表头
    this.columns = [
      {
        title: '编号',
        dataIndex: 'id',
        sorter: (a, b) => String(a.id).length - String(b.id).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '用户名',
        dataIndex: 'username',
        sorter: (a, b) => String(a.username).length - String(b.username).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '邮箱',
        dataIndex: 'email',
        sorter: (a, b) => String(a.email).length - String(b.email).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '加入时间',
        dataIndex: 'date_joined',
        sorter: (a, b) => moment(a.date_joined) - moment(b.date_joined),
        render: (text) => text.split('.')[0],
      },
      {
        title: '上次登录时间',
        dataIndex: 'last_login',
        sorter: (a, b) => moment(a.last_login) - moment(b.last_login),
        render: (text) => (text == null ? '' : text.split('.')[0]),
      },
      // {
      //   title: '是否超级用户',
      //   dataIndex: 'is_superuser',
      // },
      {
        title: '操作',
        dataIndex: '',
        render: (text, record) => {
          let { status } = this.state;
          const userName = localStorage.getItem('userName') || '我的';
          return (
            <div>
              <Button
                type="link"
                text={JSON.stringify(text)}
                onClick={(e) => {
                  if (!status) return;
                  let { id, username, first_name, last_name, email } = record;
                  // 获取用户菜单
                  this.getCurrentUserMenus(username);
                  this.setState(
                    {
                      username,
                      first_name,
                      last_name,
                      email,
                      content: { title: '编辑用户信息' },
                      type: 'edit',
                      isModalVisible: true,
                      status: 'edit',
                      whichItem: id,
                      password1: '',
                      password2: '',
                    },
                    () => {
                      if (this.formRef.current && this.formRef.current.setFieldsValue) {
                        this.formRef.current.setFieldsValue({
                          username,
                          first_name,
                          last_name,
                          email,
                        });
                      }
                    }
                  );
                }}
              >
                编辑
              </Button>
              &nbsp;&nbsp;
              <Button
                type="link"
                text={JSON.stringify(text)}
                onClick={(e) => {
                  if (!status) return;
                  let { id } = record;
                  this.setState(
                    {
                      content: { title: '修改密码' },
                      isPasswordModalVisible: true,
                      whichItem: id,
                    },
                    () => {
                      // this.passwordForm.resetFields();
                      if (
                        this.passwordFormRef.current &&
                        this.passwordFormRef.current.setFieldsValue
                      ) {
                        this.passwordFormRef.current.setFieldsValue({
                          password1: '',
                          password2: '',
                        });
                      }
                    }
                  );
                }}
              >
                修改密码
              </Button>
              &nbsp;&nbsp;
              {userName === 'admin' && (
                <Button
                  type="text"
                  danger
                  onClick={(e) => {
                    if (!status) return;
                    this.setState(
                      {
                        status: 'delete',
                        type: 'delete',
                        whichItem: text.id,
                      },
                      this.showDeleteConfirm
                    );
                  }}
                >
                  删除
                </Button>
              )}
            </div>
          );
        },
      },
    ];
  }

  //对话框
  showDeleteConfirm = () => {
    let { type } = this.state;
    let _this = this;
    confirm({
      title: '您确定要删除该项数据吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          _this.deleteItem();
        }
      },
      onCancel() {},
    });
  };
  //列表
  itemList = () => {
    http.get(
      'data/common/user/',
      {
        format: 'json',
        _search: false,
        rows: 10000,
        page: 1,
        sidx: 'id',
        sord: 'asc',
      },
      (response) => {
        if (response.data && response.data.rows) {
          const userName = localStorage.getItem('userName') || 'admin';

          let data = this.filterAccount(response.data.rows, userName);
          // let data = response.data.rows.filter(item=>item);
          let owners = filterItem(data, 'name');
          owners.push('');
          this.setState({
            staticData: data,
            data,
            owners,
            status: 'ready',
          });
        }
      }
    );
  };
  filterAccount = (arr, account) => {
    const res = arr.filter((item) => item.is_active === 'True');
    if (account === 'admin') {
      return res;
    } else {
      return res.filter((item) => {
        return item.username === account;
      });
    }
  };
  //新建
  addItem = async () => {
    await this.formRef.current.validateFields();
    // 保存用户菜单配置接口
    this.setAuthMenus();
    let { username, first_name, last_name, email, password1, password2 } = this.state;
    let _this = this;

    http.post(
      'data/common/user/add/',
      {
        _save: '保存',
        username,
        first_name,
        last_name,
        email,
        password1,
        password2,
        is_superuser: 'True',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
          _this.editItem(response.data.url);
        } else {
          message.destroy();
          message.error('输入错误或用户名重复');
        }
      }
    );
  };
  //编辑
  editItem = (url) => {
    let { username, first_name, last_name, email, whichItem } = this.state;
    // 保存用户菜单配置接口
    this.setAuthMenus();
    url = url || `data/common/user/${whichItem}/change/`;
    let _this = this;
    http.post(
      url,
      {
        csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        _save: '保存',
        username,
        first_name,
        last_name,
        email,
        is_superuser: 'True',
        is_active: 'True',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  editPassword = async (values) => {
    try {
      const values = await this.passwordFormRef.current.validateFields();
      const { whichItem, password1, password2 } = this.state;

      const url = `data/common/user/${whichItem}/password/`;
      http.post(
        url,
        {
          csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
          password1,
          password2,
        },
        (response) => {
          if (response.data.status === 'success') {
            message.success('修改成功！');
            this.setState(
              {
                isPasswordModalVisible: false,
              },
              () => {
                this.itemList();
              }
            );
          } else {
            message.error(
              '修改失败（你的密码不能与你的其他个人信息太相似。你的密码必须包含至少 8 个字符。你的密码不能是一个常见密码。你的密码不能全都是数字。）'
            );
          }
        }
      );
    } catch (errorInfo) {
      message.warn('提交校验失败');
    }
  };
  //删除
  deleteItem = () => {
    let { whichItem } = this.state;
    let _this = this;
    http.post(`data/common/user/`, [{ id: whichItem, is_active: 'False' }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        _this.itemList();
      } else {
        message.error('操作失败！');
      }
    });
  };
  //表单提交
  handleOK = () => {
    let { type } = this.state;
    if (type === 'add') {
      this.addItem();
    } else if (type === 'edit') {
      this.editItem();
    }
  };
  setAuthMenus = () => {
    if (this.state.whichItem == 1) {
      return false;
    }
    const url = `menus`;
    const { username, checkedKeys } = this.state;
    const params = {
      id: username,
      menus: checkedKeys,
    };
    http.postNkJSON(url, params);
  };
  getCurrentUserMenus = async (id) => {
    try {
      const res = await getAuthMenus(id);
      this.setState({
        checkedKeys: res,
      });
    } catch (e) {}
    // console.log(this.state.checkedKeys, 'res');
  };
  //模糊查询
  handleSearch = (searched) => {
    let { staticData } = this.state;
    let arr = staticData.filter((item) => {
      let val = searched.replace(/(^\s*)|(\s*$)/g, '');
      return item.username.includes(val);
    });
    this.setState({
      data: arr,
    });
  };
  //密码规则
  pwdRule(rules, value, callback) {
    console.log('pwdRule');
    // 判断数字
    if (value.length < 8) {
      callback(new Error('密码长度应大于8位'));
    }
    // 判断数字
    else if (!/[0-9]/.test(value)) {
      callback(new Error('密码应包含数字'));
    }
    // 判断有没有字母
    else if (!/[a-zA-Z]/.test(value)) {
      callback(new Error('密码应包含字母'));
    }
    // 判断有没有特殊符号
    else if (!/[^0-9a-zA-A_]/.test(value)) {
      callback(new Error('密码应包含特殊字符'));
    } else {
      callback();
    }
  }

  //确认密码校验一致
  handleCfmPwd(rules, value, callback) {
    let loginpass = this.state.password1;
    if (loginpass && loginpass !== value) {
      callback(new Error('两次密码输入不一致'));
    } else {
      // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
      callback();
    }
  }
  onCheck(values) {
    console.log(values);
    this.setState({ checkedKeys: values });
  }

  componentDidMount() {
    this.itemList();
  }

  renderPasswordModal() {
    const { isPasswordModalVisible, content } = this.state;
    return (
      <Modal
        title={content.title}
        visible={isPasswordModalVisible}
        maskClosable={false}
        okText="提交"
        cancelText="取消"
        onOk={this.editPassword}
        onCancel={() => {
          this.setState({ isPasswordModalVisible: false });
        }}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          ref={this.passwordFormRef}
          // form={this.passwordForm}
          initialValues={{
            password1: '',
            password2: '',
          }}
          onValuesChange={(changedValues, allValues) => {
            console.log(changedValues, 'changedValues');
            this.setState({
              ...changedValues,
            });
          }}
        >
          <Form.Item
            label="密码"
            name="password1"
            rules={[
              { required: true, message: '请输入密码' },
              {
                validator: (rules, value, callback) => {
                  this.pwdRule(rules, value, callback);
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="确认密码"
            name="password2"
            rules={[
              { required: true, message: '请输入密码' },
              {
                validator: (rules, value, callback) => {
                  this.handleCfmPwd(rules, value, callback);
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
  renderMenus() {
    // 只有admin才有权限修改菜单
    const userName = localStorage.getItem('userName');
    if (userName === 'admin' && this.state.whichItem == 1 && this.state.type === 'add') {
      return (
        <Form.Item label="菜单权限" name="auth">
          <Tree
            checkable
            checkedKeys={this.state.checkedKeys}
            onCheck={this.onCheck.bind(this)}
            treeData={menus}
          />
        </Form.Item>
      );
    } else if (userName === 'admin' && this.state.whichItem != 1) {
      return (
        <Form.Item label="菜单权限" name="auth">
          <Tree
            checkable
            // defaultExpandedKeys={['0-0-0', '0-0-1']}
            // defaultSelectedKeys={['0-0-0', '0-0-1']}
            // defaultCheckedKeys={this.state.checkedKeys}
            checkedKeys={this.state.checkedKeys}
            // onSelect={onSelect}
            onCheck={this.onCheck.bind(this)}
            treeData={menus}
          />
        </Form.Item>
      );
    } else {
      return null;
    }
  }

  render() {
    let { isModalVisible, content, data } = this.state;
    const userName = localStorage.getItem('userName') || '我的';
    return (
      <div className="modal-list">
        {this.renderPasswordModal()}
        <Modal
          title={content.title}
          visible={isModalVisible}
          maskClosable={false}
          okText="提交"
          cancelText="取消"
          onOk={this.handleOK}
          onCancel={() => {
            this.setState({ isModalVisible: false });
          }}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            ref={this.formRef}
            // initialValues={{
            //   username,
            //   first_name,
            //   last_name,
            //   email,
            // }}
            onValuesChange={(changedValues, allValues) => {
              this.setState({
                ...changedValues,
              });
            }}
          >
            <Form.Item
              label="用户名"
              name="username"
              rules={[{ required: true, message: '请输入用户名' }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
              label="姓"
              name="last_name"
              rules={[{ required: true, message: '请输入姓' }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
              label="名"
              name="first_name"
              rules={[{ required: true, message: '请输入名' }]}
            >
              <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
              label="邮箱"
              name="email"
              rules={[
                {
                  required: true,
                  message: '请输入邮箱',
                },
                {
                  type: 'email',
                  message: '邮箱格式不正确!',
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
            {this.state.type == 'add' && (
              <div>
                <Form.Item
                  label="密码"
                  name="password1"
                  rules={[
                    { required: true, message: '请输入密码' },
                    {
                      validator: (rules, value, callback) => {
                        this.pwdRule(rules, value, callback);
                      },
                    },
                  ]}
                >
                  <Input.Password autocomplete="new-password" />
                </Form.Item>
                <Form.Item
                  label="确认密码"
                  name="password2"
                  rules={[
                    { required: true, message: '请输入确认密码' },
                    {
                      validator: (rules, value, callback) => {
                        this.handleCfmPwd(rules, value, callback);
                      },
                    },
                  ]}
                >
                  <Input.Password autocomplete="new-password" />
                </Form.Item>
              </div>
            )}
            {this.renderMenus()}
          </Form>
        </Modal>
        <div className="layout-content">
          <div className="content-header">
            <BreadNav />
            <div className="search-container">
              用户名&nbsp;:&nbsp;&nbsp;
              <Search
                className="search"
                enterButton="查询"
                size="middle"
                allowClear
                onSearch={(value) => {
                  this.handleSearch(value);
                }}
              />
            </div>
            <br />
            {userName == 'admin' && (
              <Button
                type="primary"
                shape="round"
                size="middle"
                onClick={() => {
                  console.log('add');
                  this.setState({
                    type: 'add',
                    isModalVisible: true,
                    content: { title: '新建用户信息' },
                  });
                  // 清空表单数据
                  this.setState({
                    checkedKeys: [],
                  });
                  if (this.formRef.current && this.formRef.current.setFieldsValue) {
                    this.formRef.current.resetFields();
                  }
                }}
              >
                +&nbsp;新建
              </Button>
            )}
            <br />
            <br />
          </div>
          <div className="content-body">
            <Table
              size="small"
              columns={this.columns}
              dataSource={data}
              pagination={{
                pageSize: 15,
              }}
              style={{
                height: getDomHeight(),
              }}
              scroll={{
                y: getDomHeight(),
                // x: '100vw',
              }}
              rowKey={(record) => record.id}
              bordered
            />
          </div>
        </div>
      </div>
    );
  }
}
