import React, { useState, useEffect } from 'react';
import http from '../../../http';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import BreadNav from 'components/breadNav';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  TimePicker,
  DatePicker,
  ConfigProvider,
  message,
  Select,
  Breadcrumb,
  Checkbox,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import SearchGroup from '../../../components/SearchGroup';
import { searchEngine, getDomHeight } from '../../../utils/common';

const { confirm } = Modal;
const format = 'HH:mm';
const dateFormat = 'YYYY-MM-DD HH:mm';

export default function Workshift() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [id, setId] = useState('');
  const [calendar, setCalendar] = useState('');
  const [value, setValue] = useState('');
  const [priority, setPriority] = useState('');
  const [monday, setMonday] = useState('');
  const [tuesday, setTuesday] = useState('');
  const [wednesday, setWednesday] = useState('');
  const [thursday, setThursday] = useState('');
  const [friday, setFriday] = useState('');
  const [saturday, setSaturday] = useState('');
  const [sunday, setSunday] = useState('');
  const [starttime, setStarttime] = useState('');
  const [endtime, setEndtime] = useState('');
  const [startdate, setStartdate] = useState('');
  const [enddate, setEnddate] = useState('');
  const [whichItem, setWhichItem] = useState('');
  const [calendarsList, setCalendarsList] = useState([]);
  //表头
  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
      width: 60,
    },
    {
      title: '日历',
      dataIndex: 'calendar',
      width: 160,
    },
    {
      title: '开始日期',
      dataIndex: 'startdate',
      width: 200,
    },
    {
      title: '结束日期',
      dataIndex: 'enddate',
      width: 200,
    },
    {
      title: '优先级',
      dataIndex: 'priority',
      width: 80,
    },
    {
      title: '星期一',
      dataIndex: 'monday',
      width: 80,
      render: (value) => {
        if (value === 'True') return <span>是</span>;
        else return <span>否</span>;
      },
    },
    {
      title: '星期二',
      dataIndex: 'tuesday',
      width: 80,
      render: (value) => {
        if (value === 'True') return <span>是</span>;
        else return <span>否</span>;
      },
    },
    {
      title: '星期三',
      dataIndex: 'wednesday',
      width: 80,
      render: (value) => {
        if (value === 'True') return <span>是</span>;
        else return <span>否</span>;
      },
    },
    {
      title: '星期四',
      dataIndex: 'thursday',
      width: 80,
      render: (value) => {
        if (value === 'True') return <span>是</span>;
        else return <span>否</span>;
      },
    },
    {
      title: '星期五',
      dataIndex: 'friday',
      width: 80,
      render: (value) => {
        if (value === 'True') return <span>是</span>;
        else return <span>否</span>;
      },
    },
    {
      title: '星期六',
      dataIndex: 'saturday',
      width: 80,
      render: (value) => {
        if (value === 'True') return <span>是</span>;
        else return <span>否</span>;
      },
    },
    {
      title: '星期日',
      dataIndex: 'sunday',
      width: 80,
      render: (value) => {
        if (value === 'True') return <span>是</span>;
        else return <span>否</span>;
      },
    },
    {
      title: '开始时间',
      dataIndex: 'starttime',
      width: 100,
    },
    {
      title: '结束时间',
      dataIndex: 'endtime',
      width: 100,
    },
    {
      title: '更新时间',
      dataIndex: 'lastmodified',
      width: 200,
      sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
      render: (text) => text.split('.')[0],
    },
    {
      title: '操作',
      dataIndex: '',
      width: 140,
      render: (text) => (
        <div>
          <Button
            type="link"
            text={JSON.stringify(text)}
            onClick={(e) => {
              setEditValue(text, 'edit');
              setIsModalVisible(true);
            }}
          >
            编辑
          </Button>
          <Button
            type="text"
            danger
            name={text.name}
            onClick={() => {
              setOptType('delete');
              showDeleteConfirm('delete', text.id);
            }}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];

  const setEditValue = (item, type) => {
    console.log(item, type, '========');
    setContent({ title: type === 'edit' ? '编辑信息' : '新增信息' });
    setOptType(type);

    setWhichItem(item.id || '');
    setCalendar(item.calendar || '');
    // setValue(item.value || '');
    setPriority(item.priority || '');
    setMonday(item.monday || '');
    setTuesday(item.tuesday || '');
    setWednesday(item.wednesday || '');
    setThursday(item.thursday || '');
    setFriday(item.friday || '');
    setSaturday(item.saturday || '');
    setSunday(item.sunday || '');

    setStartdate(item.startdate ? moment(item.startdate, dateFormat) : '');
    setEnddate(item.enddate ? moment(item.enddate, dateFormat) : '');
    setStarttime(item.starttime ? moment(item.starttime, format) : '');
    setEndtime(item.endtime ? moment(item.endtime, format) : '');
  };

  const onSearch = (keys) => {
    if (keys.every((key) => !key.value)) {
      pageList();
    } else {
      const arr = searchEngine(dataCopy, keys);
      setData(arr);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //删除提示
  const showDeleteConfirm = (optType, name) => {
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (optType === 'delete') {
          deleteItem(name);
        } else if (optType === 'deleteBatch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const handleDateParams = () => {
    const _dateFormat = 'YYYY-MM-DD';
    const _format = 'HH:mm';
    return {
      startdate_0: moment(startdate).format(_dateFormat),
      startdate_1: moment(startdate).format(_format),
      enddate_0: moment(enddate).format(_dateFormat),
      enddate_1: moment(enddate).format(_format),
      starttime: moment(starttime).format(_format),
      endtime: moment(endtime).format(_format),
    };
  };
  //编辑
  const editItem = () => {
    if (!calendar) {
      return message.error('日历不能为空！');
    }
    if (!startdate) {
      return message.error('有效开始不能为空！');
    }
    if (!enddate) {
      return message.error('有效结束不能为空！');
    }
    if (!priority) {
      return message.error('优先级不能为空！');
    }
    if (!starttime) {
      return message.error('开始时间不能为空！');
    }
    if (!endtime) {
      return message.error('结束时间不能为空！');
    }
    http.post(
      `detail/input/calendarbucket/${whichItem}/`,
      {
        _save: '保存',
        calendar,
        priority,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        ...handleDateParams(),
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //删除
  const deleteItem = (name) => {
    http.post(
      `data/input/calendarbucket/${name}/delete/`,
      {
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    console.log(selectedRowKeys);
    http.postJSON(`data/input/calendarbucket/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        pageList();
      } else {
        message.error('操作失败！');
      }
    });
  };
  //表单提交
  const handleOK = () => {
    if (optType === 'add') {
      addWorkshift();
    } else if (optType === 'edit') {
      editItem();
    }
  };

  const addWorkshift = () => {
    if (!calendar) {
      return message.error('日历不能为空！');
    }
    if (!startdate) {
      return message.error('有效开始不能为空！');
    }
    if (!enddate) {
      return message.error('有效结束不能为空！');
    }
    if (!priority) {
      return message.error('优先级不能为空！');
    }
    if (!starttime) {
      return message.error('开始时间不能为空！');
    }
    if (!endtime) {
      return message.error('结束时间不能为空！');
    }
    http.post(
      'data/input/calendarbucket/add/',
      {
        _save: '保存',
        id,
        calendar,
        priority,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        ...handleDateParams(),
        // starttime,
        // endtime,
        // startdate_0,
        // startdate_1,
        // enddate_0,
        // enddate_1,
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('添加成功');
          pageList();
        } else {
          alert(response.data.status);
        }
      }
    );
  };

  const pageList = () => {
    http.get(
      'data/input/calendarbucket/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'id',
        sord: 'asc',
      },
      function (response) {
        setData(response.data.rows);
        setDataCopy(response.data.rows);
      }
    );
  };
  // 工厂列表
  const getCalendarsList = () => {
    http.get(
      'data/input/calendar/',
      {
        format: 'json',
        rows: 10000,
        page: 1,
      },
      function (response) {
        setCalendarsList(response.data.rows);
      }
    );
  };
  useEffect(() => {
    pageList();
    getCalendarsList();
  }, []);

  return (
    <div className="workshift layout-content">
      <Modal
        title={content.title}
        visible={isModalVisible}
        maskClosable={false}
        okText="提交"
        cancelText="取消"
        onOk={handleOK}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal">
          <Form.Item label="日历">
            <Select
              onSelect={(value) => {
                setCalendar(value);
              }}
              value={calendar}
            >
              {calendarsList &&
                calendarsList.map((item) => (
                  <Select.Option value={item.name} key={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="有效开始">
            <ConfigProvider locale={locale}>
              <DatePicker
                format={dateFormat}
                value={startdate}
                showTime={{ defaultValue: moment('00:00', format) }}
                onChange={(date, dateStr) => {
                  console.log(
                    moment(dateStr, dateFormat).format(dateFormat),
                    'moment(dateStr,dateFormat)'
                  );
                  setStartdate(date);
                }}
              />
              {/* <DatePicker
                value={startdate_0}
                format={dateFormat}
                onChange={(date, dateStr) => {
                  setStartdate_0(moment(dateStr,dateFormat));
                }}
              />
              <TimePicker
                value={startdate_1}
                format={format}
                onChange={(date, dateStr) => {
                  setStartdate_1(moment(dateStr,format));
                }}
              /> */}
            </ConfigProvider>
          </Form.Item>
          <Form.Item label="有效结束">
            <ConfigProvider locale={locale}>
              <DatePicker
                format={dateFormat}
                value={enddate}
                showTime={{ defaultValue: moment('00:00', format) }}
                onChange={(date, dateStr) => {
                  setEnddate(date);
                }}
              />
              {/* <TimePicker
                format={format}
                defaultValue={moment('00:00', format)}
                value={moment(enddate_1, format)}
                onChange={(date, dateStr) => {
                  setEnddate_1(dateStr);
                }}
              /> */}
            </ConfigProvider>
          </Form.Item>
          <Form.Item label="优先级">
            <InputNumber
              step="1"
              max="20"
              value={priority}
              onChange={(value) => {
                setPriority(value);
              }}
            />
          </Form.Item>
          <Form.Item label="开始时间">
            <ConfigProvider locale={locale}>
              <TimePicker
                // defaultValue={moment('00:00', format)}
                // value={moment(starttime, format)}
                value={starttime}
                format={format}
                onChange={(date, dateStr) => {
                  setStarttime(date);
                }}
              />
            </ConfigProvider>
          </Form.Item>
          <Form.Item label="结束时间">
            <ConfigProvider locale={locale}>
              <TimePicker
                // defaultValue={moment('23:59', format)}
                value={endtime}
                format={format}
                onChange={(date, dateStr) => {
                  setEndtime(date);
                }}
              />
            </ConfigProvider>
          </Form.Item>
          <Form.Item label="星期一">
            <Checkbox
              defaultChecked
              checked={monday === 'True' ? true : false}
              onChange={(e) => {
                if (e.target.checked) setMonday('True');
                else setMonday('False');
              }}
            />
          </Form.Item>
          <Form.Item label="星期二">
            <Checkbox
              defaultChecked
              checked={tuesday === 'True' ? true : false}
              onChange={(e) => {
                if (e.target.checked) setTuesday('True');
                else setTuesday('False');
              }}
            />
          </Form.Item>
          <Form.Item label="星期三">
            <Checkbox
              defaultChecked
              checked={wednesday === 'True' ? true : false}
              onChange={(e) => {
                if (e.target.checked) setWednesday('True');
                else setWednesday('False');
              }}
            />
          </Form.Item>
          <Form.Item label="星期四">
            <Checkbox
              defaultChecked
              checked={thursday === 'True' ? true : false}
              onChange={(e) => {
                if (e.target.checked) setThursday('True');
                else setThursday('False');
              }}
            />
          </Form.Item>
          <Form.Item label="星期五">
            <Checkbox
              defaultChecked
              checked={friday === 'True' ? true : false}
              onChange={(e) => {
                if (e.target.checked) setFriday('True');
                else setFriday('False');
              }}
            />
          </Form.Item>
          <Form.Item label="星期六">
            <Checkbox
              defaultChecked
              checked={saturday === 'True' ? true : false}
              onChange={(e) => {
                if (e.target.checked) setSaturday('True');
                else setSaturday('False');
              }}
            />
          </Form.Item>
          <Form.Item label="星期日">
            <Checkbox
              defaultChecked
              checked={sunday === 'True' ? true : false}
              onChange={(e) => {
                if (e.target.checked) setSunday('True');
                else setSunday('False');
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <div className="content-header">
        <BreadNav />
        <div>
          <SearchGroup
            items={[
              {
                label: '名称',
                name: 'calendar',
              },
            ]}
            onSearch={onSearch}
          ></SearchGroup>
        </div>
        <br />
        <Button
          type="primary"
          shape="round"
          size="middle"
          onClick={() => {
            setEditValue({}, 'add');
            setIsModalVisible(true);
          }}
        >
          新建
        </Button>
        &nbsp;&nbsp;
        <Button
          type="primary"
          shape="round"
          size="middle"
          danger
          onClick={() => {
            setOptType('deleteBatch');
            showDeleteConfirm('deleteBatch');
          }}
        >
          删除
        </Button>
        <br />
        <br />
      </div>
      <div className="content-body">
        <Table
          size="small"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.id}
          bordered
          pagination={{
            pageSize: 15,
          }}
          style={{
            height: getDomHeight(),
          }}
          scroll={{
            y: getDomHeight(),
            // x: '100vw',
          }}
        />
      </div>
    </div>
  );
}
