import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import http from '../../http/index';
import { Form, Input, Button, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import loginBg from '../../assets/imgs/login_bg.jpg';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthMenus } from '../../api/user';
import menus from '../../components/layout/menus';
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function LogIn(props) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const num = useSelector((state) => {
    return state.logged;
  });

  // 登录成功之后处理
  const afterLogin = (values) => {
    localStorage.setItem('userName', values.username);
    menusFilter();
    history.push('/dashboard');
  };
  // 组装菜单
  const menusFilter = async () => {
    try {
      const authMenus = await getAuthMenus(localStorage.getItem('userName'));
      if (authMenus) {
        const currentMenus = menus.filter((item) => {
          if (item.children) {
            item.subMenus = item.children.filter((child) => authMenus.includes(child.key));
          }
          return authMenus.includes(item.key);
        });
        dispatch({ type: 'SET_CURRENT_MENUS', data: currentMenus });
      }
    } catch (e) {
      console.log(e);
      // menus.forEach((item) => {
      //   item.subMenus = item.children;
      // });
      // dispatch({ type: 'SET_CURRENT_MENUS', data: menus });
    }
  };

  let onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // 登录
  let login = async (values) => {
    let params = {
      username: values.username,
      password: values.password,
    };
    try {
      const res = await http.customPost('/api/data/login/?next=/', params);
      if (res.data.status === 'success') {
        afterLogin(values);
      } else if (res.data.status === 'fail') {
        message.error('账号和密码错误');
      }
    } catch (e) {
      message.error('服务异常');
    }
  };

  return (
    <div className="login">
      <h2 className="title">欢迎登录中国家居产业智联网</h2>
      <div className="login-container">
        <div className="login-left">
          <img src={loginBg} alt="login_bg" />
        </div>
        <div className="login-right">
          <h5 className="login-tab">账号登录</h5>
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={(values) => {
              login(values);
            }}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: '请输入用户名!',
                },
              ]}
            >
              <Input
                className="username"
                placeholder="用户名"
                onChange={(event) => {
                  setUsername('username', event.target.value);
                }}
                value={username}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: '请输入密码!',
                },
              ]}
              onSubmit={login}
            >
              <Input.Password
                className="password"
                placeholder="密码"
                onChange={(event) => {
                  setPassword('password', event.target.value);
                }}
                value={password}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" className="submit-btn">
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
