import moment from 'moment';
import { message } from 'antd';

export const requiredLabel = (value) => {
  return (
    <div>
      <span
        style={{
          fontSize: '14px',
          color: '#ff4d4f',
          marginRight: '4px',
          lineHeight: 1,
          fontFamily: 'SimSun, sans-serif',
        }}
      >
        *
      </span>
      <span>{value}</span>
    </div>
  );
};
export const getFilters = (keys) => {
  if (!keys) {
    return false;
  }
  const rules = keys.map((key) => {
    return {
      field: key.field,
      op: key.op || 'cn',
      data: key.data || '',
      filtercount: null,
    };
  });
  return { groupOp: 'AND', rules };
};
export const treeToArr = (tree, key) => {
  let arr = [];
  const parseData = (treeData, deep, pid) => {
    deep += 1;
    if (treeData && treeData.length > 0) {
      treeData.forEach((item) => {
        const id = (Math.random() * 10).toString();
        item.id = id;
        item.parent = pid;
        item.deep = deep;
        const itemCopy = JSON.parse(JSON.stringify(item));
        // 删除children
        delete itemCopy[key];
        arr.push(itemCopy);
        parseData(item[key], deep, id);
      });
    }
  };
  parseData(tree, 0);
  return arr;
};
export const timeToDuration = (value) => {
  let currentValue = value || 0;
  let currentYears = moment.duration(currentValue, 'seconds').years();
  let currentMonth = moment.duration(currentValue, 'seconds').months();
  let currentDay = moment.duration(currentValue, 'seconds').days();
  let currentHour = moment.duration(currentValue, 'seconds').hours();
  let currentM = moment.duration(currentValue, 'seconds').minutes();
  let currentS = moment.duration(currentValue, 'seconds').seconds();
  return (
    <span>
      {(currentYears ? currentYears + '年' : '') +
        (currentMonth ? currentMonth + '月' : '') +
        currentDay +
        '天' +
        currentHour +
        '时' +
        currentM +
        '分' +
        currentS +
        '秒'}
    </span>
  );
};

export const searchEngine = (arr, keys) => {
  // console.log('searchEngine');
  return arr.filter((item) => {
    const keysCopy = keys.filter((key) => {
      // console.log('key.value', key.value);
      if (key.value) {
        let val = key.value.replace(/(^\s*)|(\s*$)/g, '');
        return !!val;
      }
    });
    const flag = keysCopy.every((key) => {
      if (key.value) {
        let val = key.value.replace(/(^\s*)|(\s*$)/g, '');
        return item[key.key] && item[key.key].includes(val);
      } else {
        return item[key.key] && item[key.key].includes('');
      }
    });
    return flag;
  });
};

export const splitDate = (date, key) => {
  const tempTime = moment.duration(date);
  const d = tempTime.days();
  const h = tempTime.hours();
  const m = tempTime.minutes();
  const s = tempTime.seconds();
  return [key];
};

export const dateFix = (date, timeFormat) => {
  if (!date) return '';

  timeFormat = timeFormat || 'YYYY-MM-DD HH:mm:ss';
  return (
    moment(date, timeFormat)
      // .subtract(new Date().getTimezoneOffset(), 'minute')
      .format(timeFormat)
  );
};

export const launchFullscreen = (element) => {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullScreen();
  }
};

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};

export const getSelectOptions = (arr, key) => {
  const res = [];
  arr.forEach((item) => {
    if (res.indexOf(item[key]) < 0 && item[key]) {
      res.push({
        label: item.value ? item.value : item[key],
        value: item[key],
      });
    }
  });
  //数组对象去重
  var result = [];
  var obj = {};
  for (var i = 0; i < res.length; i++) {
    if (!obj[res[i].label]) {
      result.push(res[i]);
      obj[res[i].label] = true;
    }
  }
  return result.map((item) => {
    return {
      label: item.label,
      value: item.value,
    };
  });
};

export const demandsDataAdapter = (data, key) => {
  return data.map((item) => {
    return {
      ...item,
      require: key ? item[key] : item.demands && item.demands[0][1],
    };
  });
};

export const deepClone = (obj) => {
  let newObj = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = obj && typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key];
      }
    }
  }
  return newObj;
};

export const isCanDelete = (source, arr, key) => {
  const flag = source
    .filter((item) => {
      return arr.some((target) => target === item[key]);
    })
    .some((item) => item.status === 'completed');
  if (flag) {
    message.error('已采用的数据不可删除');
  }
  return flag;
};
export const getDomHeight = (className, offset) => {
  className = className || 'content-body';
  offset = offset || 50;
  return (
    document.getElementsByClassName(className)[0] &&
    document.getElementsByClassName(className)[0].offsetHeight - offset
  );
};
