import React, { useState, useEffect } from 'react';
import http from '../../../http';
import { Table, Button, Modal, Form, Input, InputNumber, message, Breadcrumb } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import { requiredLabel, getFilters, searchEngine, getDomHeight } from '../../../utils/common';
import SearchGroup from '../../../components/SearchGroup';
import BreadNav from 'components/breadNav';
const { confirm } = Modal;

export default function Calendars() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [defaultvalue, setDefaultvalue] = useState('1.00');
  const [whichItem, setWhichItem] = useState('');

  //表头
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      width: 300,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '描述',
      dataIndex: 'description',
      width: 300,
    },
    // {
    //   title: '默认值',
    //   dataIndex: 'defaultvalue',
    //   width: '10%',
    //   render: (value) => {
    //     return <span>{value && parseFloat(value)}</span>;
    //   },
    // },
    {
      title: '操作',
      dataIndex: '',
      width: 200,
      render: (text) => {
        return (
          <div>
            <Button
              type="link"
              text={JSON.stringify(text)}
              onClick={(e) => {
                setEditValue(text, 'edit');
                setIsModalVisible(true);
              }}
            >
              编辑
            </Button>
            &nbsp;&nbsp;
            <Button
              type="text"
              danger
              name={text.name}
              onClick={(e) => {
                let name = e.target.parentNode.name;
                setOptType('delete');
                showDeleteConfirm('delete', name);
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const setEditValue = (item, type) => {
    console.log(item, type, '========');
    setContent({ title: type === 'edit' ? '编辑日历信息' : '新增日历信息' });
    setOptType(type);
    setName(item.name || '');
    setWhichItem(item.name || '');
    setDescription(item.description || '');
    setDefaultvalue(parseFloat(item.defaultvalue));
  };

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showDeleteConfirm = (optType, name) => {
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (optType === 'delete') {
          deleteItem(name);
        } else if (optType === 'deleteBatch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const addCalendars = () => {
    if (!name) {
      return message.error('名称不能为空！');
    }
    http.post(
      'data/input/calendar/add/',
      {
        _save: '保存',
        name,
        description,
        defaultvalue,
        'buckets-TOTAL_FORMS': 0,
        'buckets-INITIAL_FORMS': 0,
        'buckets-MIN_NUM_FORMS': 0,
        'buckets-MAX_NUM_FORMS': 1000,
        'buckets-__prefix__-enddate_0': '2030/12/31',
        'buckets-__prefix__-enddate_1': '00:00',
        'buckets-__prefix__-value': '0.00',
        'buckets-__prefix__-priority': '0',
        'buckets-__prefix__-monday': 'on',
        'buckets-__prefix__-tuesday': 'on',
        'buckets-__prefix__-wednesday': 'on',
        'buckets-__prefix__-thursday': 'on',
        'buckets-__prefix__-friday': 'on',
        'buckets-__prefix__-saturday': 'on',
        'buckets-__prefix__-sunday': 'on',
        'buckets-__prefix__-starttime': '00:00',
        'buckets-__prefix__-endtime': '23:59',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('添加成功');
          setIsModalVisible(false);
          pageList();
        } else {
          message.error(response.data.status);
        }
      }
    );
  };

  const pageList = (keywords) => {
    const data = keywords || {};
    const params = {
      format: 'json',
      _search: data._search || false,
      filters: getFilters(data.rules),
      rows: 10,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
    };
    http.get('data/input/calendar/', params, function (response) {
      setData(response.data.rows);
      setDataCopy(response.data.rows);
    });
  };
  //编辑
  const editItem = () => {
    if (!name) {
      return message.error('名称不能为空！');
    }
    http.post(
      `detail/input/calendar/${whichItem}/`,
      {
        _save: '保存',
        name,
        defaultvalue,
        description,
        'buckets-TOTAL_FORMS': 0,
        'buckets-INITIAL_FORMS': 0,
        'buckets-MIN_NUM_FORMS': 0,
        'buckets-MAX_NUM_FORMS': 1000,
        'buckets-__prefix__-enddate_0': '2030/12/31',
        'buckets-__prefix__-enddate_1': '00:00',
        'buckets-__prefix__-value': '0.00',
        'buckets-__prefix__-priority': '0',
        'buckets-__prefix__-monday': 'on',
        'buckets-__prefix__-tuesday': 'on',
        'buckets-__prefix__-wednesday': 'on',
        'buckets-__prefix__-thursday': 'on',
        'buckets-__prefix__-friday': 'on',
        'buckets-__prefix__-saturday': 'on',
        'buckets-__prefix__-sunday': 'on',
        'buckets-__prefix__-starttime': '00:00',
        'buckets-__prefix__-endtime': '23:59',
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //删除
  const deleteItem = (name) => {
    http.post(
      `data/input/calendar/${name}/delete/`,
      {
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    console.log(selectedRowKeys);
    http.postJSON(`data/input/calendar/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        pageList();
      } else {
        message.error('操作失败！');
      }
    });
  };
  //表单提交
  const handleOK = () => {
    if (optType === 'add') {
      addCalendars();
    } else if (optType === 'edit') {
      editItem();
    }
  };
  const onSearch = (keys) => {
    if (keys.every((key) => !key.value)) {
      pageList();
    } else {
      const arr = searchEngine(dataCopy, keys);
      setData(arr);
    }
  };
  useEffect(() => {
    pageList();
  }, []);

  return (
    <div className="calendars layout-content">
      <Modal
        title={content.title}
        visible={isModalVisible}
        maskClosable={false}
        okText="提交"
        cancelText="取消"
        onOk={handleOK}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{
            priority: 10,
            status: 'open',
          }}
        >
          <Form.Item
            label={requiredLabel('名称')}
            rules={[{ required: true, message: '请填写名称' }]}
          >
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="描述">
            <Input.TextArea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>
          {/* <Form.Item label="默认值">
            <InputNumber
              step="0.01"
              value={defaultvalue}
              onChange={(value) => {
                setDefaultvalue(value);
              }}
            />
          </Form.Item> */}
        </Form>
      </Modal>
      <div className="content-header">
        <BreadNav />
        <SearchGroup
          items={[
            {
              label: '名称',
              name: 'name',
            },
          ]}
          onSearch={onSearch}
        ></SearchGroup>
        <br />
        <Button
          type="primary"
          shape="round"
          size="middle"
          onClick={() => {
            setEditValue({ defaultvalue: '0' }, 'add');
            setIsModalVisible(true);
          }}
        >
          新建
        </Button>
        &nbsp;&nbsp;
        <Button
          type="primary"
          shape="round"
          size="middle"
          danger
          onClick={() => {
            setOptType('deleteBatch');
            showDeleteConfirm('deleteBatch');
          }}
        >
          删除
        </Button>
        <br />
        <br />
      </div>
      <div className="content-body">
        <Table
          size="small"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.name}
          bordered
          pagination={{
            pageSize: 15,
          }}
          style={{
            height: getDomHeight(),
          }}
          scroll={{
            y: getDomHeight(),
            // x: '100vw',
          }}
        />
      </div>
    </div>
  );
}
