import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import http from '../../../http';
import filterItem from '../../../utils/filterItem';
import formatTime from '../../../utils/formatTime';
import { Table, message, Button, Modal, Form, Input, Select, InputNumber, DatePicker } from 'antd';
import BreadNav from '../../../components/breadNav';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
const axios = require('axios');
import { getDomHeight, timeToDuration } from '../../../utils/common';

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
/**状态下拉框数据 */
const statusArr = [
  { cn: '开放', en: 'open' },
  { cn: '已结束', en: 'closed' },
  { cn: '已取消', en: 'canceled' },
];

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /**
       * 操作相关
       */
      type: '',
      pageStatus: '',
      isModalVisible: false,
      content: { title: '' },
      selectedRowKeys: [],
      data: [],
      staticData: [],
      itemStatus: [
        { key: 'open', value: '改变状态为开放' },
        { key: 'closed', value: '改变状态为已结束' },
        { key: 'canceled', value: '改变状态为已取消' },
      ],
      locations: [],
      customers: [],
      items: [],
      itemStatusValue: '',
      /**
       * 接口参数
       */
      name: '',
      item: '',
      location: '',
      customer: '',
      due: undefined,
      quantity: 1,
      priority: 10,
      status: 'open',
      deleteName: '',
      whichItem: '',
    };
    this.formRef = React.createRef();

    //表头
    this.columns = [
      {
        title: '名称',
        dataIndex: 'name',
        fixed: 'left',
        width: 300,
        sorter: (a, b) => String(a.name).length - String(b.name).length,
        sortDirections: ['descend', 'ascend'],
        render: (value, record) => {
          /**
           * 参数传递
           */
          return (
            <a
              onClick={() => {
                this.props.history.push({
                  pathname: '/sales/supplyPath',
                  params: record,
                });
              }}
            >
              {value}
            </a>
          );
        },
      },
      {
        title: '产品',
        dataIndex: 'item',
        width: 300,
        sorter: (a, b) => String(a.item).length - String(b.item).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '工厂',
        dataIndex: 'location',
        width: 200,
        sorter: (a, b) => String(a.location).length - String(b.location).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '客户',
        dataIndex: 'customer',
        width: 200,
        sorter: (a, b) => String(a.customer).length - String(b.customer).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '状态',
        width: 100,
        dataIndex: 'status',
        sorter: (a, b) => a.status.length - b.status.length,
        sortDirections: ['descend', 'ascend'],
        render: (text) => {
          switch (text) {
            case 'inquiry':
              return '查询';
            case 'quote':
              return '报价';
            case 'open':
              return '开放';
            case 'closed':
              return '已结束';
            case 'canceled':
              return '已取消';
            default:
              return '';
          }
        },
      },
      {
        title: '数量',
        width: 100,
        dataIndex: 'quantity',
        sorter: (a, b) => a.quantity - b.quantity,
        render: (text) => text.split('.')[0],
      },
      {
        title: '交期',
        dataIndex: 'due',
        width: 150,
        sorter: (a, b) => moment(a.due) - moment(b.due),
        render: (text) => text.split('.')[0],
      },
      {
        title: '延期',
        dataIndex: 'delay',
        width: 150,
        className: 'dealy',
        render: (text) =>
          typeof text === 'number' ? (
            <div
              style={{
                background: text > 0 ? 'red' : 'green',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              }}
            >
              <span>{timeToDuration(text)}</span>
            </div>
          ) : (
            <div>{text}</div>
          ),
      },
      {
        title: '已计划量',
        width: 100,
        dataIndex: 'plannedquantity',
        sorter: (a, b) => a.plannedquantity - b.plannedquantity,
        render: (text) => (text === '0E-8' ? 0 : text && text.split('.')[0]),
      },
      {
        title: '未计划量',
        width: 100,
        dataIndex: 'plannedshort',
        render: () => '',
      },
      {
        title: '交货日期',
        dataIndex: 'deliverydate',
        width: 150,
        sorter: (a, b) => moment(b.deliverydate) - moment(a.deliverydate),
      },
      {
        title: '优先级',
        width: 100,
        dataIndex: 'priority',
        sorter: (a, b) => b.priority - a.priority,
      },
      {
        title: '更新日期',
        dataIndex: 'lastmodified',
        width: 150,
        sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
        render: (text) => text.split('.')[0],
      },
      // {
      //   title: '操作',
      //   dataIndex: '',
      //   width: 200,
      //   fixed: 'right',
      //   render: (text) => {
      //     let { pageStatus, status } = this.state;
      //     return (
      //       <div>
      //         <Button
      //           type="link"
      //           text={JSON.stringify(text)}
      //           onClick={(e) => {
      //             if (!pageStatus) return;
      //             let {
      //               name,
      //               item,
      //               location,
      //               customer,
      //               due,
      //               quantity,
      //               priority,
      //               status,
      //             } = JSON.parse(e.currentTarget.getAttribute('text'));
      //             this.setState(
      //               {
      //                 name,
      //                 whichItem: name,
      //                 item,
      //                 location,
      //                 customer,
      //                 due,
      //                 quantity,
      //                 priority,
      //                 status,

      //                 pageStatus: 'edit',
      //                 type: 'edit',
      //                 content: { title: '编辑销售订单信息' },
      //                 whichItem: name,
      //                 isModalVisible: true,
      //               },
      //               () => {
      //                 if (this.formRef.current && this.formRef.current.setFieldsValue) {
      //                   this.formRef.current.setFieldsValue({
      //                     name,
      //                     whichItem: name,
      //                     item,
      //                     location,
      //                     customer,
      //                     due: moment(due || undefined, 'YYYY/MM/DD HH:mm:ss'),
      //                     quantity,
      //                     priority,
      //                     status,
      //                   });
      //                 }
      //               }
      //             );
      //           }}
      //         >
      //           编辑
      //         </Button>
      //         &nbsp;&nbsp;
      //         <Button
      //           type="text"
      //           danger
      //           name={text.name}
      //           onClick={(e) => {
      //             if (!pageStatus) return;
      //             let name = e.target.parentNode.name;
      //             this.setState(
      //               {
      //                 pageStatus: 'delete',
      //                 type: 'delete',
      //                 whichItem: name,
      //               },
      //               this.showDeleteConfirm
      //             );
      //           }}
      //         >
      //           删除
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ];
  }

  //表格选中行
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  //对话框
  showDeleteConfirm = () => {
    let { type } = this.state;
    let _this = this;
    confirm({
      title: '您确定要删除该项数据吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          _this.deleteItem();
        } else if (type === 'deleteBatch') {
          _this.deleteBatch();
        }
      },
      onCancel() {},
    });
  };
  //列表
  itemList = () => {
    let _this = this;

    http.get(
      'data/input/demand/',
      {
        format: 'json',
        _search: false,
        rows: 100,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        if (response.data && response.data.rows) {
          let data = response.data.rows;
          _this.setState({
            staticData: data,
            data,
            pageStatus: 'ready',
          });
        }
      }
    );
  };
  //订单列表
  orderList = () => {
    return http.customGet('/api/data/input/demand/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
    });
  };
  //产品列表
  productionList = () => {
    return http.customGet('/api/data/input/item/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
      filters: {
        groupOp: 'AND',
        rules: [{ field: 'name', op: 'ne', data: 'All items', filtercount: 0 }],
      },
    });
  };
  //客户列表
  customerList = () => {
    return http.customGet('/api/data/input/customer/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
    });
  };
  //工厂列表
  locationList = () => {
    return http.customGet('/api/data/input/location/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
    });
  };

  //请求所有数据
  allList = () => {
    let _this = this;
    axios
      .all([this.productionList(), this.customerList(), this.locationList(), this.orderList()])
      .then((results) => {
        let items = filterItem(results[0].data.rows, 'name');
        let customers = filterItem(results[1].data.rows, 'name');
        let locations = filterItem(results[2].data.rows, 'name');
        let orders = results[3].data.rows.filter((item) => typeof item.delay === 'number');

        _this.setState({
          staticData: orders,
          data: orders,
          locations,
          customers,
          items,
          pageStatus: 'ready',
        });
      });
  };
  //新建
  addItem = () => {
    let { name, item, location, customer, due, quantity, priority, status } = this.state;
    let _this = this;
    due =
      (Object.prototype.toString.call(due) === '[object Object]' &&
        due.format('YYYY/MM/DD HH:mm:ss')) ||
      due;
    http.post(
      'data/input/demand/add/',
      {
        csrfmiddlewaretoken: 'mV3EB7B9SJLO7LZTHNU1fcakr9z75W1npDcy8YjuzB6waYxxBihC76xbLqdOcIGg',
        _save: '保存',
        name,
        item,
        location,
        customer,
        due_0: due.split(' ')[0],
        due_1: due.split(' ')[1],
        quantity,
        priority,
        status,
        category: '',
        subcategory: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //编辑
  editItem = () => {
    let { whichItem, name, item, location, customer, quantity, priority, status, due } = this.state;
    let _this = this;
    due =
      (Object.prototype.toString.call(due) === '[object Object]' &&
        due.format('YYYY/MM/DD HH:mm:ss')) ||
      due;

    http.post(
      `detail/input/demand/${whichItem}/`,
      {
        csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        _save: '保存',
        name,
        item,
        location,
        customer,
        due_0: due.split(' ')[0],
        due_1: due.split(' ')[1],
        quantity,
        priority,
        status,
        description: '',
        owner: 'All customers',
        category: '',
        subcategory: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //删除
  deleteItem = () => {
    let { whichItem } = this.state;
    let _this = this;
    http.post(
      `data/input/demand/${whichItem}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.itemList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  deleteBatch = () => {
    let { type, selectedRowKeys } = this.state;
    let _this = this;
    if (type !== 'deleteBatch') return;
    http.postJSON(`data/input/demand/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        _this.itemList();
      } else {
        message.error('操作失败！');
      }
    });
  };
  //表单提交
  handleSubmit = () => {
    let { type } = this.state;
    if (type === 'add') {
      this.addItem();
    } else if (type === 'edit') {
      this.editItem();
    }
  };

  saveOrders = async (items, status) => {
    const params = items.map((item) => {
      return {
        id: item,
        status,
      };
    });
    const url = `data/input/demand/`;
    return http.post(url, params);
  };

  //模糊查询
  handleSearch = (searched) => {
    let { staticData } = this.state;
    let arr = staticData.filter((item) => {
      let val = searched.replace(/(^\s*)|(\s*$)/g, '');
      return item.name.includes(val);
    });
    this.setState({
      data: arr,
    });
  };

  componentDidMount() {
    this.allList();
  }

  render() {
    let {
      /**其他 */
      isModalVisible,
      content,
      data,
      selectedRowKeys,
      locations,
      customers,
      items,
      /** 表单相关*/
      name,
      item,
      location,
      customer,
      quantity,
      priority,
      status,
      due,
      itemStatus,
      itemStatusValue,
    } = this.state;
    return (
      <div className="modal-list sales-order">
        <Modal
          className="sales-order-modal"
          title={content.title}
          visible={isModalVisible}
          maskClosable={false}
          okText="提交"
          cancelText="取消"
          onCancel={() => {
            this.setState({ isModalVisible: false });
          }}
        >
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizental"
            ref={this.formRef}
            initialValues={{
              name,
              item,
              location,
              customer,
              due:
                due === undefined || due === null ? undefined : moment(due, 'YYYY/MM/DD HH:mm:ss'),
              quantity,
              priority,
              status,
            }}
            onValuesChange={(changedValues, allValues) => {
              this.setState({
                ...allValues,
              });
            }}
            onFinish={this.handleSubmit}
          >
            <Form.Item label="名称" name="name" rules={[{ required: true, message: '请输入名称' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="产品"
              name="item"
              rules={[{ required: true, message: '请选择产品名' }]}
            >
              <Select placeholder="请选择产品名" showSearch allowClear>
                {items.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="工厂"
              name="location"
              rules={[{ required: true, message: '请选择工厂' }]}
            >
              <Select placeholder="请选择工厂" showSearch allowClear>
                {locations.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="客户"
              name="customer"
              rules={[{ required: true, message: '请选择客户' }]}
            >
              <Select placeholder="请选择客户" showSearch allowClear>
                {customers.map((item) => (
                  <Option value={item} key={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="交期" name="due" rules={[{ required: true, message: '请选择日期' }]}>
              <DatePicker
                locale={locale}
                format="YYYY/MM/DD HH:mm:ss"
                showTime={{ format: 'HH:mm:ss' }}
              />
            </Form.Item>
            <Form.Item
              label="数量"
              name="quantity"
              rules={[{ required: true, message: '请输入数量' }]}
            >
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label="优先级"
              name="priority"
              rules={[{ required: true, message: '请输入优先级' }]}
            >
              <InputNumber min={0} max={10} />
            </Form.Item>
            <Form.Item
              label="状态"
              name="status"
              rules={[{ required: true, message: '请选择状态' }]}
            >
              <Select showSearch allowClear>
                {statusArr.map((item) => (
                  <Option value={item.en} key={item.en}>
                    {item.cn}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  position: 'absolute',
                  left: '416px',
                  top: '91px',
                }}
              >
                提交
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <div className="layout-content">
          <div className="content-header">
            <BreadNav />
            <div className="search-container">
              名称：
              <Search
                className="search"
                enterButton="查询"
                size="middle"
                allowClear
                onSearch={(value) => {
                  this.handleSearch(value);
                }}
              />
            </div>
            <br />
          </div>
          <div className="content-body">
            <Table
              className="custom-table"
              style={{
                height: getDomHeight(),
              }}
              size="small"
              scroll={{ x: '100vw' }}
              // rowSelection={{
              //   selectedRowKeys,
              //   onChange: this.onSelectChange,
              // }}
              columns={this.columns}
              dataSource={data}
              rowKey={(record) => record.name}
              scroll={{
                y: getDomHeight(),
                x: '100vw',
              }}
              bordered
              pagination={{ pageSize: 15 }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Orders);
