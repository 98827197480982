import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import http from '../../../http';
import { timeToDuration, getDomHeight, searchEngine } from '../../../utils/common';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Breadcrumb,
  message,
  DatePicker,
  ConfigProvider,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import BreadNav from '../../../components/breadNav';
import SearchGroup from '../../../components/SearchGroup';
const { confirm } = Modal;

export default function Productionapplier() {
  const [productOptions, setProductOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [factoryOptions, setFactoryOptions] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [id, setId] = useState('');
  const [item, setItem] = useState('');
  // const [location, setLocation] = useState('');
  const [supplier, setSupplier] = useState('');
  const [leadtime, setLeadtime] = useState('');
  const [sizeminimum, setSizeminimum] = useState('');
  const [cost, setCost] = useState('');
  const [priority, setPriority] = useState('');
  const [resource, setResource] = useState('');
  const [resource_qty, setResource_qty] = useState('');
  const [sizemaximum, setSizemaximum] = useState('');
  const [sizemultiple, setSizemultiple] = useState('');
  const [effective_start_0, setEffective_start_0] = useState('');
  const [effective_start_1, setEffective_start_1] = useState('');
  const [effective_end_0, setEffective_end_0] = useState('');
  const [effective_end_1, setEffective_end_1] = useState('');
  const [resourcedata, setResourcedata] = useState([]);
  const [locationdata, setLocationdata] = useState([]);
  const [searchProduct, setsearchProduct] = useState('');
  const [staticData, setStaticData] = useState([]);
  const [searchStatus, setSearchStatus] = useState('');
  const [whichItem, setWhichItem] = useState('');

  const [day, setDay] = useState('0');
  const [hour, setHour] = useState('0');
  const [minute, setMinute] = useState('0');
  const [second, setSecond] = useState('0');

  const formRef = useRef(null);

  //表头
  const columns = [
    // {
    //   title: '名称',
    //   dataIndex: 'id',
    // },
    {
      title: '产品',
      dataIndex: 'item',
      width: 200,
      render: (value) => {
        return <span>{value}</span>;
      },
      sorter: (a, b) => String(a.item).length - String(b.item).length,
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: '工厂',
    //   width: 200,
    //   dataIndex: 'location',
    //   render: (value) => {
    //     return <span>{value}</span>;
    //   },
    //   sorter: (a, b) => String(a.location).length - String(b.location).length,
    //   sortDirections: ['descend', 'ascend'],
    // },
    {
      title: '供应商',
      dataIndex: 'supplier',
      width: 200,
      render: (value) => {
        return <span>{value}</span>;
      },
      sorter: (a, b) => String(a.supplier).length - String(b.supplier).length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '交货提前期',
      width: 200,
      dataIndex: 'leadtime',
      render: (value) => {
        return <span>{value ? timeToDuration(value) : ''}</span>;
      },
    },
    {
      title: '最小量',
      width: 100,
      dataIndex: 'sizeminimum',
      render: (value) => {
        return <span>{value ? parseInt(value) : ''}</span>;
      },
    },

    {
      title: '优先级',
      width: 100,
      dataIndex: 'priority',
      render: (value) => {
        return <span>{value ? parseInt(value) : ''}</span>;
      },
    },

    {
      title: '大小的倍数',
      width: 100,
      dataIndex: 'sizemultiple',
      render: (value) => {
        return <span>{value ? parseInt(value) : ''}</span>;
      },
    },

    {
      title: '更新时间',
      width: 200,
      dataIndex: 'lastmodified',
      sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
      render: (text) => text.split('.')[0],
    },
    // {
    //   title: '操作',
    //   width: 200,
    //   dataIndex: '',
    //   render: (text) => (
    //     <div>
    //       <Button
    //         type="link"
    //         name={text.id}
    //         text={JSON.stringify(text)}
    //         onClick={(e) => {
    //           let text = e.currentTarget.getAttribute('text');
    //           text = JSON.parse(text);
    //           setTimeout(() => {
    //             formRef.current.setFieldsValue({
    //               item: text.item,
    //               // location: text.location,
    //               supplier: text.supplier,
    //               leadtime: text.leadtime,
    //               sizeminimum: text.sizeminimum,
    //               priority: text.priority,
    //               sizemultiple: text.sizemultiple,
    //             });
    //           });
    //           setOptType('edit');
    //           setWhichItem(text.id);
    //           setItem(text.item);
    //           // setLocation(text.location);
    //           setSupplier(text.supplier);
    //           setLeadtime(text.leadtime);

    //           setDay(parseInt(text.leadtime / 86400) || '');
    //           setHour(parseInt((text.leadtime / 3600) % 24) || '');
    //           setMinute(parseInt((text.leadtime / 60) % 60) || '');
    //           setSecond(parseInt(text.leadtime % 60) || '');
    //           text.sizeminimum = text.sizeminimum ? parseInt(text.sizeminimum) : '';
    //           setSizeminimum(text.sizeminimum);
    //           text.priority = text.priority ? parseInt(text.priority) : '';
    //           setPriority(text.priority);
    //           text.sizemultiple = text.sizemultiple ? parseInt(text.sizemultiple) : '';
    //           setSizemultiple(text.sizemultiple);
    //           setIsModalVisible(true);
    //           setContent({ title: '编辑供应商信息' });
    //         }}
    //       >
    //         编辑
    //       </Button>
    //       <Button
    //         type="text"
    //         danger
    //         name={text.id}
    //         onClick={(e) => {
    //           let name = e.target.parentNode.name;
    //           setOptType('delete');
    //           showDeleteConfirm('delete', name);
    //         }}
    //       >
    //         删除
    //       </Button>
    //     </div>
    //   ),
    // },
  ];

  const onSelectChange = (selectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showDeleteConfirm = (type, name) => {
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          deleteItem(name);
        } else if (type === 'deleteBatch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  //删除
  const deleteItem = (name) => {
    //console.log('deleteName: ', deleteName);
    http.post(
      `data/input/itemsupplier/${name}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
          alert(response.data.status);
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    http.postJSON(`data/input/itemsupplier/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        pageList();
      } else {
        message.error('操作失败！');
      }
    });
  };

  const addProductionapplier = () => {
    if (!item) {
      return message.error('产品不能为空！');
    }
    if (!supplier) {
      return message.error('供应商不能为空！');
    }
    http.post(
      'data/input/itemsupplier/add/',
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        _save: '保存',
        id,
        item,
        // location,
        supplier,
        leadtime: (day * 86400 + hour * 3600 + minute * 60 + second * 1).toString(),
        sizeminimum,
        cost,
        priority,
        resource,
        resource_qty,
        sizemaximum,
        sizemultiple,
        effective_start_0,
        effective_start_1,
        effective_end_0,
        effective_end_1,
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          setIsModalVisible(false);
          pageList();
        } else {
          alert(response.data.status);
        }
      }
    );
  };

  const editProductionapplier = () => {
    if (!item) {
      return message.error('产品不能为空！');
    }
    if (!supplier) {
      return message.error('供应商不能为空！');
    }
    http.post(
      `detail/input/itemsupplier/${whichItem}/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        _save: '保存',
        id,
        item,
        // location,
        supplier,
        leadtime: (day * 86400 + hour * 3600 + minute * 60 + second * 1).toString(),
        sizeminimum,
        cost,
        priority,
        resource,
        resource_qty,
        sizemaximum,
        sizemultiple,
        effective_start_0,
        effective_start_1,
        effective_end_0,
        effective_end_1,
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };

  const pageList = () => {
    http.get(
      'data/input/itemsupplier/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        setData(response.data.rows);
        setStaticData(response.data.rows);
        setSearchStatus('ready');
      }
    );
  };

  const getSupplier = () => {
    console.log('getSupplier');
    const res = [];
    staticData.forEach((item) => {
      if (!res.includes(item.supplier)) {
        res.push(item.supplier);
      }
    });
    return res;
  };
  const getProductOptions = () => {
    http.get(
      'data/input/item/',
      {
        format: 'json',
        _search: false,
        rows: 100,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
        filters: {
          groupOp: 'AND',
          rules: [
            { field: 'owner', op: 'ne', data: '全部' },
            { field: 'name', op: 'ne', data: '全部' },
            { field: 'name', op: 'ne', data: 'All items', filtercount: 0 },
          ],
        },
      },
      (response) => {
        if (response.data && response.data.rows) {
          let data = response.data.rows;
          const res = [];
          data.forEach((obj) => {
            if (!res.includes(obj.name)) {
              res.push(obj.name);
            }
          });
          setProductOptions(res);
          // console.log(res, 'products');
        }
      }
    );
  };
  const getSupplierOptions = () => {
    http.get(
      'data/input/supplier/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        let data = response.data.rows;
        const res = [];
        data.forEach((item) => {
          if (!res.includes(item.name)) {
            res.push(item.name);
          }
        });
        setSupplierOptions(res);
      }
    );
  };
  const getFactoryOptions = () => {
    http.get(
      'data/input/location/',
      {
        format: 'json',
        _search: false,
        rows: 100,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        if (response.data && response.data.rows) {
          let data = response.data.rows;
          const res = [];
          data.forEach((item) => {
            if (!res.includes(item.name)) {
              res.push(item.name);
            }
          });
          setFactoryOptions(res);
        }
      }
    );
  };

  const handleOk = () => {
    if (optType === 'add') {
      addProductionapplier();
    } else if (optType === 'edit') {
      editProductionapplier();
    }
  };

  //模糊查询
  const onSearch = (keys) => {
    console.log('onSearch');
    if (keys.every((key) => !key.value)) {
      pageList();
    } else {
      const arr = searchEngine(staticData, keys);
      setData(arr);
      setStaticData(arr);
    }
  };

  useEffect(() => {
    pageList();
    resourceList();
    locationList();
  }, []);

  const resourceList = () => {
    http.get(
      'data/input/resource/',
      {
        format: 'json',
      },
      function (response) {
        setResourcedata(response.data.rows);
      }
    );
  };

  const locationList = () => {
    http.get(
      'data/input/location/',
      {
        format: 'json',
      },
      function (response) {
        setLocationdata(response.data.rows);
      }
    );
  };
  return (
    <div className="productionapplier modal-list">
      <Modal
        title={content.title}
        visible={isModalVisible}
        maskClosable={false}
        okText="提交"
        cancelText="取消"
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal" ref={formRef}>
          {/* <Form.Item label="名称">
            <Input
              value={id}
              onChange={(e) => {
                setId(e.target.value);
              }}
            />
          </Form.Item> */}
          <Form.Item label="产品">
            <Select
              showSearch
              onSelect={(value) => {
                setItem(value);
                formRef.current.setFieldsValue(value);
              }}
              onFocus={() => {
                getProductOptions();
              }}
              value={item}
            >
              {productOptions.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item label="工厂">
            <Select
              showSearch
              allowClear={true}
              onSelect={(value) => {
                setLocation(value);
                formRef.current.setFieldsValue(value)
              }}
              onFocus={() => {
                getFactoryOptions();
              }}
              value={location}
            >
              {factoryOptions.map((i) => (
                <Select.Option value={i} key={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item label="供应商">
            <Select
              showSearch
              onSelect={(value) => {
                setSupplier(value);
                formRef.current.setFieldsValue(value);
              }}
              onFocus={() => {
                getSupplierOptions();
              }}
              value={supplier}
            >
              {supplierOptions.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item label="交货提前期(秒)">
            <InputNumber
              value={leadtime}
              max="999999999999"
              onChange={(value) => {
                setLeadtime(value);
                formRef.current.setFieldsValue(value);
              }}
            />
          </Form.Item> */}
          <Form.Item label="交货提前期">
            <InputNumber
              value={day}
              max="10000"
              min="0"
              onChange={(value) => {
                setDay(value);
                formRef.current.setFieldsValue(value);
              }}
            />
            （天）
            <InputNumber
              value={hour}
              max="23"
              min="0"
              onChange={(value) => {
                setHour(value);
                formRef.current.setFieldsValue(value);
              }}
            />
            （时）
            <InputNumber
              value={minute}
              max="59"
              min="0"
              style={{ marginTop: '10px' }}
              onChange={(value) => {
                setMinute(value);
                formRef.current.setFieldsValue(value);
              }}
            />
            （分）
            <InputNumber
              value={second}
              max="59"
              min="0"
              onChange={(value) => {
                setSecond(value);
                formRef.current.setFieldsValue(value);
              }}
            />
            （秒）
          </Form.Item>
          <Form.Item label="最小量">
            <InputNumber
              value={sizeminimum}
              onChange={(value) => {
                setSizeminimum(value);
                formRef.current.setFieldsValue(value);
              }}
            />
          </Form.Item>
          {/* <Form.Item label="成本">
            <InputNumber
              value={cost}
              onChange={(value) => {
                setCost(value);
              }}
            />
          </Form.Item> */}
          <Form.Item label="优先级">
            <InputNumber
              value={priority}
              onChange={(value) => {
                setPriority(value);
                formRef.current.setFieldsValue(value);
              }}
            />
          </Form.Item>
          {/* <Form.Item label="资源">
            <Select
              allowClear={true}
              onSelect={(value) => {
                setResource(value);
              }}
              value={resource}
            >
              {resourcedata.map((item) => (
                <Select.Option value={item.name} key={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="资源数量">
            <InputNumber
              value={resource_qty}
              onChange={(value) => {
                setResource_qty(value);
              }}
            />
          </Form.Item>
          <Form.Item label="大小的最大量">
            <InputNumber
              value={sizemaximum}
              onChange={(value) => {
                setSizemaximum(value);
              }}
            />
          </Form.Item> */}
          <Form.Item label="大小的倍数">
            <InputNumber
              value={sizemultiple}
              onChange={(value) => {
                setSizemultiple(value);
                formRef.current.setFieldsValue(value);
              }}
            />
          </Form.Item>

          {/* <Form.Item
            label="交期"
            name="effective_start"
            rules={[{ required: true, message: '请选择日期' }]}
          >
            <ConfigProvider locale={locale}>
              <DatePicker
                format="YYYY/MM/DD HH:mm"
                showTime={{ defaultValue: moment('00/00/00', 'HH:mm') }}
                onChange={(date, str) => {
                  setEffective_start_0(str.split(' ')[0]);
                  setEffective_start_1(str.split(' ')[1]);
                }}
              />
            </ConfigProvider>
          </Form.Item>

          <Form.Item
            label="生效结束时间"
            name="effective_end"
            rules={[{ required: true, message: '请选择日期' }]}
          >
            <ConfigProvider locale={locale}>
              <DatePicker
                format="YYYY/MM/DD HH:mm"
                showTime={{ defaultValue: moment('00/00/00', 'HH:mm') }}
                onChange={(date, str) => {
                  setEffective_end_0(str.split(' ')[0]);
                  setEffective_end_1(str.split(' ')[1]);
                }}
              />
            </ConfigProvider>
          </Form.Item>*/}
        </Form>
      </Modal>
      <div className="layout-content">
        <div className="content-header">
          <BreadNav />
          <SearchGroup
            className="search-group"
            items={[
              {
                label: '产品',
                name: 'item',
              },
              {
                label: '供应商',
                name: 'supplier',
              },
            ]}
            onSearch={onSearch}
          ></SearchGroup>
          <br />
          {/* <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => {
              setId('');
              setItem('');
              // setLocation('');
              setSupplier('');
              setLeadtime('');
              setSizeminimum('');
              setCost('');
              setPriority('');
              setResource('');
              setResource_qty('');
              setSizemaximum('');
              setSizemultiple('');
              setDay('');
              setHour('');
              setMinute('');
              setSecond('');
              setOptType('add');
              setContent({ title: '新建产品供应商' });
              setIsModalVisible(true);
            }}
          >
            &nbsp;新建
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            type="primary"
            shape="round"
            size="middle"
            danger
            onClick={() => {
              setOptType('deleteBatch');
              showDeleteConfirm('deleteBatch');
            }}
          >
            删除
          </Button>
          <br />
          <br /> */}
        </div>
        <div className="content-body">
          <Table
            scroll={{ x: '100vw' }}
            size="small"
            // rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            bordered
            pagination={{ pageSize: 15 }}
            style={{
              height: getDomHeight(),
            }}
            scroll={{
              y: getDomHeight(),
              // x: '100vw',
            }}
          />
        </div>
      </div>
    </div>
  );
}
