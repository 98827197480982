import React from 'react';
import moment from 'moment';
import http from '../../../http';
import filterItem from '../../../utils/filterItem';
import { Table, message, Button, Modal, Form, Input, Select } from 'antd';
import BreadNav from '../../../components/breadNav';
import { getDomHeight } from '../../../utils/common';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';

const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
export default class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isModalVisible: false,
      locationVisible: false,
      content: { title: '' },
      type: '',
      staticData: [],
      data: [],
      name: '',
      description: '',
      owner: '',
      owners: [],
      names: [],
      whichItem: '',
      status: '',
    };
    this.formRef = React.createRef();

    //表头
    this.columns = [
      {
        title: '名称',
        dataIndex: 'name',
        width: 200,
        sorter: (a, b) => String(a.name).length - String(b.name).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '备注',
        dataIndex: 'description',
        width: 200,
        sorter: (a, b) => String(a.description).length - String(b.description).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '父名称',
        dataIndex: 'owner_id',
        width: 200,
        sorter: (a, b) => String(a.owner_id).length - String(b.owner_id).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '更新时间',
        dataIndex: 'lastmodified',
        width: 200,
        sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
        render: (text) => text.split('.')[0],
      },
      // {
      //   title: '操作',
      //   dataIndex: '',
      //   width:200,
      //   render: (text) => {
      //     let { status } = this.state;
      //     return (
      //       <div>
      //         <Button
      //           type="link"
      //           text={JSON.stringify(text)}
      //           onClick={(e) => {
      //             if (!status) return;
      //             let { name, description, owner } = JSON.parse(
      //               e.currentTarget.getAttribute('text')
      //             );
      //             this.setState(
      //               {
      //                 name,
      //                 description,
      //                 owner,
      //                 content: { title: '编辑客户信息' },
      //                 type: 'edit',
      //                 isModalVisible: true,
      //                 status: 'edit',
      //                 whichItem: name,
      //               },
      //               () => {
      //                 if (this.formRef.current && this.formRef.current.setFieldsValue) {
      //                   this.formRef.current.setFieldsValue({
      //                     name,
      //                     description,
      //                     owner,
      //                   });
      //                 }
      //               }
      //             );
      //           }}
      //         >
      //           编辑
      //         </Button>
      //         <Button
      //           type="text"
      //           danger
      //           name={text.name}
      //           onClick={(e) => {
      //             if (!status) return;
      //             let name = e.target.parentNode.name;
      //             this.setState(
      //               {
      //                 status: 'delete',
      //                 type: 'delete',
      //                 whichItem: name,
      //               },
      //               this.showDeleteConfirm
      //             );
      //           }}
      //         >
      //           删除
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ];
  }

  //表格选中行
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  //对话框
  showDeleteConfirm = () => {
    let { type } = this.state;
    let _this = this;
    confirm({
      title: '您确定要删除该项数据吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          _this.deleteItem();
        } else if (type === 'deleteBatch') {
          _this.deleteBatch();
        }
      },
      onCancel() {},
    });
  };
  //列表
  // itemList = () => {
  //   let _this = this;

  //   http.get(
  //     'data/input/customer/',
  //     {
  //       format: 'json',
  //       _search: false,
  //       rows: 100,
  //       page: 1,
  //       sidx: 'lastmodified',
  //       sord: 'desc',
  //     },
  //     function (response) {
  //       if (response.data && response.data.rows) {
  //         let data = response.data.rows;
  //         let owners = filterItem(data, 'name');
  //         owners.push('');
  //         _this.setState({
  //           staticData: data,
  //           data,
  //           owners,
  //           status: 'ready',
  //         });
  //       }
  //     }
  //   );
  // };
  itemList = () => {
    let _this = this;
    http.postNkJSON(
      'customer/getdatalist',
      {
        // PageIndex: 1,
        // PageRows: 100,
        SortField: 'name',
        SortType: '',
        // Search: {
        //   Condition: '',
        //   Keyword: '',
        // },
      },
      function (response) {
        if (response.data && response.data.Success) {
          let data = response.data.Data;
          let owners = filterItem(data, 'name');
          owners.push('');
          _this.setState({
            staticData: data,
            data,
            owners,
            status: 'ready',
          });
        }else{
         return
        }
      }
    );
  };
  //新建
  addItem = () => {
    let { name, description, owner } = this.state;
    let _this = this;

    http.post(
      'data/input/customer/add/',
      {
        csrfmiddlewaretoken: 'mV3EB7B9SJLO7LZTHNU1fcakr9z75W1npDcy8YjuzB6waYxxBihC76xbLqdOcIGg',
        _save: '保存',
        name,
        description,
        owner,
        category: '',
        subcategory: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.success('操作失败！');
        }
      }
    );
  };
  //编辑
  editItem = () => {
    let { name, description, owner, whichItem } = this.state;
    let _this = this;
    http.post(
      `detail/input/customer/${whichItem}/`,
      {
        csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        _save: '保存',
        name,
        description,
        owner,
        category: '',
        subcategory: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //删除
  deleteItem = () => {
    let { whichItem } = this.state;
    let _this = this;
    http.post(
      `data/input/customer/${whichItem}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.itemList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  deleteBatch = () => {
    let { type, selectedRowKeys } = this.state;
    let _this = this;
    if (type !== 'deleteBatch') return;
    http.postJSON(`data/input/customer/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        _this.itemList();
      } else {
        message.error('操作失败！');
      }
    });
  };
  //表单提交
  handleOK = () => {
    let { type } = this.state;
    if (type === 'add') {
      this.addItem();
    } else if (type === 'edit') {
      this.editItem();
    }
  };
  //模糊查询
  handleSearch = (searched) => {
    let { staticData } = this.state;
    let arr = staticData.filter((item) => {
      let val = searched.replace(/(^\s*)|(\s*$)/g, '');
      return item.name.includes(val);
    });
    this.setState({
      data: arr,
    });
  };
  //同步
  synchronization = () => {
    http.customPost('/nkapi/sync/customer').then((res) => {
      console.log('res', res);
      if (res.data.Success === true) {
        message.success('同步成功！');
        this.itemList();
      } else {
        message.error('同步失败！');
      }
    });
  };

  componentDidMount() {
    this.itemList();
    //地址栏后面添加?123 新建按钮才能显示出来
    var search = location.search;
    if (search === '?123') {
      this.setState({
        locationVisible: true,
      });
    }
  }

  render() {
    let { name, description, owner, owners, isModalVisible, content, data, selectedRowKeys } =
      this.state;
    return (
      <div className="modal-list">
        <Modal
          title={content.title}
          visible={isModalVisible}
          maskClosable={false}
          okText="提交"
          cancelText="取消"
          onOk={this.handleOK}
          onCancel={() => {
            this.setState({ isModalVisible: false });
          }}
        >
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            ref={this.formRef}
            initialValues={{
              name,
              description,
              owner,
            }}
            onValuesChange={(changedValues, allValues) => {
              this.setState({
                ...changedValues,
              });
            }}
          >
            <Form.Item label="名称" name="name" rules={[{ required: true, message: '请输入名称' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="描述" name="description">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="父级" name="owner">
              <Select showSearch allowClear>
                {owners.map(
                  (item) =>
                    item !== name && (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <div className="layout-content">
          <div className="content-header">
            <BreadNav />
            <div className="search-container">
              名称&nbsp;:&nbsp;&nbsp;
              <Search
                className="search"
                enterButton="查询"
                size="middle"
                allowClear
                onSearch={(value) => {
                  this.handleSearch(value);
                }}
              />
            </div>
            <br />
            {this.state.locationVisible ? (
              <span>
                <Button
                  type="primary"
                  shape="round"
                  size="middle"
                  onClick={() => {
                    if (this.formRef.current && this.formRef.current.setFieldsValue) {
                      this.formRef.current.setFieldsValue({
                        name: '',
                        description: '',
                        owner: '',
                      });
                    }
                    this.setState({
                      type: 'add',
                      isModalVisible: true,
                      content: { title: '新建客户信息' },
                    });
                  }}
                >
                  +&nbsp;新建
                </Button>
                &nbsp;&nbsp;
              </span>
            ) : (
              ''
            )}
            {/* <Button
          type="primary"
          shape="round"
          size="middle"
          danger
          onClick={() => {
            this.setState(
              {
                type: 'deleteBatch',
              },
              () => {
                this.showDeleteConfirm('deleteBatch');
              }
            );
          }}
        >
          删除
        </Button> */}
            <Button
              type="primary"
              shape="round"
              size="middle"
              onClick={() => {
                this.synchronization();
              }}
            >
              同步
            </Button>
            <br />
            <br />
          </div>
          <div className="content-body">
            <Table
              size="small"
              // rowSelection={{
              //   selectedRowKeys,
              //   onChange: this.onSelectChange,
              // }}
              pagination={{ pageSize: 15 }}
              columns={this.columns}
              dataSource={data}
              rowKey={(record) => record.name}
              bordered
              style={{
                height: getDomHeight(),
              }}
              scroll={{
                y: getDomHeight(),
                // x: '100vw',
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
