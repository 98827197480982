import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'antd/dist/antd.css';
import LogIn from './pages/login';
import UserLayOut from './components/layout/userLayout';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LogIn />
        </Route>
        <Route path="/">
          <UserLayOut />
        </Route>
      </Switch>
    </Router>
  );
}
