import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import http from '../../../http';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Breadcrumb,
  message,
  DatePicker,
  ConfigProvider,
  icons,
} from 'antd';
import { getDomHeight } from '../../../utils/common';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import BreadNav from '../../../components/breadNav';
const { confirm } = Modal;

export default function Resource() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [owner, setOwner] = useState('');
  const [type, setType] = useState('');
  const [constrained, setConstrained] = useState('');
  const [maximum, setMaximum] = useState('1');
  // const [maximum_calendar, setMaximum_calendar] = useState('');
  const [available, setAvailable] = useState('');
  const [cost, setCost] = useState('');
  const [efficiency, setEfficiency] = useState('');
  const [efficiency_calendar, setEfficiency_calendar] = useState('');
  const [lastmodified, setLastmodified] = useState('');
  const [calendardata, setCalendardata] = useState([]);
  const [whichItem, setWhichItem] = useState('');
  const [staticData, setStaticData] = useState([]);
  const [searchStatus, setSearchStatus] = useState('');
  const [locationList, setLocationList] = useState([]);

  //表头
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      width: 200,
      sorter: (a, b) => String(a.name).length - String(b.name).length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '描述',
      dataIndex: 'description',
      width: 200,
    },
    {
      title: '工厂',
      dataIndex: 'location',
      width: 200,
      render: (value) => {
        return <span>{value}</span>;
      },
    },
    {
      title: '父名称',
      dataIndex: 'owner',
      width: 200,
      render: (value) => {
        return <span>{value}</span>;
      },
    },
    {
      title: '类型',
      dataIndex: 'type',
      width: 120,
      render: (value) => {
        if (value === 'default') {
          return (value = '默认');
        } else if (value === 'buckets') {
          return (value = '日期类型');
        } else if (value === 'buckets_day') {
          return (value = '日期类型_日');
        } else if (value === 'buckets_week') {
          return (value = '日期类型_周');
        } else if (value === 'buckets_month') {
          return (value = '日期类型_月');
        } else if (value === 'infinite') {
          return (value = '无限期');
        }
      },
    },
    {
      title: '受约束的',
      dataIndex: 'constrained',
      width: 100,
      render: (value) => {
        if (value === 'True') {
          return (value = '是');
        } else if (value === 'False') {
          return (value = '否');
        } else {
          return (value = '');
        }
      },
    },
    {
      title: '最大值',
      dataIndex: 'maximum',
      width: 100,
    },
    // {
    //   title: '日历',
    //   dataIndex: 'maximum_calendar',
    //   render: (value) => {
    //     return <span>{value}</span>;
    //   },
    // },
    // {
    //   title: '可用',
    //   dataIndex: 'available',
    //   render: (value) => {
    //     return <span>{value}</span>;
    //   },
    // },
    // {
    //   title: '成本',
    //   dataIndex: 'cost',
    //   render: (value) => {
    //     return <span>{value}</span>;
    //   },
    // },
    // {
    //   title: '效率',
    //   dataIndex: 'efficiency',
    //   render: (value) => {
    //     return <span>{value}</span>;
    //   },
    // },
    // {
    //   title: '效率日历',
    //   dataIndex: 'efficiency_calendar',
    //   render: (value) => {
    //     return <span>{value}</span>;
    //   },
    // },
    {
      title: '更新时间',
      width: 200,
      dataIndex: 'lastmodified',
      sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
      render: (text) => text.split('.')[0],
    },
    {
      title: '操作',
      dataIndex: '',
      width: 200,
      render: (text) => (
        <div>
          <Button
            type="link"
            text={JSON.stringify(text)}
            onClick={(e) => {
              let text = e.currentTarget.getAttribute('text');
              text = JSON.parse(text);
              setOptType('edit');
              setWhichItem(text.name);
              setName(text.name);
              setDescription(text.description);
              setLocation(text.location);
              setOwner(text.owner);
              setType(text.type);
              setConstrained(text.constrained);
              setMaximum(text.maximum);
              // setMaximum_calendar(text.maximum_calendar);
              setAvailable(text.available);
              setCost(text.cost);
              setEfficiency(text.efficiency);
              setEfficiency_calendar(text.efficiency_calendar);
              setLastmodified(text.lastmodified);
              //calendarList();
              //setOptType('add');
              setIsModalVisible(true);
              setContent({ title: '编辑资源信息' });
            }}
          >
            编辑
          </Button>
          <Button
            type="text"
            danger
            name={text.name}
            onClick={(e) => {
              let name = e.target.parentNode.name;
              setOptType('delete');
              showDeleteConfirm('delete', name);
            }}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showDeleteConfirm = (type, name) => {
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          deleteItem(name);
        } else if (type === 'deleteBatch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  //删除
  const deleteItem = (name) => {
    //console.log('deleteName: ', deleteName);
    http.post(
      `data/input/resource/${name}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
          alert(response.data.status);
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    http.postJSON(`data/input/resource/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        pageList();
      } else {
        message.error('操作失败！');
      }
    });
  };

  const addResource = () => {
    http.post(
      'data/input/resource/add/',
      {
        _save: '保存',
        name,
        description,
        location,
        owner,
        type,
        constrained,
        maximum,
        // maximum_calendar,
        available,
        cost,
        efficiency,
        efficiency_calendar,
        lastmodified,
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          setIsModalVisible(false);
          pageList();
        } else {
          message.error('资源信息名称已存在');
        }
      }
    );
  };

  const editResource = () => {
    http.post(
      `detail/input/resource/${whichItem}/`,
      {
        csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        _save: '保存',
        name,
        description,
        location,
        owner,
        type,
        constrained,
        maximum,
        // maximum_calendar,
        available,
        cost,
        efficiency,
        efficiency_calendar,
        lastmodified,
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };

  const pageList = () => {
    http.get(
      'data/input/resource/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        let dataNew = response.data.rows;
        dataNew.map((item) => {
          item.maximum = parseInt(item.maximum);
        });
        setData(dataNew);
        setStaticData(dataNew);
        setSearchStatus('ready');
      }
    );
  };

  // 工厂列表
  const getLocationList = () => {
    http.get(
      'data/input/location/',
      {
        format: 'json',
        rows: 10000,
        page: 1,
      },
      function (response) {
        setLocationList(response.data.rows);
      }
    );
  };

  const handleOk = () => {
    if (optType === 'add') {
      if (name) {
        addResource();
      } else {
        message.error('名称不能为空');
      }
    } else if (optType === 'edit') {
      editResource();
    }
  };

  const onSearch = (value) => {
    if (searchStatus !== '') {
      const arr = [];
      let val = value.replace(/(^\s*)|(\s*$)/g, '');
      if (value !== '') {
        staticData.forEach((item) => {
          if (item.name.match(val) != null) {
            arr.push(item);
          }
        });
        setData(arr);
      } else {
        setData(staticData);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    pageList();
    getLocationList();
    calendarList();
  }, []);

  const calendarList = () => {
    http.get(
      'data/input/calendar/',
      {
        format: 'json',
      },
      function (response) {
        response.data.rows.map((item) => {});
        setCalendardata(response.data.rows);
      }
    );
  };
  return (
    <div className="resource modal-list layout-content">
      <Modal
        title={content.title}
        visible={isModalVisible}
        maskClosable={false}
        okText="提交"
        cancelText="取消"
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal">
          <Form.Item label="名称">
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="描述">
            <Input
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="工厂">
            <Select
              showSearch
              allowClear={true}
              placeholder="请选择工厂"
              onSelect={(value) => {
                setLocation(value);
              }}
              value={location}
              onChange={() => {
                setLocation('');
              }}
            >
              {locationList.map((item) => (
                <Select.Option value={item.name} key={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="父名称">
            <Select
              showSearch
              allowClear={true}
              onSelect={(value) => {
                setOwner(value);
              }}
              onClear={() => {
                setOwner('');
              }}
              value={owner}
            >
              {data.map((item) => (
                <Select.Option value={item.name} key={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="类型">
            <Select
              allowClear={true}
              onSelect={(value) => {
                setType(value);
              }}
              value={type}
              onClear={() => {
                setType('');
              }}
            >
              <Select.Option value="default" selected="">
                默认
              </Select.Option>
              <Select.Option value="buckets">日期类型</Select.Option>
              <Select.Option value="buckets_day">日期类型_日</Select.Option>
              <Select.Option value="buckets_week">日期类型_周</Select.Option>
              <Select.Option value="buckets_month">日期类型_月</Select.Option>
              <Select.Option value="infinite">无限期</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="受约束的">
            <Select
              allowClear={true}
              onSelect={(value) => {
                setConstrained(value);
              }}
              value={constrained}
              onClear={() => {
                setConstrained('');
              }}
            >
              <Select.Option value="True">是</Select.Option>
              <Select.Option value="False">否</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="最大值">
            <InputNumber
              step="1"
              value={maximum}
              max="1000000"
              onChange={(value) => {
                setMaximum(value);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <div className="content-header">
        <BreadNav />
        <div style={{ lineHeight: '32px' }}>
          名称&nbsp;:&nbsp;&nbsp;
          <Input.Search
            className="search"
            placeholder="名称"
            enterButton="查询"
            allowClear={true}
            size="middle"
            onSearch={onSearch}
          />
        </div>
        <br />
        <Button
          type="primary"
          shape="round"
          size="middle"
          onClick={() => {
            setName('');
            setDescription('');
            setLocation('');
            setOwner('');
            setType('default');
            setConstrained('True');
            setMaximum('1');
            // setMaximum_calendar('');
            setAvailable('');
            setCost('');
            setEfficiency('');
            setEfficiency_calendar('');
            setLastmodified('');

            setOptType('add');
            setContent({ title: '新建资源信息' });
            setIsModalVisible(true);
          }}
        >
          &nbsp;新建
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button
          type="primary"
          shape="round"
          size="middle"
          danger
          onClick={() => {
            setOptType('deleteBatch');
            showDeleteConfirm('deleteBatch');
          }}
        >
          删除
        </Button>
        <br />
        <br />
      </div>
      <div className="content-body">
        <Table
          size="small"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.name}
          bordered
          pagination={{
            pageSize: 15,
          }}
          style={{
            height: getDomHeight(),
          }}
          scroll={{
            y: getDomHeight(),
            // x: '100vw',
          }}
        />
      </div>
    </div>
  );
}
