import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';
//import locale from 'antd/es/date-picker/locale/zh_CN';
import locale from 'antd/lib/locale/zh_CN';
import http from '../../../http';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Breadcrumb,
  message,
  DatePicker,
  Descriptions,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import SearchGroup from '../../../components/SearchGroup';
import {
  dateFix,
  isCanDelete,
  searchEngine,
  getSelectOptions,
  getDomHeight,
} from '../../../utils/common';
import { useLocation } from 'react-router-dom';
import BreadNav from '../../../components/breadNav';
const { confirm } = Modal;

export default function Purchaseorder() {
  const location = useLocation();
  const formRef = useRef(null);
  const dateFormat = 'YYYY-MM-DD HH:mm';

  const [isModalVisibleInfo, setIsModalVisibleInfo] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [product, setProduct] = useState([]);
  const [factory, setFactory] = useState([]);
  const [applier, setApplier] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [searchStatus, setSearchStatus] = useState('');
  const [itemStatus, setItemStatus] = useState([
    { key: 'proposed', value: '已提议' },
    { key: 'completed', value: '已采用' },
    { key: 'closed', value: '不采用' },
    { key: 'approved', value: '协商' },
  ]);
  const [itemStatusValue, setItemStatusValue] = useState();

  //人员信息
  const [addr, setAddr] = useState();
  const [contactMobile, setContactMobile] = useState();
  const [contactName, setContactName] = useState();
  const [name, setName] = useState();

  //表头
  const columns = [
    {
      title: '编号',
      dataIndex: 'reference',
      width: 100,
      fixed: 'left',
      //sorter: (a, b) => String(a.reference).length - String(b.reference).length,
      sorter: (a, b) => parseInt(a.reference) - parseInt(b.reference),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '状态',
      width: 100,
      dataIndex: 'computed_color',
      render: (value) => {
        if (parseInt(value) > 0) {
          return <span>提前</span>;
        } else if (parseInt(value) < 0) {
          return <span>延迟</span>;
        } else {
          return <span>准时</span>;
        }
      },
    },
    {
      title: '产品',
      width: 200,
      dataIndex: 'item',
      render: (value) => {
        return <span>{value}</span>;
      },
      sorter: (a, b) => String(a.item).length - String(b.item).length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '工厂',
      width: 200,
      dataIndex: 'location',
      render: (value) => {
        return <span>{value}</span>;
      },
      sorter: (a, b) => String(a.location).length - String(b.location).length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '供应商',
      width: 200,
      dataIndex: 'supplier',
      render: (value) => {
        return <span>{value}</span>;
      },
      sorter: (a, b) => String(a.supplier).length - String(b.supplier).length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '采购时间',
      width: 200,
      dataIndex: 'startdate',
      sorter: (a, b) => moment(a.startdate) - moment(b.startdate),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '到货时间',
      width: 200,
      dataIndex: 'enddate',
      sorter: (a, b) => moment(a.enddate) - moment(b.enddate),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '数量',
      width: 200,
      dataIndex: 'quantity',
      render: (value) => {
        return <span>{parseInt(value)}</span>;
      },
    },
    {
      title: '计划状态',
      width: 200,
      dataIndex: 'status',
      render: (value) => {
        switch (value) {
          case 'proposed':
            return '已提议';
          case 'completed':
            return '已采用';
          case 'closed':
            return '不采用';
          case 'approved':
            return '协商';
          default:
            return '';
        }
      },
      sorter: (a, b) => String(a.status).length - String(b.status).length,
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: '产品成本',
    //   dataIndex: 'unit_cost',
    //   render: (value) => {
    //     return <span>{value}</span>;
    //   },
    // },
    // {
    //   title: '产品总成本',
    //   dataIndex: 'total_cost',
    //   render: (value) => {
    //     return <span>{value}</span>;
    //   },
    // },
    {
      title: '需求',
      width: 200,
      dataIndex: 'require',
    },
    {
      title: '更新时间',
      width: 200,
      dataIndex: 'lastmodified',
      sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
      render: (text) => {
        return dateFix(text);
      },
    },
    {
      title: '操作',
      width: 200,
      fixed: 'right',
      dataIndex: '',
      render: (text) => (
        <div>
          <Button
            type="link"
            onClick={(e) => {
              setEditValue(text, 'edit');
            }}
          >
            编辑
          </Button>
          <Button
            type="text"
            danger
            name={text.reference}
            onClick={(e) => {
              let name = e.target.parentNode.name;
              setOptType('delete');
              showDeleteConfirm('delete', name);
            }}
          >
            删除
          </Button>
          {text.status == 'completed' && text.source ? (
            <Button type="link" onClick={() => setInfo(text, 'clientele')}>
              客户
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ];

  const setEditValue = (item, type) => {
    console.log('setEditValue');
    item = JSON.parse(JSON.stringify(item));
    setOptType(type);
    setIsModalVisible(true);
    setContent({ title: type === 'add' ? '新建采购订单' : '物料计划调整' });
    item.startdate = item.startdate ? moment(item.startdate, dateFormat) : '';
    item.enddate = item.enddate ? moment(item.enddate, dateFormat) : '';
    formRef.current.setFieldsValue(item);
  };

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showDeleteConfirm = (type, name) => {
    // 已采用数据不可删除
    const arr = type === 'delete' ? [name] : selectedRowKeys;
    if (isCanDelete(dataCopy, arr, 'reference')) {
      return false;
    }
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          deleteItem(name);
        } else if (type === 'deleteBatch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  //查看客户
  const setInfo = (text, info) => {
    if (info === 'clientele') {
      //客户
      setIsModalVisibleInfo(true);
      http.customGet(`/nkapi/center/company/${text.source}`).then((res) => {
        if (res.data) {
          const { addr, contactMobile, contactName, name } = res.data.Data;
          setAddr(addr);
          setContactMobile(contactMobile);
          setContactName(contactName);
          setName(name);
        }
      });
    }
  };
  //删除
  const deleteItem = (name) => {
    //console.log('deleteName: ', deleteName);
    http.post(
      `data/input/purchaseorder/${name}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
          alert(response.data.status);
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    http.postJSON(`data/input/purchaseorder/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        pageList();
        setSelectedRowKeys([]); //批量删除时重置setSelectedRowKeys
      } else {
        message.error('操作失败！');
      }
    });
  };

  const addItem = () => {
    console.log('addItem');
    const item = formRef.current.getFieldsValue();
    http.post(
      'data/input/purchaseorder/add/',
      {
        _save: '保存',
        reference: item.reference,
        item: item.item,
        location: item.location,
        supplier: item.supplier,
        quantity: item.quantity,
        status: item.status,
        batch: item.batch,
        ...handleDateParams(item.startdate, item.enddate),
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('请填写完整信息！');
        }
      }
    );
  };

  const editItem = () => {
    console.log('editItem');
    const item = formRef.current.getFieldsValue();
    http.post(
      `detail/input/purchaseorder/${item.reference}/`,
      {
        csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        _save: '保存',
        reference: item.reference,
        item: item.item,
        location: item.location,
        supplier: item.supplier,
        quantity: item.quantity,
        status: item.status,
        batch: item.batch,
        ...handleDateParams(item.startdate, item.enddate),
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('请填写完整信息！');
        }
      }
    );
  };

  const strToJson = (str) => {
    str = str.replace(/False/g, 'false');
    str = str.replace(/True/g, 'true');
    var json = eval('(' + str + ')');
    return json;
  };

  const demandsDataAdapter = (data) => {
    // console.log('demandsDataAdapter');
    return data.map((item) => {
      let require = '';
      const plan = strToJson(item.plan);
      if (plan && plan.pegging && Object.keys(plan.pegging).length > 0) {
        require = Object.keys(plan.pegging)[0];
      }
      return {
        ...item,
        require: require,
      };
    });
  };

  const pageList = (initFlag) => {
    http.get(
      'data/input/purchaseorder/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        const res = demandsDataAdapter(response.data.rows);
        setData(res);
        setDataCopy(res);
        setSearchStatus('ready');
        if (initFlag) {
          const params = location.params || {};
          // console.log(params, 'params');

          onSearch(
            [
              {
                key: 'reference',
                value: params.reference,
              },
            ],
            res
          );
        }
      }
    );
  };

  const handleOk = () => {
    if (optType === 'add') {
      addItem();
    } else if (optType === 'edit') {
      editItem();
    }
  };

  const saveOrders = async (items, status) => {
    const params = items.map((item) => {
      return {
        id: item,
        status,
      };
    });
    // console.log(params, 'params');
    const url = `data/input/purchaseorder/`;
    return http.post(url, params);
  };

  const onSearch = (keys, list) => {
    list = list || dataCopy;
    if (keys.every((key) => !key.value)) {
      pageList();
    } else {
      const arr = searchEngine(list, keys);
      setData(arr);
    }
  };

  useEffect(() => {
    pageList('init');

    productlist();
    locationlist();
    applierlist();
  }, []);

  /**产品列表 */
  const productlist = () => {
    http.get(
      'data/input/item/',
      {
        format: 'json',
        filters: {
          groupOp: 'AND',
          rules: [
            { field: 'owner', op: 'ne', data: '全部' },
            { field: 'name', op: 'ne', data: '全部' },
            { field: 'name', op: 'ne', data: 'All items', filtercount: 0 },
          ],
        },
      },
      function (response) {
        setProduct(response.data.rows);
      }
    );
  };

  /**工厂列表 */
  const locationlist = () => {
    http.get(
      'data/input/location/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        setFactory(response.data.rows);
      }
    );
  };
  /**供应商列表 */
  const applierlist = () => {
    http.get(
      'data/input/supplier/',
      {
        format: 'json',
      },
      function (response) {
        setApplier(response.data.rows);
      }
    );
  };

  //同步
  const synchBatch = () => {
    console.log('synchBatch');
    let selectedItems = []; //保存产品名称
    if (selectedRowKeys.length > 0) {
      selectedRowKeys.forEach((i) => {
        data.forEach((d) => {
          if (d.reference == i) {
            selectedItems.push(d.item);
          }
        });
      });
    }
    http.postNkJSON(
      'schedulesync/scheduled/purchase',
      {
        post: 'yes',
        products: selectedItems,
      },
      function (response) {
        console.log(response);
        if (response.data.Success) {
          message.success(response.data.Msg);
        } else {
          message.error(response.data.Msg);
        }
      }
    );
  };

  const handleDateParams = (startDate, endDate) => {
    const _dateFormat = 'YYYY-MM-DD';
    const _format = 'HH:mm';
    return {
      ordering_date_0: moment(startDate).format(_dateFormat),
      ordering_date_1: moment(startDate).format(_format),
      receipt_date_0: moment(endDate).format(_dateFormat),
      receipt_date_1: moment(endDate).format(_format),
    };
  };

  return (
    <div className="purchaseorder modal-list">
      <Modal
        title="客户信息"
        visible={isModalVisibleInfo}
        maskClosable={false}
        onCancel={() => {
          setIsModalVisibleInfo(false);
        }}
        footer={null}
      >
        <Descriptions column={1}>
          <Descriptions.Item label="地址">{addr}</Descriptions.Item>
          <Descriptions.Item label="电话">{contactMobile}</Descriptions.Item>
          <Descriptions.Item label="姓名">{contactName}</Descriptions.Item>
          <Descriptions.Item label="企业">{name}</Descriptions.Item>
        </Descriptions>
      </Modal>

      <Modal
        title={content.title}
        visible={isModalVisible}
        forceRender={true}
        maskClosable={false}
        okText="提交"
        cancelText="取消"
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form ref={formRef} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal">
          <Form.Item label="编号" name="reference">
            <Input disabled={optType === 'edit'} />
          </Form.Item>
          <Form.Item label="产品" name="item">
            <Select showSearch allowClear={true}>
              {product.map((item) => (
                <Select.Option value={item.name} key={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="工厂" name="location">
            <Select showSearch allowClear={true}>
              {factory.map((item) => (
                <Select.Option value={item.name} key={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="供应商" name="supplier">
            <Select showSearch allowClear={true}>
              {applier.map((item) => (
                <Select.Option value={item.name} key={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="数量" name="quantity">
            <InputNumber />
          </Form.Item>
          <Form.Item label="状态" name="status">
            <Select allowClear={true}>
              {itemStatus.map((item) => {
                return (
                  <Select.Option value={item.key} key={item.key}>
                    {item.value}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="订购时间" name="startdate">
            <DatePicker
              format="YYYY/MM/DD HH:mm"
              showTime={{ defaultValue: moment('00/00/00', 'HH:mm') }}
            />
          </Form.Item>
          <Form.Item label="发货时间" name="enddate">
            <DatePicker
              format="YYYY/MM/DD HH:mm"
              showTime={{ defaultValue: moment('00/00/00', 'HH:mm') }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <div className="layout-content">
        <div className="content-header">
          <BreadNav />
          <div>
            <SearchGroup
              items={[
                {
                  label: '产品：',
                  name: 'item',
                },
                {
                  label: '工厂名称',
                  name: 'location',
                },
                {
                  label: '需求',
                  name: 'require',
                  type: 'select',
                  className: 'requireClass',
                  options: getSelectOptions(dataCopy, 'require'),
                },
                {
                  label: '状态',
                  name: 'status',
                  type: 'select',
                  options: getSelectOptions(itemStatus, 'key'),
                },
              ]}
              onSearch={onSearch}
            ></SearchGroup>
          </div>
          <br />
          <Button
            type="primary"
            shape="round"
            size="middle"
            onClick={() => {
              setEditValue(
                {
                  reference: '',
                  item: '',
                  location: '',
                  supplier: '',
                  quantity: '',
                  status: '',
                  batch: '',
                  startdate: '',
                  enddate: '',
                },
                'add'
              );
              setContent({ title: '新建采购订单' });
            }}
          >
            &nbsp;新建
          </Button>{' '}
          &nbsp;&nbsp;
          <Button
            type="primary"
            shape="round"
            size="middle"
            danger
            onClick={() => {
              setOptType('deleteBatch');
              showDeleteConfirm('deleteBatch');
            }}
          >
            删除
          </Button>
          <Select
            style={{ width: 200, marginLeft: 20 }}
            placeholder="状态修改"
            allowClear
            value={itemStatusValue}
            onSelect={(value) => {
              console.log(value, '=====');
              setItemStatusValue(value);
              selectedRowKeys.forEach((name) => {
                data.forEach((target) => {
                  if (target.reference == name) {
                    target.status = value;
                  }
                });
              });
              setData(data);
            }}
            onClear={() => {
              setItemStatusValue('');
            }}
          >
            {itemStatus.map((item) => {
              // TODO:
              return (
                <Select.Option value={item.key} key={item.key}>
                  {item.value}
                </Select.Option>
              );
            })}
          </Select>
          <Button
            type="primary"
            shape="round"
            size="middle"
            style={{ marginLeft: 20 }}
            onClick={async () => {
              try {
                const res = await saveOrders(selectedRowKeys, itemStatusValue);
                // console.log('save success');
                if (res.data.status == 'success') {
                  message.success('操作成功！');
                  productlist();
                  locationlist();
                  applierlist();
                } else {
                  message.error('服务异常！');
                }
              } catch (e) {
                console.log(e);
                message.error('服务异常！');
              }
            }}
          >
            发布/回传
          </Button>
          <br />
          <br />
        </div>
        <div className="content-body">
          <Table
            size="small"
            scroll={{ x: '100vw' }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.reference}
            bordered
            pagination={{
              defaultPageSize: 20,
            }}
            style={{
              height: getDomHeight(),
            }}
            scroll={{
              y: getDomHeight(),
              x: '100vw',
            }}
          />
        </div>
      </div>
    </div>
  );
}
