import React, { useState, useEffect } from 'react';
import BreadNav from 'components/breadNav';
import { Input, Table, Button } from 'antd';
import { getDomHeight } from '../../../utils/common';
import http from '../../../http';

export default function People() {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [companys, setCompanys] = useState();
  const [total, setTotal] = useState();
  const [pageRows, setPageRows] = useState(20);

  //表头
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 150,
      key: 'index',
      render: (val, obj, index) => {
        return <span>{(currentIndex - 1) * 20 + (index + 1)}</span>;
      },
    },
    {
      title: '所属企业',
      dataIndex: 'compName',
      width: 400,
      key: 'compName',
    },
    {
      title: '员工编号',
      dataIndex: 'memberId',
      width: 200,
      key: 'memberId',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      width: 200,
      key: 'name',
    },
    {
      title: '手机号',
      dataIndex: 'mobile',
      width: 200,
      key: 'mobile',
    },
  ];
  //搜索
  const btnSearch = () => {
    locationList(1, 20, companys);
  };

  //每次切换页码的时候,更新之前初始化的页码
  const pagination = {
    pageSize: pageRows,
    total: total,
    onChange: (page, pageSize) => {
      setCurrentIndex(page);
      locationList(page, pageRows, companys);
    },
  };

  //渲染表格
  const locationList = (index = 1, rows = 20, companys) => {
    let companysNew = '';
    if (companys) {
      companysNew = companys.replace(/(^\s*)|(\s*$)/g, '');
    }
    let params = {
      PageIndex: index,
      PageRows: rows,
      CompName: companysNew,
    };
    http.customGet('/nkapi/center/users', params).then((res) => {
      if (res.data && res.data.Success) {
        const { rows, total } = res.data.Data;
        setData(rows);
        setTotal(total);
      }else{
        return
      }
    });
  };

  //挂载
  useEffect(() => {
    locationList();
  }, []);

  return (
    <div className="modal-list layout-content">
      <div className="content-header">
        <BreadNav />
        <div className="search-container">
          所属企业&nbsp;:&nbsp;&nbsp;
          <Input
            placeholder="所属企业"
            allowClear
            style={{ width: 200 }}
            onChange={(e) => {
              setCompanys(e.target.value);
            }}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button type="primary" shape="round" onClick={btnSearch}>
            搜索
          </Button>
        </div>
        <br />
      </div>
      <div className="content-body">
        <Table
          bordered
          size="small"
          className="table-normal"
          columns={columns}
          dataSource={data}
          pagination={pagination} //分页属性
          style={{
            height: getDomHeight(),
          }}
          scroll={{
            y: getDomHeight(),
            // x: '100vw',
          }}
        />
      </div>
    </div>
  );
}
