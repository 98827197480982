import React from 'react';
import moment from 'moment';
import filterItem from '../../../utils/filterItem';
import http from '../../../http';
import { Table, message, Button, Modal, Form, Input, Select } from 'antd';
import BreadNav from '../../../components/breadNav';
import { getDomHeight } from '../../../utils/common';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
const axios = require('axios');
const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
export default class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isModalVisible: false,
      content: { title: '' },
      data: [],
      staticData: [],
      calendars: [],
      type: '',
      name: '',
      description: '',
      available: '',
      owner: '',
      owners: [],
      deleteName: '',
      whichItem: '',
      status: '',
    };
    this.formRef = React.createRef();
    //表头
    this.columns = [
      {
        title: '名称',
        dataIndex: 'name',
        width: 200,
        sorter: (a, b) => String(a.name).length - String(b.name).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '描述',
        dataIndex: 'description',
        width: 200,
        sorter: (a, b) => String(a.description).length - String(b.description).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '日历',
        dataIndex: 'available_id',
        width: 200,
        sorter: (a, b) => String(a.available_id).length - String(b.available_id).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '父名称',
        dataIndex: 'owner_id',
        width: 200,
        sorter: (a, b) => String(a.owner_id).length - String(b.owner_id).length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: '更新时间',
        width: 200,
        dataIndex: 'lastmodified',
        sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
        render: (text) => text.split('.')[0],
      },
      // {
      //   title: '操作',
      //   dataIndex: '',
      //   width: 200,
      //   render: (text) => {
      //     let { pageStatus } = this.state;
      //     return (
      //       <div>
      //         <Button
      //           type="link"
      //           text={JSON.stringify(text)}
      //           onClick={(e) => {
      //             if (!pageStatus) return;
      //             let { name, description, available, owner } = JSON.parse(
      //               e.currentTarget.getAttribute('text')
      //             );
      //             this.setState(
      //               {
      //                 name,
      //                 description,
      //                 available,
      //                 owner,
      //                 content: { title: '编辑工厂信息' },
      //                 type: 'edit',
      //                 isModalVisible: true,
      //                 status: 'edit',
      //                 whichItem: name,
      //               },
      //               () => {
      //                 if (this.formRef.current && this.formRef.current.setFieldsValue) {
      //                   this.formRef.current.setFieldsValue({
      //                     name,
      //                     description,
      //                     owner,
      //                     available,
      //                     available,
      //                   });
      //                 }
      //               }
      //             );
      //           }}
      //         >
      //           编辑
      //         </Button>
      //         <Button
      //           type="text"
      //           danger
      //           name={text.name}
      //           onClick={(e) => {
      //             if (!pageStatus) return;
      //             let name = e.target.parentNode.name;
      //             this.setState(
      //               {
      //                 status: 'delete',
      //                 type: 'delete',
      //                 whichItem: name,
      //               },
      //               this.showDeleteConfirm
      //             );
      //           }}
      //         >
      //           删除
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ];
  }

  //表格选中行
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  //表格选中行
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  //对话框
  showDeleteConfirm = () => {
    let { type } = this.state;
    let _this = this;

    confirm({
      title: '您确定要删除该项数据吗？对应的销售订单也会同时删除！',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          _this.deleteItem();
        } else if (type === 'deleteBatch') {
          _this.deleteBatch();
        }
      },
      onCancel() {},
    });
  };
  //列表
  // itemList = () => {
  //   let _this = this;

  //   http.get(
  //     'data/input/location/',
  //     {
  //       format: 'json',
  //       // _search: false,
  //       // rows: 100,
  //       rows: 10000,
  //       page: 1,
  //       // sidx: 'lastmodified',
  //       // sord: 'desc',
  //     },
  //     function (response) {
  //       if (response.data && response.data.rows) {
  //         let data = response.data.rows;
  //         _this.setState({
  //           staticData: data,
  //           data,
  //           status: 'ready',
  //         });
  //       }
  //     }
  //   );
  itemList = () => {
    let _this = this;

    http.postNkJSON(
      'location/getdatalist',
      {
        SortField: 'name',
        SortType: '',
      },
      function (response) {
        if (response.data && response.data.Success) {
          let data = response.data.Data;
          _this.setState({
            staticData: data,
            data,
            status: 'ready',
          });
        }else{
          return
        }
      }
    );
  };
  //日历列表
  calendarList = () => {
    return http.customGet('/api/data/input/calendar/?/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
      _to_field: 'name',
      _popup: 1,
    });
  };
  //工厂列表
  locationList = () => {
    return http.customGet('/api/data/input/location/', {
      format: 'json',
      _search: false,
      rows: 100,
      page: 1,
      sidx: 'lastmodified',
      sord: 'desc',
    });
  };
  //请求所有数据
  allList = () => {
    let _this = this;
    axios.all([this.calendarList(), this.locationList()]).then((results) => {
      let calendars = filterItem(results[0].data.rows, 'name');
      let data = results[1].data.rows;
      let owners = filterItem(data, 'name');

      _this.setState({
        calendars,
        staticData: data,
        data,
        owners,
        pageStatus: 'ready',
      });
    });
  };
  //新建
  addItem = () => {
    let { name, description, available, owner } = this.state;
    let _this = this;

    http.post(
      'data/input/location/add/',
      {
        csrfmiddlewaretoken: 'mV3EB7B9SJLO7LZTHNU1fcakr9z75W1npDcy8YjuzB6waYxxBihC76xbLqdOcIGg',
        _save: '保存',
        name,
        description,
        available,
        owner,
        category: '',
        subcategory: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //编辑
  editItem = () => {
    let { name, description, available, owner, whichItem } = this.state;
    let _this = this;

    http.post(
      `detail/input/location/${whichItem}/`,
      {
        csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        _save: '保存',
        name,
        description,
        available,
        owner,
        category: '',
        subcategory: '',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('名称不能为空！');
        }
      }
    );
  };
  //删除
  deleteItem = () => {
    let { whichItem } = this.state;
    let _this = this;

    http.post(
      `data/input/location/${whichItem}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          _this.itemList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };
  //批量删除
  deleteBatch = () => {
    let { type, selectedRowKeys } = this.state;
    let _this = this;
    if (type !== 'deleteBatch') return;

    http.postJSON(`data/input/location/`, [{ delete: selectedRowKeys }], function (response) {
      if (response.data.status === 'success') {
        message.success('操作成功！');
        _this.itemList();
      } else {
        message.error('操作失败！');
      }
    });
  };
  //表单提交
  handleOK = () => {
    let { type } = this.state;

    if (type === 'add') {
      this.addItem();
    } else if (type === 'edit') {
      this.editItem();
    }
  };
  //模糊查询
  handleSearch = (searched) => {
    let { staticData } = this.state;
    let arr = staticData.filter((item) => {
      let val = searched.replace(/(^\s*)|(\s*$)/g, '');
      return item.name.includes(val);
    });
    this.setState({
      data: arr,
    });
  };
  //同步
  synchronization = () => {
    http.customPost('/nkapi/sync/locations').then((res) => {
      // console.log('res', res);
      if (res.data.Success === true) {
        message.success('同步成功！');
        this.itemList();
      } else {
        message.error('同步失败！');
      }
    });
  };
  componentDidMount() {
    this.itemList();
    // this.allList();
  }

  render() {
    let {
      name,
      description,
      data,
      owner,
      owners,
      isModalVisible,
      content,
      available,
      calendars,
      selectedRowKeys,
    } = this.state;
    return (
      <div className="modal-list">
        <Modal
          title={content.title}
          visible={isModalVisible}
          maskClosable={false}
          okText="提交"
          cancelText="取消"
          onOk={this.handleOK}
          onCancel={() => {
            this.setState({ isModalVisible: false });
          }}
        >
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            ref={this.formRef}
            initialValues={{
              name,
              description,
              available,
              owner,
            }}
            onValuesChange={(changedValues, allValues) => {
              this.setState({
                ...changedValues,
              });
            }}
          >
            <Form.Item name="name" label="名称" rules={[{ required: true, message: '请输入名称' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="description" label="描述">
              <Input.TextArea />
            </Form.Item>
            <Form.Item name="available" label="日历">
              <Select showSearch allowClear>
                {calendars.map(
                  (item) =>
                    item !== name && (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
            <Form.Item name="owner" label="父级">
              <Select showSearch allowClear>
                {owners.map(
                  (item) =>
                    item !== name && (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <div className="layout-content">
          <div className="content-header">
            <BreadNav />
            <div className="search-container">
              名称&nbsp;:&nbsp;&nbsp;
              <Search
                className="search"
                enterButton="查询"
                size="middle"
                allowClear
                onSearch={(value) => {
                  this.handleSearch(value);
                }}
              />
            </div>
            <br />
            {/* <Button
              type="primary"
              shape="round"
              size="middle"
              onClick={() => {
                if (this.formRef.current && this.formRef.current.setFieldsValue) {
                  this.formRef.current.setFieldsValue({
                    name: '',
                    description: '',
                    available: '',
                    owner: '',
                  });
                }
                this.setState({
                  type: 'add',
                  isModalVisible: true,
                  content: { title: '新建工厂信息' },
                });
              }}
            >
              +&nbsp;新建
            </Button>
            &nbsp;&nbsp;
            <Button
              type="primary"
              shape="round"
              size="middle"
              danger
              onClick={() => {
                this.setState(
                  {
                    type: 'deleteBatch',
                  },
                  () => {
                    this.showDeleteConfirm('deleteBatch');
                  }
                );
              }}
            >
              删除
            </Button>
            <br />
            <br />*/}
            <Button
              type="primary"
              shape="round"
              size="middle"
              onClick={() => {
                this.synchronization();
              }}
            >
              同步
            </Button>
            <br />
            <br />
          </div>
          <div className="content-body">
            <Table
              size="small"
              // rowSelection={{
              //   selectedRowKeys,
              //   onChange: this.onSelectChange,
              // }}
              pagination={{ pageSize: 15 }}
              columns={this.columns}
              dataSource={data}
              rowKey={(record) => record.name}
              bordered
              style={{
                height: getDomHeight(),
              }}
              scroll={{
                y: getDomHeight(),
                // x: '100vw',
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
