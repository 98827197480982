import React, { useState, useEffect, useRef } from 'react';
import http from '../../../http';
import { Table, Tabs, Breadcrumb } from 'antd';
import RelationChart from './components/relationChart';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import PlanningGantt from './components/planningGantt';
import './index.scss';

export default function Orders() {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [supplyPaths, setSupplyPaths] = useState([]);
  const location = useLocation();

  const bodyRef = useRef(null);
  const [height, setHeight] = useState('');

  const columns = [
    {
      title: '层级',
      dataIndex: 'depth',
      key: 'depth',
      width: 100,
      ellipsis: true,
      fixed: 'left',
      resize: true,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      width: 150,
      // ellipsis: true,
      resize: true,
    },
    {
      title: '对象',
      dataIndex: 'item',
      width: 150,
      key: 'item',
    },
    {
      title: '对象-描述',
      dataIndex: 'description',
      width: 150,
      key: 'description',
    },
    {
      title: '优先级',
      dataIndex: 'priority',
      width: 50,
      key: 'priority',
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      width: 50,
      key: 'quantity',
    },
    {
      title: '地点',
      dataIndex: 'location',
      width: 100,
      key: 'location',
    },
    // {
    //   title: '类型',
    //   dataIndex: 'type',
    //   width: 100,
    //   key: 'type',
    // },
    // {
    //   title: '持续时间',
    //   dataIndex: 'duration',
    //   width: 100,
    //   render: (value) => {
    //     getTime(value);
    //     return <div>{getTime(value)}</div>;
    //   },
    // },
    // {
    //   title: '每单位持续时间',
    //   dataIndex: 'duration_per',
    //   width: 100,
    //   render: (value) => {
    //     getTime(value);
    //     return <div>{getTime(value)}</div>;
    //   },
    // },
    {
      title: '资源',
      dataIndex: 'resources',
      width: 100,
      // key: 'resources',
      render: (value) => {
        if (value && value[0]) {
          return <div>{value[0][0]}</div>;
        }
      },
    },
  ];

  const data = [
    {
      key: 1,
      name: 'John Brown sr.',
      age: 60,
      address: 'New York No. 1 Lake Park',
      children: [
        {
          key: 11,
          name: 'John Brown',
          age: 42,
          address: 'New York No. 2 Lake Park',
        },
        {
          key: 12,
          name: 'John Brown jr.',
          age: 30,
          address: 'New York No. 3 Lake Park',
          children: [
            {
              key: 121,
              name: 'Jimmy Brown',
              age: 16,
              address: 'New York No. 3 Lake Park',
            },
          ],
        },
        {
          key: 13,
          name: 'Jim Green sr.',
          age: 72,
          address: 'London No. 1 Lake Park',
          children: [
            {
              key: 131,
              name: 'Jim Green',
              age: 42,
              address: 'London No. 2 Lake Park',
              children: [
                {
                  key: 1311,
                  name: 'Jim Green jr.',
                  age: 25,
                  address: 'London No. 3 Lake Park',
                },
                {
                  key: 1312,
                  name: 'Jimmy Green sr.',
                  age: 18,
                  address: 'London No. 4 Lake Park',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      key: 2,
      name: 'Joe Black',
      age: 32,
      address: 'Sidney No. 1 Lake Park',
    },
  ];
  const nest = (items, id = null, link = 'parent') =>
    items
      .filter((item) => item[link] === id)
      .map((item) => {
        if (nest(items, item.id).length > 0) {
          return { ...item, children: nest(items, item.id) };
        } else {
          return { ...item };
        }
      });
  const getTime = (value) => {
    if (!value) {
      return '';
    }
    const tempTime = moment.duration(value);
    const d = tempTime.days();
    const h = tempTime.hours();
    const m = tempTime.minutes();
    const s = tempTime.seconds();
    const time = d + ' ' + h + ':' + m + ':' + s;
    return time;
  };
  const getSupply = async () => {
    const params = location.params || {};
    const url = `supplypath/demand/${params.name}/`;
    return http.get(encodeURIComponent(url), {
      noautofilter: '',
      format: 'json',
      _search: false,
      rows: 10000,
      page: 1,
      nd: 1614800051972,
      //sidx: 'name',
      // sidx: 'resource',
      // sord: 'asc',
      //_: 1614800051588
    });
  };
  const getHeight = () => {
    console.log(bodyRef.current.offsetHeight, 'offsetHeight');
    return bodyRef.current?.offsetHeight - 50;
  };
  useEffect(async () => {
    try {
      const res = await getSupply();
      console.log('useEffect');
      const list = res.data?.rows || [];
      setSupplyPaths(list);
      // treeTable赋值
      list.forEach((row) => {
        row.parent = row.parent ? row.parent : null;
      });
      setSupplyPaths(list);
      const tree = nest(list);
      setList(tree);
      setHeight(getHeight());
    } catch (e) {
      console.log(e);
    }
    return () => {
      //cleanup
    };
  }, []);

  return (
    <div className="layout-content">
      <div className="content-header">
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push({
                pathname: '/dashboard',
              });
            }}
          >
            {'主页'}
          </Breadcrumb.Item>
          <Breadcrumb.Item
            className="orders-bread"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.goBack();
            }}
          >
            返回上一页
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div>
        <Tabs tabPosition={'top'}>
          <Tabs.TabPane tab="供应路径" key="1">
            {supplyPaths?.length > 0 && <RelationChart params={supplyPaths} />}
            <div ref={bodyRef}>
              {height && (
                <Table
                  bordered
                  defaultExpandAllRows={true}
                  size="small"
                  scroll={{ y: height, x: '100vw' }}
                  columns={columns}
                  dataSource={list}
                  rowKey={(record) => record.id}
                />
              )}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="计划" key="2">
            {location.params && <PlanningGantt id={location.params.name}></PlanningGantt>}
            {/* <PlanningGantt id={'name'}></PlanningGantt> */}
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}
