const axios = require('axios');
const qs = require('qs');
const http = {};
import config from '../config/index';

window.env = localStorage.getItem('env') || 'default';

const service = axios.create({
  // Request backend address settings
  baseURL: '',
  // Timeout setting
  timeout: 300 * 1000,
  withCredentials: true, // 是否跨域
  // headers: { 'Cache-Control': 'no-cache' },
});

// 异常拦截处理器
const errorHandler = (error) => {
  return Promise.reject(error);
};
// 环境url切换
const urlAdapter = (url) => {
  if (window.env !== 'default') {
    url = url.replace('/api/', `/api/${window.env}/`);
  }
  if (url.includes('/api/')) {
    url = url.replace('/api/', config.url.apiServer);
  } else if (url.includes('/nkapi/')) {
    url = url.replace('/nkapi/', config.url.nkapiServer);
  }
  // else if (url.includes('/nkaps/')) {
  //   url = url.replace('/nkaps/', config.url.nkapsServer);
  // }
  return url;
};
let pendingNum = 0;
// request拦截器
service.interceptors.request.use((request) => {
  pendingNum += 1;
  request.url = urlAdapter(request.url);
  // 网络未连接
  if (!navigator.onLine) {
    throw Error('请检查网络连接');
  }
  // 此处进行登录拦截、处理请求头信息、全局loading等工作
  if (!request.headers) {
    request.headers = {};
  }
  return request;
}, errorHandler);
service.interceptors.response.use(
  (response) => {
    pendingNum -= 1;
    return response;
  },
  (err) => {
    pendingNum -= 1;
    if (err.toString().includes('401')) {
      window.location.href = window.location.origin + '/login';
    }
    return Promise.reject(err);
  }
);

/**
 *
 * @param {Array} params
 * @param {String} url
 * @param {callback} cb
 */
const urlSearchPost = function (params, url, cb) {
  let data = new URLSearchParams();
  for (let key in params) {
    data.append(key, params[key]);
  }
  service({
    method: 'post',
    url: config.url.apiServer + url,
    data,
  }).then(function (response) {
    cb(response);
  });
};

/**
 *
 * @param {String} url
 * @param {Object} params
 * @param {callback} cb
 */
const get = function (url, params, cb) {
  return service.get(config.url.apiServer + url, { params: params }).then(
    (response) => {
      cb && cb(response);
      return response;
    },
    (err) => err
  );
};

const customGet = function (url, params, cb) {
  return service.get(url, { params: params }).then(
    (response) => {
      cb && cb(response);
      return response;
    },
    (err) => err
  );
};
const customPost = function (url, params, cb) {
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: Array.isArray(params) ? params : qs.stringify(params),
    url,
  };
  return service(options).then(
    (response) => {
      cb && cb(response);
      return response;
    },
    (err) => err
  );
};

const customPost_device = function (url, params, cb) {
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    data: params,
    url,
  };
  return service(options).then(
    (response) => {
      cb && cb(response);
      return response;
    },
    (err) => err
  );
};

const post = function (url, params, cb) {
  /** url 特殊字符处理 */
  let finalUrl = `${config.url.apiServer}${url}`.replace(/\#/g, '%23');

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: Array.isArray(params) ? params : qs.stringify(params),
    url: finalUrl,
  };
  return service(options).then(
    (response) => {
      cb && cb(response);
      return response;
    },
    (err) => err
  );
};

const postJSON = function (url, params, cb) {
  /** url 特殊字符处理 */
  let finalUrl = `${config.url.apiServer}${url}`.replace(/\#/g, '%23');

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    data: JSON.stringify(params),
    url: finalUrl,
  };
  return service(options).then(function (response) {
    cb && cb(response);
  });
};

const postNkJSON = function (url, params, cb) {
  /** url 特殊字符处理 */
  let finalUrl = `${config.url.nkapiServer}${url}`.replace(/\#/g, '%23');

  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    data: JSON.stringify(params),
    url: finalUrl,
  };
  return service(options).then(function (response) {
    cb && cb(response);
  });
};

// const nkapsPost = function (url, params, cb) {
//   /** url 特殊字符处理 */
//   let finalUrl = `${config.url.nkapsServer}${url}`.replace(/\#/g, '%23');
//   const options = {
//     method: 'POST',
//     headers: { 'content-type': 'application/json' },
//     data: JSON.stringify(params),
//     url: finalUrl,
//   };
//   return service(options).then(
//     (response) => {
//       cb && cb(response);
//       return response;
//     },
//     (err) => err
//   );
// };

http.urlSearchPost = urlSearchPost;
http.get = get;
http.post = post;
http.postJSON = postJSON;
http.customGet = customGet;
http.customPost = customPost;
http.postNkJSON = postNkJSON;
http.customPost_device = customPost_device;
// http.nkapsPost = nkapsPost;
export default http;
