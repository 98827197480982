import { Switch, BrowserRouter } from 'react-router-dom';
// 你的高阶组件
import FrontendAuth from './frontendAuth';

import Dashboard from '../pages/dashboard';
import Production from '../pages/sales/production';
import Location from '../pages/sales/location';
import Orders from '../pages/sales/orders';
import CapacityDispatch from '../pages/sales/orders/capacityDispatch';
import Customer from '../pages/sales/customer';
import SupplyPath from '../pages/sales/supplyPath';
import PlanDetail from '../pages/sales/planDetail';
import Inventory from '../pages/inventory/inventory';
import Distribution from '../pages/inventory/distribution';
import Report from '../pages/inventory/report';
import Resource from '../pages/capacity/resource';
import ResourceReport from '../pages/capacity/report';
import Applier from '../pages/purchasing/applier';
import ProductionApplier from '../pages/purchasing/productionApplier';
import PurchaseOrder from '../pages/purchasing/order';
import Calendars from '../pages/manufacturing/calendars';
import Workshift from '../pages/manufacturing/workshift';
import Execution from '../pages/manufacturing/execution';
import PlanningList from '../pages/manufacturing/planning_list';
import ProductionList from '../pages/manufacturing/productionList';
import Skill from '../pages/manufacturing/skill';
import PlanningGantt from '../pages/manufacturing/planning_gantt';
import InventoryPlanning from '../pages/manufacturing/inventoryPlanning';
import LogisticsCenter from '../pages/productionPlan/logisticsCenter';
import WhatIf from '../pages/manufacturing/whatIf';
import ImportConfig from '../pages/system/importConfig';
import User from '../pages/system/user';
import Cockpit from '../pages/system/cockpit';
import Dashboard1 from '../pages/system/dashboard';
import Device from '../pages/devicePeople/device';
import People from '../pages/devicePeople/people';

const routes = [
  //dashboard
  {
    path: '/dashboard',
    auth: ['base'],
    component: <Dashboard />,
  },
  //销售
  {
    path: '/sales/production',
    auth: ['admin'],
    component: <Production />,
  },
  {
    path: '/sales/location',
    auth: ['admin'],
    component: <Location />,
  },
  {
    path: '/sales/orders',
    auth: ['admin'],
    component: <Orders />,
  },
  {
    path: '/sales/capacityDispatch',
    auth: ['admin'],
    component: <CapacityDispatch />,
  },
  {
    path: '/sales/customer',
    auth: ['admin'],
    component: <Customer />,
  },
  {
    path: '/sales/supplyPath',
    auth: ['base'],
    component: <SupplyPath />,
  },
  {
    path: '/sales/planDetail',
    auth: ['base'],
    component: <PlanDetail />,
  },
  //库存
  {
    path: '/inventory/inventory',
    auth: ['admin'],
    component: <Inventory />,
  },
  {
    path: '/inventory/distribution',
    auth: ['admin'],
    component: <Distribution />,
  },
  {
    path: '/inventory/report',
    auth: ['admin'],
    component: <Report />,
  },
  //产能
  {
    path: '/capacity/resource',
    auth: ['admin'],
    component: <Resource />,
  },
  {
    path: '/capacity/resourceReport',
    auth: ['admin'],
    component: <ResourceReport />,
  },
  //物料计划管理
  {
    path: '/purchasing/applier',
    auth: ['admin'],
    component: <Applier />,
  },
  {
    path: '/purchasing/productionApplier',
    auth: ['admin'],
    component: <ProductionApplier />,
  },
  {
    path: '/purchasing/purchaseorder',
    auth: ['admin'],
    component: <PurchaseOrder />,
  },
  //生产制造
  {
    path: '/manufacturing/calendars',
    auth: ['admin'],
    component: <Calendars />,
    beforeEnter(props) {
      console.log(props, 'dashboard');
      return '/dashboard';
    },
  },
  {
    path: '/manufacturing/workshift',
    auth: ['admin'],
    component: <Workshift />,
  },
  {
    path: '/manufacturing/execution',
    auth: ['admin'],
    component: <Execution />,
  },
  {
    path: '/manufacturing/planningList',
    auth: ['admin'],
    component: <PlanningList />,
  },
  {
    path: '/manufacturing/skill',
    auth: ['admin'],
    component: <Skill />,
  },
  {
    path: '/manufacturing/productionList',
    auth: ['admin'],
    component: <ProductionList />,
  },
  {
    path: '/manufacturing/whatif',
    auth: ['admin'],
    component: <WhatIf />,
  },
  {
    path: '/manufacturing/planningGantt',
    auth: ['admin'],
    component: <PlanningGantt />,
  },
  {
    path: '/manufacturing/inventoryPlanning',
    auth: ['admin'],
    component: <InventoryPlanning />,
  },
  // 物流中心调度
  {
    path: '/productionLine/logisticsCenter',
    auth: ['admin'],
    component: <LogisticsCenter />,
  },
  // 导入配置
  {
    path: '/system/importConfig',
    auth: ['admin'],
    component: <ImportConfig />,
  },
  // 用户管理
  {
    path: '/system/user',
    auth: ['admin'],
    component: <User />,
  },
  //cockpit
  {
    path: '/system/cockpit',
    auth: ['admin'],
    component: <Cockpit />,
  },
  //dashboard
  {
    path: '/system/dashboard',
    auth: ['base'],
    component: <Dashboard1 />,
  },
  //devicePeople
  {
    path: '/devicePeople/device',
    auth: ['admin'],
    component: <Device />,
  },
  {
    path: '/devicePeople/people',
    auth: ['admin'],
    component: <People />,
  },
];
function router() {
  return (
    // <BrowserRouter>
    <Switch>
      <FrontendAuth routerConfig={routes} />
    </Switch>
    // </BrowserRouter>
  );
}
export default router;
