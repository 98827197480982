import { getAuthMenus } from '../api/user';
export const GetAuthMenus = (params) => {
  return async (dispath) => {
    try {
      const response = await getAuthMenus(params);
      dispath({
        type: 'SET_AUTH_MENUS',
        data: response,
      });
    } catch (e) {}
    return undefined;
  };
};
export const SetCurrentMenus = (params) => {
  return (dispath) => {
    try {
      dispath({
        type: 'SET_CURRENT_MENUS',
        data: params,
      });
    } catch (e) {}
    return undefined;
  };
};
