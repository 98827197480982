export function logReducer(state = { logged: false, authMenus: [] }, action) {
  switch (action.type) {
    case 'SET_AUTH_MENUS':
      return {
        ...state,
        authMenus: action.data,
      };
    case 'SET_CURRENT_MENUS':
      return {
        ...state,
        currentMenus: action.data,
      };
    case 'logIn':
      console.log('login=====');
      return { logged: true };
    case 'logOut':
      return { logged: false };
    default:
      return state;
  }
}
