import React, { Component } from 'react';
import http from '../../../http';
import { withRouter } from 'react-router-dom';
import './index.scss';
import { launchFullscreen, exitFullscreen } from '../../../utils/common';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import CenterChart from './chart';
import Echarts from '../../../components/Echarts';

class PlanningGantt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullScreenState: '0',
      centerOption: {},
      indicatorOption: {
        title: {
          text: '折线图堆叠',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: '邮件营销',
            type: 'line',
            stack: '总量',
            data: [120, 132, 101, 134, 90, 230, 210],
          },
          {
            name: '联盟广告',
            type: 'line',
            stack: '总量',
            data: [220, 182, 191, 234, 290, 330, 310],
          },
          {
            name: '视频广告',
            type: 'line',
            stack: '总量',
            data: [150, 232, 201, 154, 190, 330, 410],
          },
          {
            name: '直接访问',
            type: 'line',
            stack: '总量',
            data: [320, 332, 301, 334, 390, 330, 320],
          },
          {
            name: '搜索引擎',
            type: 'line',
            stack: '总量',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
          },
        ],
      },
    };
  }
  componentDidMount() {}
  componentWillReceiveProps() {}

  renderFullScreenBtn() {
    // 全屏
    if (this.state.fullScreenState === '1') {
      return (
        <FullscreenExitOutlined
          className="fullscreen-btn"
          onClick={() => {
            exitFullscreen();
            this.setState({ fullScreenState: '0' });
          }}
        />
      );
    } else {
      // 退出全屏
      return (
        <FullscreenOutlined
          className="fullscreen-btn"
          onClick={() => {
            launchFullscreen(document.getElementById('cockpit'));
            this.setState({ fullScreenState: '1' });
          }}
        />
      );
    }
  }

  render() {
    return (
      <div
        className="cockpit-page"
        id="cockpit"
        style={{
          width: '100%',
          height: '100%',
          background: '#172234',
        }}
      >
        {this.renderFullScreenBtn()}
        <div
          style={{
            width: '100%',
            height: '100%',
            background: '#172234',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div style={{ width: 400, height: 400 }}>
            <CenterChart chartId="cockpit_chart" option={this.state.centerOption}></CenterChart>
          </div>
          <div style={{ width: 400, height: 400 }}>
            <Echarts chartId="indicator_chart" option={this.state.indicatorOption}></Echarts>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PlanningGantt);
