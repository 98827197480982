import React, { useState, useEffect } from 'react';
import http from '../../../http';
import { Breadcrumb, Table } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import './index.scss';
import Echarts from '../../../components/Echarts';

export default function Orders() {
  const history = useHistory();
  const location = useLocation();
  const defaultOption = {
    legend: {},
    tooltip: {},
    dataset: {
      source: [
        // ['product', '建议开工数量', '建议完工数量', 'Proposed production'],
        // ['2-1', 43, 85.8, 93.7],
        // ['2-2', 83.1, 73.4, 55.1],
        // ['2-3', 86.4, 65.2, 82.5],
        // ['2-4', 72.4, 53.9, 39.1],
        // ['2-5', 72.4, 53.9, 39.1],
        // ['2-6', 72.4, 53.9, 39.1],
        // ['2-7', 72.4, 53.9, 39.1],
      ],
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
  };
  const [option, setOption] = useState(defaultOption);
  const [rows, setRows] = useState([]);
  //表头
  const columnsCopy = [
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      fixed: 'left',
      sorter: (a, b) => a.operation.localeCompare(b.operation),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '地点',
      dataIndex: 'location',
      width: 200,
    },
    {
      title: '',
      dataIndex: 'customKey',
      width: 200,
      render: (value) => {
        return (
          <div>
            <div>{value[0]}</div>
            <div>{value[1]}</div>
            <div>{value[2]}</div>
          </div>
        );
      },
    },
  ];
  const [columns, setColumns] = useState(columnsCopy);
  const data = [{ name: '油漆2128#1.8米加顶#二推衣柜胡', description: '共享喷涂生产基地' }];

  const getPlans = async () => {
    const id = (location.params && location.params.text) || '1';
    console.log(id, 'id');
    const url = `operation/${encodeURIComponent(id)}/`;
    const params = {
      format: 'json',
      _search: false,
      nd: 1616482309004,
      rows: 100,
      page: 1,
      sidx: 'operation',
      sord: 'asc',
      _: 1616482308540,
      no_auto_filter: '',
    };
    return http.get(url, params);
    // return http.post(`api/detail/input/operation/${123}/`, {});
  };
  const optionAdapter = (data) => {
    // ['product', '2015', '2016', '2017'],
    // ['2-1', 43, 85.8, 93.7],
    // ['2-2', 83.1, 73.4, 55.1],
    // ['2-3', 86.4, 65.2, 82.5],
    // ['2-4', 72.4, 53.9, 39.1],
    // ['2-5', 72.4, 53.9, 39.1],
    // ['2-6', 72.4, 53.9, 39.1],
    // ['2-7', 72.4, 53.9, 39.1],
    const obj = (data.rows && data.rows[0]) || {};
    const dateKeys = [];
    let source = [];
    const result = [];
    // console.log(obj, 'obj');
    Object.keys(obj).forEach((key) => {
      if (/^\d+$/.test(key.split('')[0])) {
        const o = {
          key,
          values: obj[key],
        };
        dateKeys.push(o);
      }
    });
    source = dateKeys.filter((dateKey, index) => {
      return index % 1 === 0;
    });

    // set table columns
    const currColumns = source.map((column) => {
      return {
        title: column.key,
        dataIndex: column.key,
        width: 200,
        render: (value) => {
          return (
            <div>
              <div>{value[0]}</div>
              <div>{value[1]}</div>
              <div>{value[2]}</div>
            </div>
          );
        },
      };
    });
    setColumns([...columns, ...currColumns]);

    // set table rows
    obj.customKey = ['全部开工数量', '全部完工数量', '总产量'];
    setRows([obj]);
    // console.log(columns, 'columns');

    const f = ['产品', '建议开工数量', '建议完工数量', '建议生产'];
    result[0] = f;
    source.forEach((item) => {
      const arr = [item.key, ...item.values];
      result.push(arr);
    });
    // console.log(dateKeys, 'dateKeys');
    // console.log(source, 'source');
    // console.log(result, 'result');
    const optionCopy = JSON.parse(JSON.stringify(defaultOption));
    optionCopy.dataset.source = result;
    setOption(optionCopy);
  };

  useEffect(async () => {
    const res = await getPlans();
    optionAdapter(res.data);
    // optionAdapter(require('./data.json'));
    return () => {
      //cleanup
    };
  }, []);

  return (
    <div className="page">
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>销售</Breadcrumb.Item>
        <Breadcrumb.Item
          className="orders-bread"
          onClick={() => {
            history.push({
              pathname: '/sales/orders',
            });
          }}
        >
          销售订单
        </Breadcrumb.Item>
        <Breadcrumb.Item>计划</Breadcrumb.Item>
      </Breadcrumb>
      <div className="chart-wrapper">
        <Echarts chartId="plan_chart" option={option} width></Echarts>
      </div>
      <div className="table-wrapper">
        <Table
          scroll={{ x: 1500 }}
          sticky
          columns={columns}
          dataSource={rows}
          size="middle"
          // rowKey={(record) => record.name}
        />
      </div>
    </div>
  );
}
