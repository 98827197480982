import React, { useState, useEffect, useRef } from 'react';
import http from '../../../http';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Breadcrumb,
  Radio,
  Slider,
  Menu,
  Dropdown,
  Upload,
  message,
  Spin,
} from 'antd';
import './index.scss';
import BreadNav from '../../../components/breadNav';
import { searchEngine, dateFix } from '../../../utils/common';
import SearchGroup from '../../../components/SearchGroup';
export default function Whatif() {
  const bodyRef = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isConstraintModalVisible, setIsConstraintModalVisible] = useState(false);
  const [isWhatModelVisible, setIsWhatModelVisible] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const [plantype, setPlantype] = useState(1);
  const [env, setEnv] = useState('');
  const [constraint, setConstraint] = useState('');
  const [delivery, setDelivery] = useState(0); //交期优先
  const [cost, setCost] = useState(0); //成本最小化
  const [capacity, setCapacity] = useState(0); //产能利用率
  const [display, setDisplay] = useState('visible');
  const [refreshBtnTxt, setRefreshBtnTxt] = useState('刷新');
  const [refreshDataInterval, setRefreshDataInterval] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataCopy, setDataCopy] = useState([]);
  const [searchStatus, setSearchStatus] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [scenarioList, setScenarioList] = useState();
  const [logVisible, setLogVisible] = useState(false);
  const [logs, setLogs] = useState('');
  const [height, setHeight] = useState('');
  const [leadVisible, setLeadVisible] = useState(false);
  const [loadHtmlVal, setLoadHtmlVal] = useState();
  const [loadHtmlFlag, setloadHtmlFlag] = useState(true);

  //表头
  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
      fixed: 'left',
      width: 100,
    },
    {
      title: '类型',
      dataIndex: 'name',
      width: 200,
      render: (text) => {
        const type = {
          runplan: '执行排程',
        };
        return type[text] ? type[text] : '场景模拟';
      },
    },
    {
      title: '优化提交时间',
      dataIndex: 'submitted',
      width: 200,
      render: (text) => {
        return dateFix(text);
      },
    },
    {
      title: '优化结束时间',
      dataIndex: 'started',
      width: 200,
      render: (text) => {
        return dateFix(text);
      },
    },
    {
      title: '优化完成时间',
      dataIndex: 'finished',
      width: 200,
      render: (text) => {
        return dateFix(text);
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 120,
      render: (value, record) => {
        const status = {
          Waiting: '取消',
          Canceled: '取消',
          Done: '成功',
          Failed: '失败',
        };
        if (record.cancelable == 'True') {
          return (
            <button
              onClick={() => {
                cancelTask(record.id);
              }}
            >
              处理中
            </button>
          );
        } else {
          return <div>{status[value] ? status[value] : value}</div>;
        }
      },
    },
    {
      title: '日志文件',
      dataIndex: 'message',
      width: 120,
      render: (value, record) => {
        if (
          record.status === 'Failed' ||
          (record.status === 'Waiting' && record.message) ||
          record.status === 'Done' ||
          record.status === 'Canceled'
        ) {
          return (
            <a
              onClick={async () => {
                // console.log('view');
                setLogVisible(true);
                try {
                  const res = await getLogs(record.id);
                  setLogs(res.data.data);
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              view
            </a>
          );
        } else {
          return <div></div>;
        }
      },
    },
    {
      title: '排产规则',
      dataIndex: 'arguments',
      width: 200,
      render: (text) => {
        if (typeof text == 'undefined') return '约束瓶颈混合排';
        if (text.includes('plantype=2')) {
          return '无约束排程';
        } else if (text.includes('plantype=1')) {
          if (text.includes('supply1')) {
            return '有约束正排';
          } else if (text.includes('supply2')) {
            return '有约束倒排';
          } else {
            return '约束瓶颈混合排';
          }
        } else {
          return '约束瓶颈混合排';
        }
      },
    },
    {
      title: '用户',
      dataIndex: 'user',
      width: 120,
    },
  ];

  const cancelTask = (id) => {
    http.post(`execute/cancel/${id}/`, {}, function (response) {
      console.log(response, 'execute/cancel response');
      pageList();
    });
  };

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //创建计划
  const createPlan = () => {
    let params = {
      plantype: plantype,
      env: 'supply,supply' + env,
      weight: delivery + ',' + cost + ',' + capacity,
    };
    console.log(params);
    http.post('execute/launch/runplan/', params, function (response) {
      console.log(response);
      if (response.data.status === 'success') {
        pageList();
        refreshDataStart();
      } else {
        alert(response.data.status);
      }
    });
  };

  const getLogs = async (id) => {
    return http.get(`execute/logfrepplech/${id}/`, {});
  };

  const refreshDataStart = () => {
    setRefreshBtnTxt('自动刷新中');
    setRefreshDataInterval(
      setInterval(() => {
        pageList();
      }, 5000)
    );
  };

  const refreshDataEnd = () => {
    if (refreshDataInterval != null) {
      clearInterval(refreshDataInterval);
      setRefreshDataInterval(null);
    } else refreshDataStart();
  };

  const refreshData = () => {
    setRefreshBtnTxt('刷新');
    refreshDataEnd();
  };
  const pageList = () => {
    http.get(
      'execute/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'submitted',
        sord: 'desc',
      },
      function (response) {
        setData(response.data.rows);
        setDataCopy(response.data.rows);
        setSearchStatus('ready');
      }
    );
  };

  useEffect(() => {
    pageList();
    setHeight(getHeight());
  }, []);

  const sceneSimulation = () => {
    let source = [];
    http.post('scenario/launch/show_scenario/', {}, function (response) {
      console.log('alldata:', response.data.data.all_data);
      console.log('data:', response.data.data);
      console.log('status:', response.data.status);
      if (response.data.status === 'success') {
        //遍历获取列表数据
        setScenarioList(response.data.data); //把返回值保存
        response.data.data.all_data.map((item) => {
          source.push({
            //key: item[0].split,
            scene: item[0], //scene,
            action: '管理',
            condition: item[1], //sceStatu,
            tag: item[0],
            updatetime: item[2] == null ? '' : item[2],
          });
        });
        console.log('source:', source);
        setDataSource(source);
        console.log('setDataSource:', dataSource);
      } else {
        console.log(response.data.status);
      }
    });
  };

  // const dataSource = [
  //   {
  //     key: '1',
  //     scene: '默认',
  //     action: '管理',
  //     condition: '在用',
  //     tag: 'Production',
  //     updatetime: '2021-02-01 12:50:11',
  //   },
  //   {
  //     key: '2',
  //     scene: '场景1',
  //     action: '管理',
  //     condition: '空闲',
  //     tag: 'Production',
  //     updatetime: '2021-02-01 12:50:11',
  //   },
  //   {
  //     key: '3',
  //     scene: '场景2',
  //     action: '管理',
  //     condition: '忙碌',
  //     tag: 'Production',
  //     updatetime: '2021-02-01 12:50:11',
  //   },
  //   {
  //     key: '4',
  //     scene: '场景3',
  //     action: '管理',
  //     condition: '在用',
  //     tag: 'Production',
  //     updatetime: '2021-02-01 12:50:11',
  //   },
  // ];

  const whatcolume = [
    {
      title: '场景',
      dataIndex: 'scene',
      render: (text) => {
        if (text == 'scenario1') {
          return '场景1';
        } else if (text == 'scenario2') {
          return '场景2';
        } else if (text == 'scenario3') {
          return '场景3';
        } else if (text == 'default') {
          return '默认';
        }
      },
    },
    {
      title: '动作',
      dataIndex: 'action',
      render: (text, record, index) => {
        // if (index == 0) {
        //   return <lable>{text}</lable>;
        // } else {
        //if (scenarioList.current_database)
        console.log('record:', record);
        if (scenarioList.free_scenarios.includes(record.scene)) {
          return (
            <Dropdown
              arrow={true}
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="text"
                      onClick={() => {
                        let params = {
                          copy: index, //1,
                          source: 'default',
                          destination: 'scenario' + index,
                        };
                        console.log(params);
                        http.post('execute/launch/scenario_copy/', params, function (response) {
                          console.log(response);
                          if (response.data.status === 'success') {
                            console.log('拷贝成功');
                            setIsWhatModelVisible(false);
                          } else {
                            console.log(response.data.status);
                          }
                        });
                      }}
                    >
                      创建
                    </Button>
                  </Menu.Item>
                </Menu>
              }
            >
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                {text}
              </a>
            </Dropdown>
          );
        } else if (scenarioList.current_database.includes(record.scene)) {
          return (
            <Dropdown
              arrow={true}
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="text"
                      onClick={() => {
                        let params = {
                          release: index, //1,
                          database: record.tag,
                          // source: record.tag,
                          // destination: record.tag,
                        };
                        console.log(params);
                        http.post('execute/launch/scenario_copy/', params, function (response) {
                          console.log(response);
                          if (response.data.status === 'success') {
                            console.log('发布成功');
                            setIsWhatModelVisible(false);
                          } else {
                            console.log(response.data.status);
                          }
                        });
                      }}
                    >
                      清除
                    </Button>
                  </Menu.Item>

                  <Menu.Item>
                    <Button
                      type="text"
                      onClick={() => {
                        let params = {
                          promote: index, //1,
                          source: record.tag,
                          destination: 'default',
                        };
                        console.log(params);
                        http.post('execute/launch/scenario_copy/', params, function (response) {
                          console.log(response);
                          if (response.data.status === 'success') {
                            console.log('覆盖成功');
                            setIsWhatModelVisible(false);
                          } else {
                            console.log(response.data.status);
                          }
                        });
                      }}
                    >
                      覆盖
                    </Button>
                  </Menu.Item>
                </Menu>
              }
            >
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                {text}
              </a>
            </Dropdown>
          );
        } else if (
          !scenarioList.current_database.includes(record.scene) &&
          !scenarioList.free_scenarios.includes(record.scene)
        ) {
          return (
            <Dropdown
              arrow={true}
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      type="text"
                      onClick={() => {
                        let params = {
                          release: index, //1,
                          database: record.tag,
                          // source: record.tag,
                          // destination: record.tag,
                        };
                        console.log(params);
                        http.post('execute/launch/scenario_copy/', params, function (response) {
                          console.log(response);
                          if (response.data.status === 'success') {
                            console.log('发布成功');
                            setIsWhatModelVisible(false);
                          } else {
                            console.log(response.data.status);
                          }
                        });
                      }}
                    >
                      清除
                    </Button>
                  </Menu.Item>
                </Menu>
              }
            >
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                {text}
              </a>
            </Dropdown>
          );
          //}
        }
      },
    },
    {
      title: '状态',
      dataIndex: 'condition',
      width: '200px',
      render: (text) => {
        if (text == 'Free') {
          return '空闲';
        } else if (text == 'Busy') {
          return '忙碌';
        } else if (text == 'In use') {
          return '在用';
        }
      },
    },
    {
      title: '标签',
      dataIndex: 'tag',
    },
    {
      title: '更新时间',
      dataIndex: 'updatetime',
    },
  ];
  const fileKeys = [
    'APS导入模板',
    '产业APS导入模板',
    '南康产业APS导入模板',
    '南康家具产业APS导入模板',
    '木材APS导入模板',
    '设计APS导入模板',
  ];
  // 上传文件
  const url = '/api/execute/launch/importworkbook/';
  const props = {
    name: 'file',
    action: url,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    headers: {
      authorization: 'authorization-text',
      // content_type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      // application/vnd.openxmlformats-officedocument.wordprocessingml.document
    },
    onChange(info) {
      console.log(info, 'upload');
      // 临时处理方案（裕福）
      const flag = fileKeys.some((key) => key == info.file.name.split('.')[0]);
      if (!flag) {
        message.destroy();
        message.error(`导入模板不对`);
        setloadHtmlFlag(false);
        return false;
      }
      setloadHtmlFlag(true);
      setIsLoading(true);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        setIsLoading(false);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} 文件上传成功`);
        setIsLoading(false);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} 文件上传失败.`);
        setIsLoading(false);
      }
    },
    onSuccess(obj) {
      // console.log('obj--------',obj);
      if (obj.status === 'success') {
        setLoadHtmlVal(obj.data);
        setIsLoading(false);
      }
    },
  };
  const loadHtml = () => {
    // console.log('loadHtmlVal--------',loadHtmlVal)
    if (loadHtmlFlag) {
      return <div dangerouslySetInnerHTML={{ __html: loadHtmlVal }}></div>;
    }
  };
  const onSearch = (keys) => {
    console.log('onSearch');
    if (keys.every((key) => !key.value)) {
      pageList();
    } else {
      const arr = searchEngine(dataCopy, keys);
      setData(arr);
    }
  };

  const getHeight = () => {
    // console.log(bodyRef.current.offsetHeight, 'offsetHeight');
    return bodyRef.current?.offsetHeight - 100;
  };

  return (
    <div className="whatif modal-list">
      {isLoading && <Spin className="loading" tip="Loading..."></Spin>}
      <Modal
        title="日志文件"
        centered
        maskClosable={false}
        visible={logVisible}
        onOk={() => setLogVisible(false)}
        onCancel={() => setLogVisible(false)}
        width={1000}
      >
        <div className="log">{logs}</div>
      </Modal>
      <Modal
        title={content.title}
        visible={isConstraintModalVisible}
        maskClosable={false}
        okText="排产调度规则"
        cancelText="取消"
        onOk={() => {
          //if (type === 'add') {
          createPlan();
          //}
          setIsConstraintModalVisible(false);
        }}
        onCancel={() => {
          setIsConstraintModalVisible(false);
        }}
        height={400}
      >
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
          <div className="constraintVisible" style={{ visibility: display }}>
            {/* style={{"display":display}} */}
            <Form.Item label="排产方向">
              <Radio.Group
                onChange={(e) => {
                  setEnv(e.target.value);
                }}
                value={env}
              >
                <Radio value={1} checked={true}>
                  正排
                </Radio>
                <Radio value={2}>倒排</Radio>
                <Radio value={''}>混合/瓶颈排</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="优化级管理">
              {/* <Input
              value={constraint}
              onChange={(e) => {
                setConstraint(e.target.value);
              }}
            /> */}
            </Form.Item>
            <Form.Item label="交期优先：">
              <Slider
                defaultValue={30}
                onChange={(value) => {
                  setDelivery(value);
                }}
                value={delivery}
              />
            </Form.Item>
            <Form.Item label="成本最小化：">
              <Slider
                defaultValue={30}
                value={cost}
                onChange={(value) => {
                  setCost(value);
                }}
              />
            </Form.Item>
            <Form.Item label="产能利用率优先：">
              <Slider
                defaultValue={30}
                value={capacity}
                onChange={(value) => {
                  setCapacity(value);
                }}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Modal
        title={content.title}
        visible={isModalVisible}
        maskClosable={false}
        okText="排产调度规则"
        cancelText="取消"
        onOk={() => {
          //if (type === 'add') {
          createPlan();
          //}
          setIsModalVisible(false);
        }}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        height={400}
      >
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} layout="horizontal">
          <Form.Item label="计划类型">
            <Radio.Group
              value={plantype}
              onChange={(e) => {
                setPlantype(e.target.value);
                console.log(plantype);
                //点击无约束的时候
                if (plantype == 1) {
                  setDisplay('hidden');
                } else {
                  setDisplay('visible');
                }
              }}
            >
              {/* <Radio value={1}>
                有约束条件
              </Radio> */}
              <Radio value={2}>无约束条件</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={'场景模拟'}
        visible={isWhatModelVisible}
        maskClosable={false}
        closable={true}
        footer={null}
        onCancel={() => {
          setIsWhatModelVisible(false);
        }}
        width={800}
      >
        <Table dataSource={dataSource} columns={whatcolume} pagination={false} bordered />
      </Modal>
      <div className="layout-content">
        <div className="content-header">
          <BreadNav />
          <div>
            <SearchGroup
              items={[
                {
                  label: '编号',
                  name: 'id',
                },
              ]}
              onSearch={onSearch}
            ></SearchGroup>
          </div>
          <div className="buttons-wrapper">
            <div className="button">
              {/* <Upload showUploadList={false} class="upload-button" {...props}> */}
              <Button
                type="primary"
                shape="round"
                size="middle"
                onClick={() => {
                  // setIsModalVisible(true);
                  setLeadVisible(true);
                }}
              >
                &nbsp;+&nbsp;导入
              </Button>
              <Modal
                title="导入"
                visible={leadVisible}
                maskClosable={true}
                footer={null}
                width={700}
                onCancel={(e) => {
                  setLeadVisible(false);
                  setLoadHtmlVal('');
                }}
              >
                <div style={{ margin: '0px 30px' }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      let a = document.createElement('a');
                      a.href = 'https://mes.cdn.nk001.com/test/apstest/APS导入模板.xlsx';
                      a.style.display = 'none';
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }}
                    size="middle"
                    style={{ marginRight: '20px', marginBottom: '20px' }}
                  >
                    下载模板
                  </Button>
                  <Upload showUploadList={false} class="upload-button" {...props}>
                    <Button type="primary" size="middle">
                      导入数据
                    </Button>
                  </Upload>
                  {loadHtml()}
                </div>
              </Modal>
              {/* </Upload> */}
            </div>
            <Button
              type="primary"
              className="button"
              shape="round"
              size="middle"
              onClick={() => {
                setOptType('add');
                setContent({ title: '排产调度规则' });
                setPlantype(1);
                setIsConstraintModalVisible(true);
              }}
            >
              &nbsp;+&nbsp;创建有约束排产方法
            </Button>
            <Button
              type="primary"
              className="button"
              shape="round"
              size="middle"
              onClick={() => {
                setOptType('add');
                setContent({ title: '排产调度规则' });
                setPlantype(2);
                setIsModalVisible(true);
              }}
            >
              &nbsp;+&nbsp;创建无约束排产方法
            </Button>
            <Button
              type="primary"
              className="button"
              shape="round"
              size="middle"
              danger
              onClick={() => {
                setIsWhatModelVisible(true);
                sceneSimulation();
              }}
            >
              场景模拟
            </Button>
            <Button
              type="primary"
              className="button"
              shape="round"
              size="middle"
              onClick={() => {
                refreshData();
              }}
            >
              {refreshBtnTxt}
            </Button>
          </div>
        </div>
        <div className="content-body" ref={bodyRef}>
          {height && (
            <Table
              size="small"
              // rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              rowKey={(record) => record.id}
              bordered
              pagination={{
                pageSize: 15,
              }}
              scroll={{ y: height }}
              scrollToFirstRowOnChange={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}
