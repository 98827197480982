import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { Table } from 'antd';
import BreadNav from '../../../components/breadNav';
import SearchGroup from '../../../components/SearchGroup';
import http from '../../../http';
import { searchEngine, getDomHeight } from '../../../utils/common';
import './index.scss';
export default class ResourceReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      at: 0,
      size: 10,
      data: [],
      dataCopy: [],
    };
    this.columns = [
      {
        title: '资源',
        dataIndex: 'resource',
        key: 'resource',
        width: 150,
      },
      {
        title: '地点',
        dataIndex: 'location__name',
        key: 'location__name',
        width: 150,
      },
      {
        title: '使用率',
        dataIndex: 'avgutil',
        key: 'avgutil',
        width: 80,
        render: (text) => {
          return `${text}%`;
        },
      },
      {
        title: '图表',
        align: 'center',
        key: 'graph',
        render: () => <div className={`echart`} style={{ height: '180px' }}></div>,
      },
    ];
  }
  //获取资源数据
  itemList = () => {
    http
      .get('resource/', {
        mode: 'graph',
        format: 'json',
        _search: false,
        rows: 100,
        page: 1,
        sidx: 'resource',
        sord: 'asc',
      })
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              data: res.data.rows,
            },
            () => {
              this.cellChart();
              this.setDataCopy(res.data.rows);
            }
          );
        }
      });
  };

  setDataCopy = (data) => {
    this.setState({
      dataCopy: data,
    });
  };
  //  绘制图表
  paintChart = (ele, data) => {
    let myChart = echarts.init(ele);
    let option;
    let { dates, available, load, unavailable, changeLine } = data;
    option = {
      tooltip: {
        show: true,
        trigger: 'axis',
        formatter: function (params) {
          let index = params[0].dataIndex;
          let date = params[0].axisValue;
          //  处理没有date情况乱码问题
          if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
            let res = `<div>
                     <div>${date}</div>
                     可用     ${available[index].toFixed(2)}</br>
                     不可用    ${unavailable[index].toFixed(2)}</br>
                     负荷     ${load[index].toFixed(2)}</div>`;
            return res;
          } else return '';
        },
      },
      grid: {
        top: '40px',
        left: '30px',
        right: '60px',
        bottom: '15px',
        containLabel: true,
      },
      xAxis: {
        name: '日期',
        type: 'category',
        data: dates,
        // show: false,
        axisLabel: {
          interval: 16, //设置间隔为0
          showMaxLabel: true,
        },
      },
      yAxis: {
        name: '数量',
        type: 'value',
        splitLine: { show: false },
        splitNumber: 1,
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
      },
      legend: {
        data: ['可用', '负荷'],
      },
      series: [
        {
          name: '可用',
          data: available,
          type: 'line',
          symbol: 'none',
        },
        {
          name: '负荷',
          data: load,
          barWidth: '50%',
          type: 'bar',
          symbol: 'none',
        },
      ],
    };

    option && myChart.setOption(option);
  };

  //  处理数据
  handleData = (data) => {
    let dates = [];
    let available = [];
    let load = [];
    let unavailable = [];
    let changeLine = [];
    for (let k in data) {
      if (parseInt(k)) {
        dates.push(k);
        available.push(data[k][0]);
        unavailable.push(data[k][1]);
        changeLine.push(data[k][2]);
        load.push(data[k][3]);
      }
    }
    return { dates, available, unavailable, changeLine, load };
  };

  //单元格中生成图表
  cellChart = () => {
    let { at, size, data } = this.state;
    if (!data) return;
    let containers = document.querySelectorAll('div.echart');
    let arr = data.slice(at, at + size);
    let _this = this;

    if (containers[0]) {
      arr.forEach((item, index) => {
        let finalData = this.handleData(item);
        let container = containers[index];
        setTimeout(function () {
          _this.paintChart(container, finalData);
        });
      });
    }
  };

  componentDidMount() {
    this.itemList();
  }

  componentDidUpdate() {
    this.cellChart();
  }

  render() {
    let { data } = this.state;
    const onSearch = (keys) => {
      console.log('onSearch');
      if (keys.every((key) => !key.value)) {
        this.itemList();
      } else {
        const arr = searchEngine(this.state.dataCopy, keys);
        this.setState({
          data: arr,
        });
      }
    };
    return (
      <div className="layout-content">
        <div className="content-header">
          <div className="capacity-report">
            <BreadNav />
            <SearchGroup
              items={[
                {
                  label: '资源',
                  name: 'resource',
                },
                {
                  label: '地点',
                  name: 'location__name',
                },
              ]}
              onSearch={onSearch}
            ></SearchGroup>
            <br />
          </div>
        </div>
        <div className="content-body">
          <Table
            size="small"
            style={{
              height: getDomHeight(),
            }}
            scroll={{
              y: getDomHeight(),
              // x: '100vw',
            }}
            columns={this.columns}
            dataSource={data}
            rowKey={(record) => {
              return record.resource;
            }}
            onChange={(pagination, filters, sorter, extra) => {
              if (extra.action === 'paginate') {
                this.setState({
                  at: (pagination.current - 1) * pagination.pageSize,
                });
              }
            }}
            bordered
          />
        </div>
      </div>
    );
  }
}
