import http from '../http';
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
export const getAuthMenus = (id) => {
  const url = `/nkapi/menus/${id}`;
  return http.customGet(url).then((res) => {
    if (res.data.Success) {
      return res.data.Data.menus;
    } else {
      <Redirect to="/" />;
    }
  });
};
