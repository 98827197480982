import React, { useState, useEffect } from 'react';
import './index.scss';
import $ from 'jquery';
import lbx from './picture/lbx.png';
import jt from './picture/jt.png';
import map from './picture/map.png';
import html from './js/html.js';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { launchFullscreen, exitFullscreen } from '../../../utils/common';
import CenterChart from './chart';
import Echarts from '../../../components/Echarts';

export default function Purchaseorder() {
  useEffect(() => {
    var whei = $(window).width();
    $('html').css({ fontSize: whei / 20 });
    $(window).resize(function () {
      var whei = $(window).width();
      $('html').css({ fontSize: whei / 20 });
    });
  }, []);

  const [fullScreenState, setFullScreenState] = useState('0');
  const renderFullScreenBtn = () => {
    // 全屏
    if (fullScreenState === '1') {
      return (
        <FullscreenExitOutlined
          className="fullscreen-btn"
          onClick={() => {
            exitFullscreen();
            setFullScreenState('0');
          }}
        />
      );
    } else {
      // 退出全屏
      return (
        <FullscreenOutlined
          className="fullscreen-btn"
          onClick={() => {
            launchFullscreen(document.getElementById('cockpit'));
            setFullScreenState('1');
          }}
        />
      );
    }
  };
  const indicatorOption = {
    title: {
      text: '',
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎'],
      textStyle: {
        color: '#ffffff', //字体颜色
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: '邮件营销',
        type: 'line',
        stack: '总量',
        data: [120, 132, 101, 134, 90, 230, 210],
      },
      {
        name: '联盟广告',
        type: 'line',
        stack: '总量',
        data: [220, 182, 191, 234, 290, 330, 310],
      },
      {
        name: '视频广告',
        type: 'line',
        stack: '总量',
        data: [150, 232, 201, 154, 190, 330, 410],
      },
      {
        name: '直接访问',
        type: 'line',
        stack: '总量',
        data: [320, 332, 301, 334, 390, 330, 320],
      },
      {
        name: '搜索引擎',
        type: 'line',
        stack: '总量',
        data: [820, 932, 901, 934, 1290, 1330, 1320],
      },
    ],
  };

  return (
    <div id="cockpit" className="body" style={{ width: '100%', height: '100%' }}>
      <div className="canvas" style={{ opacity: 0.4 }}>
        <iframe frameborder="0" srcDoc={html} style={{ width: '100%', height: '100%' }}></iframe>
      </div>
      <div className="head">
        <h1>产业群智大脑调度监控平台</h1>
        {renderFullScreenBtn()}
      </div>
      <div className="mainbox">
        <ul className="clearfix" style={{ width: '100%' }}>
          <li>
            <div className="boxall" style={{ height: '3.2rem' }}>
              <div className="alltitle">模块标题样式</div>
              <div className="allnav" id="echart1"></div>
              <div className="boxfoot"></div>
            </div>
            <div className="boxall" style={{ height: '3.2rem' }}>
              <div className="alltitle">模块标题样式</div>
              <div className="allnav" id="echart2"></div>
              <div className="boxfoot"></div>
            </div>
            <div className="boxall" style={{ height: '3.2rem' }}>
              <div style={{ height: '100%', width: '100%' }}>
                <div className="sy" id="fb1"></div>
                <div className="sy" id="fb2"></div>
                <div className="sy" id="fb3"></div>
              </div>
              <div className="boxfoot"></div>
            </div>
          </li>
          <li>
            {/* <div class="bar">
              <div class="barbox">
                <ul class="clearfix">
                  <li class="pulll_left counter">12581189</li>
                  <li class="pulll_left counter">3912410</li>
                </ul>
              </div>
              <div class="barbox2">
                <ul class="clearfix">
                  <li class="pulll_left">2018年总收入情况 </li>
                  <li class="pulll_left">2018年总支出情况</li>
                </ul>
              </div>
            </div> */}
            <div className="map">
              <div className="map1">
                <img src={lbx} />
              </div>
              <div className="map2">
                <img src={jt} />
              </div>
              <div className="map3">
                <img src={map} />
              </div>
              <div className="map4" id="map_1">
                {/* <CenterChart chartId="cockpit_chart"></CenterChart> */}
              </div>
            </div>
          </li>
          <li>
            <div className="boxall" style={{ width: '5rem', height: '3.4rem' }}>
              <div className="alltitle">模块标题样式</div>
              <div className="allnav" id="echart4">
                <Echarts chartId="indicator_chart" option={indicatorOption}></Echarts>
              </div>
              <div className="boxfoot"></div>
            </div>
            <div className="boxall" style={{ width: '5rem', height: '3.2rem' }}>
              <div className="alltitle">模块标题样式</div>
              <div className="allnav" id="echart5"></div>
              <div className="boxfoot"></div>
            </div>
            <div className="boxall" style={{ width: '5rem', height: '3rem' }}>
              <div className="alltitle">模块标题样式</div>
              <div className="allnav" id="echart6"></div>
              <div className="boxfoot"></div>
            </div>
          </li>
        </ul>
      </div>
      <div className="back"></div>
    </div>
  );
}
