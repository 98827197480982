import React, { useState, useEffect } from 'react';
import './index.scss';
import { Card } from 'antd';
import menus from '../../components/layout/menus';
import { Link } from 'react-router-dom';
import IconFont from '../../components/IconFont';

export default function Purchaseorder() {
  useEffect(() => {}, []);
  return (
    <div>
      {menus.map((item) => {
        return (
          <Card key={item.key} title={item.title} style={{ width: '100%', margin: '20px 0' }}>
            <div className="menu-wrapper">
              {item.children.map((child) => (
                <Link
                  className="menu-item"
                  to={child.to}
                  key={child.to}
                  style={{ color: '#8b8b8b' }}
                >
                  <IconFont
                    className="icon-font"
                    type={child.icon}
                    style={{ color: 'rgba(28, 106, 235, 1)' }}
                  />
                  {child.title}
                </Link>
              ))}
            </div>
          </Card>
        );
      })}
    </div>
  );
}
