import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';
//import locale from 'antd/es/date-picker/locale/zh_CN';
import locale from 'antd/lib/locale/zh_CN';
import http from '../../../http';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  message,
  DatePicker,
  Descriptions,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
import { dateFix, isCanDelete, getDomHeight } from '../../../utils/common';
import BreadNav from '../../../components/breadNav';
import SearchGroup from '../../../components/SearchGroup';
import { searchEngine, getSelectOptions, demandsDataAdapter } from '../../../utils/common';
import { useLocation } from 'react-router-dom';
const { confirm } = Modal;

export default function Purchaseorder() {
  const location = useLocation();
  const formRef = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleInfo, setIsModalVisibleInfo] = useState(false);
  const [isModalVisibleInfo2, setIsModalVisibleInfo2] = useState(false);
  const [optType, setOptType] = useState();
  const [data, setData] = useState([]);
  const [content, setContent] = useState({ title: '' });
  const dateFormat = 'YYYY-MM-DD HH:mm';
  const format = 'HH:mm';

  const [product, setProduct] = useState([]);
  const [factory, setFactory] = useState([]);
  const [applier, setApplier] = useState([]);
  const [dataCopy, setDataCopy] = useState([]);
  const [itemStatus, setItemStatus] = useState([
    { key: 'proposed', value: '已提议' },
    { key: 'completed', value: '已采用' },
    { key: 'closed', value: '不采用' },
    { key: 'approved', value: '协商' },
  ]);
  const [itemStatusValue, setItemStatusValue] = useState();

  //人员信息
  const [addr, setAddr] = useState();
  const [contactMobile, setContactMobile] = useState();
  const [contactName, setContactName] = useState();
  const [name, setName] = useState();
  //物流信息
  const [logisticsInfo, setLogisticsInfo] = useState([]);

  //物流信息
  const columnsInfo = [
    {
      title: '公司',
      width: 200,
      fixed: 'left',
      dataIndex: 'createdBy',
    },
    {
      title: '姓名',
      width: 150,
      fixed: 'left',
      dataIndex: 'responser',
    },
    {
      title: '手机号',
      width: 200,
      fixed: 'left',
      dataIndex: 'responserTel',
    },
  ];
  //表头
  const columns = [
    {
      title: '编号',
      width: 100,
      fixed: 'left',
      dataIndex: 'reference',
      sorter: (a, b) => parseInt(a.reference) - parseInt(b.reference),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '库存状态',
      width: 100,
      dataIndex: 'computed_color',
      render: (value) => {
        if (parseInt(value) > 0) {
          return <span>提前</span>;
        } else if (parseInt(value) < 0) {
          return <span>延迟</span>;
        } else {
          return <span>准时</span>;
        }
      },
    },
    {
      title: '产品',
      width: 200,
      dataIndex: 'item',
      sorter: (a, b) => String(a.item).length - String(b.item).length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '收货地',
      width: 200,
      dataIndex: 'destination',
      sorter: (a, b) => String(a.destination).length - String(b.destination).length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '发货地',
      width: 200,
      dataIndex: 'origin',
      sorter: (a, b) => String(a.origin).length - String(b.origin).length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '发货时间',
      width: 200,
      dataIndex: 'startdate',
      sorter: (a, b) => moment(a.startdate) - moment(b.startdate),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '收货时间',
      width: 200,
      dataIndex: 'enddate',
      sorter: (a, b) => moment(a.enddate) - moment(b.enddate),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '数量',
      width: 100,
      dataIndex: 'quantity',
      render: (value) => {
        return <span>{parseInt(value)}</span>;
      },
    },
    {
      title: '需求',
      width: 200,
      dataIndex: 'require',
    },
    {
      title: '计划状态',
      width: 150,
      dataIndex: 'status',
      render: (value) => {
        switch (value) {
          case 'proposed':
            return '已提议';
          case 'completed':
            return '已采用';
          case 'closed':
            return '不采用';
          case 'approved':
            return '协商';
          default:
            return '';
        }
      },
      sorter: (a, b) => String(a.status).length - String(b.status).length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '更新时间',
      width: 200,
      dataIndex: 'lastmodified',
      sorter: (a, b) => moment(a.lastmodified) - moment(b.lastmodified),
      render: (text) => {
        return dateFix(text);
      },
    },
    {
      title: '操作',
      width: 160,
      fixed: 'right',
      dataIndex: '',
      render: (text) => (
        <div>
          <Button
            type="link"
            onClick={() => {
              setEditValue(text, 'edit');
            }}
          >
            编辑
          </Button>
          <Button
            type="text"
            danger
            name={text.reference}
            onClick={(e) => {
              // if (text.status === 'completed') {
              //   message.destroy();
              //   message.error('已采用状态的数据不可删除');
              //   return false;
              // }
              setOptType('delete');
              showDeleteConfirm('delete', text.reference);
            }}
          >
            删除
          </Button>
          {text.status == 'completed' && text.source ? (
            <Button type="link" onClick={() => setInfo(text, 'clientele')}>
              客户
            </Button>
          ) : (
            ''
          )}
          {text.status == 'completed' && text.source ? (
            <Button type="link" onClick={() => setInfo(text, 'logistics')}>
              物流
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
    },
  ];

  const setEditValue = (item, type) => {
    console.log('setEditValue');
    setOptType(type);
    setIsModalVisible(true);
    setContent({ title: type === 'add' ? '新增物料计划' : '物料计划调整' });
    item = JSON.parse(JSON.stringify(item));
    item.startdate = item.startdate ? moment(item.startdate, dateFormat) : '';
    item.enddate = item.enddate ? moment(item.enddate, dateFormat) : '';
    formRef.current.setFieldsValue(item);
  };
  //查看客户和物流信息
  const setInfo = (text, info) => {
    if (info === 'clientele') {
      //客户
      setIsModalVisibleInfo(true);
      http.customGet(`/nkapi/center/company/${text.source}`).then((res) => {
        if (res.data) {
          const { addr, contactMobile, contactName, name } = res.data.Data;
          setAddr(addr);
          setContactMobile(contactMobile);
          setContactName(contactName);
          setName(name);
        }
      });
    } else if (info === 'logistics') {
      //物流
      setIsModalVisibleInfo2(true);
      http.customGet(`/nkapi/mes/logistics/waybillCode/${text.batch}`).then((res) => {
        if (res.data) {
          const { Data } = res.data;
          setLogisticsInfo(Data);
        }
      });
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showDeleteConfirm = (type, reference) => {
    // 已采用数据不可删除
    const arr = type === 'delete' ? [reference] : selectedRowKeys;
    if (isCanDelete(dataCopy, arr, 'reference')) {
      return false;
    }
    confirm({
      title: '您确定要删除所选吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        if (type === 'delete') {
          deleteItem(reference);
        } else if (type === 'deleteBatch') {
          deleteBatch();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDateParams = (startDate, endDate) => {
    const _dateFormat = 'YYYY-MM-DD';
    const _format = 'HH:mm';
    return {
      // startdate: moment(startdate).format(_dateFormat),
      // enddate: moment(enddate).format(_dateFormat),
      shipping_date_0: moment(startDate).format(_dateFormat),
      shipping_date_1: moment(startDate).format(_format),
      receipt_date_0: moment(endDate).format(_dateFormat),
      receipt_date_1: moment(endDate).format(_format),
    };
  };

  //删除
  const deleteItem = (name) => {
    //console.log('deleteName: ', deleteName);
    http.post(
      `data/input/distributionorder/${name}/delete/`,
      {
        csrfmiddlewaretoken: 'MW7f6IttOzdYel8GBRm86pF5SC0MyXx3JWaYeKn7HD9J2xt578OSfeHmExjBL9Km',
        post: 'yes',
      },
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
          alert(response.data.status);
        }
      }
    );
  };
  //批量删除
  const deleteBatch = () => {
    http.postJSON(
      `data/input/distributionorder/`,
      [{ delete: selectedRowKeys }],
      function (response) {
        if (response.data.status === 'success') {
          message.success('操作成功！');
          pageList();
          setSelectedRowKeys([]); //批量删除时重置setSelectedRowKeys
        } else {
          message.error('操作失败！');
        }
      }
    );
  };

  const addItem = () => {
    console.log('addItem');
    const item = formRef.current.getFieldsValue();
    http.post(
      'data/input/distributionorder/add/',
      {
        _save: '保存',
        reference: item.reference,
        item: item.item,
        origin: item.origin,
        destination: item.destination,
        quantity: item.quantity,
        status: item.status,
        ...handleDateParams(item.startdate, item.enddate),
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('请填写完整信息！');
        }
      }
    );
  };

  const editItem = () => {
    console.log('editItem');
    const item = formRef.current.getFieldsValue();
    http.post(
      `detail/input/distributionorder/${item.reference}/`,
      {
        csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        _save: '保存',
        reference: item.reference,
        item: item.item,
        origin: item.origin,
        destination: item.destination,
        quantity: item.quantity,
        status: item.status,
        ...handleDateParams(item.startdate, item.enddate),
      },
      function (response) {
        if (response.data.status === 'success') {
          setIsModalVisible(false);
          message.success('操作成功！');
          pageList();
        } else {
          message.error('操作失败！');
        }
      }
    );
  };

  const pageList = (initFlag) => {
    console.log('pageList');
    http.get(
      'data/input/distributionorder/',
      {
        format: 'json',
        _search: false,
        rows: 1000,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        const res = demandsDataAdapter(response.data.rows);
        setData(res);
        setDataCopy(res);
        if (initFlag) {
          const params = location.params || {};
          onSearch(
            [
              {
                key: 'reference',
                value: params.reference,
              },
            ],
            res
          );
        }
      }
    );
  };

  const handleOk = () => {
    console.log(formRef.current.getFieldsValue(), 'handleOk');
    if (optType === 'add') {
      addItem();
    } else if (optType === 'edit') {
      editItem();
    }
  };

  const saveOrders = async (items, status) => {
    const params = items.map((item) => {
      return {
        id: item,
        status,
      };
    });
    const url = `data/input/distributionorder/`;
    return http.post(url, params);
  };

  const onSearch = (keys, list) => {
    list = list || dataCopy;
    if (keys.every((key) => !key.value)) {
      pageList();
    } else {
      const arr = searchEngine(list, keys);
      setData(arr);
    }
  };

  useEffect(() => {
    pageList('init');
    productlist();
    locationlist();
    applierlist();
  }, []);

  /**产品列表 */
  const productlist = () => {
    http.get(
      'data/input/item/',
      {
        format: 'json',
        filters: {
          groupOp: 'AND',
          rules: [
            { field: 'owner', op: 'ne', data: '全部' },
            { field: 'name', op: 'ne', data: '全部' },
            { field: 'name', op: 'ne', data: 'All items', filtercount: 0 },
          ],
        },
      },
      function (response) {
        setProduct(response.data.rows);
      }
    );
  };

  /**工厂列表 */
  const locationlist = () => {
    http.get(
      'data/input/location/',
      {
        format: 'json',
        _search: false,
        rows: 10,
        page: 1,
        sidx: 'lastmodified',
        sord: 'desc',
      },
      function (response) {
        setFactory(response.data.rows);
      }
    );
  };

  /**供应商列表 */
  const applierlist = () => {
    http.get(
      'data/input/supplier/',
      {
        format: 'json',
      },
      function (response) {
        setApplier(response.data.rows);
      }
    );
  };
  //表单提交
  const handleSubmit = () => {
    if (type === 'add') {
      this.addItem();
    } else if (type === 'edit') {
      this.editItem();
    }
  };

  return (
    <div className="purchaseorder modal-list">
      <Modal
        title="客户信息"
        visible={isModalVisibleInfo}
        maskClosable={false}
        onCancel={() => {
          setIsModalVisibleInfo(false);
        }}
        footer={null}
      >
        <Descriptions column={1}>
          <Descriptions.Item label="地址">{addr}</Descriptions.Item>
          <Descriptions.Item label="电话">{contactMobile}</Descriptions.Item>
          <Descriptions.Item label="姓名">{contactName}</Descriptions.Item>
          <Descriptions.Item label="企业">{name}</Descriptions.Item>
        </Descriptions>
      </Modal>

      <Modal
        title="物流信息"
        visible={isModalVisibleInfo2}
        maskClosable={false}
        onCancel={() => {
          setIsModalVisibleInfo2(false);
        }}
        footer={null}
      >
        <Descriptions column={1}>
          <Descriptions.Item>
            <Table size="small" columns={columnsInfo} dataSource={logisticsInfo} bordered />
          </Descriptions.Item>
        </Descriptions>
      </Modal>

      <Modal
        title={content.title}
        visible={isModalVisible}
        maskClosable={false}
        forceRender={true}
        okText="提交"
        cancelText="取消"
        onOk={handleOk}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <Form
          ref={formRef}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          // onValuesChange={(changedValues, values) => {
          //   this.setState({
          //     ...values,
          //   });
          // }}
          onFinish={handleSubmit}
        >
          {/* 编号新增可编辑，修改不可编辑 */}
          <Form.Item label="编号" name="reference">
            <Input disabled={optType === 'edit'} />
          </Form.Item>
          <Form.Item label="产品" name="item">
            <Select showSearch allowClear={true}>
              {product.map((item) => (
                <Select.Option value={item.name} key={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="收货地" name="destination">
            <Select showSearch allowClear={true}>
              {factory.map((item) => (
                <Select.Option value={item.name} key={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="发货地" name="origin">
            <Select showSearch allowClear={true}>
              {applier.map((item) => (
                <Select.Option value={item.name} key={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="数量" name="quantity">
            <InputNumber />
          </Form.Item>
          <Form.Item label="状态" name="status">
            <Select allowClear={true}>
              {itemStatus.map((item) => {
                return <Select.Option value={item.key}>{item.value}</Select.Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item label="发货时间" name="startdate">
            <DatePicker format={dateFormat} showTime={{ defaultValue: moment('00:00', format) }} />
          </Form.Item>
          <Form.Item label="收货时间" name="enddate">
            <DatePicker format={dateFormat} showTime={{ defaultValue: moment('00:00', format) }} />
          </Form.Item>
        </Form>
      </Modal>
      <div className="layout-content">
        <div className="content-header">
          <div className="capacity-report">
            <BreadNav />
            <SearchGroup
              items={[
                {
                  label: '产品：',
                  name: 'item',
                },
                {
                  label: '编号',
                  name: 'reference',
                },
                {
                  label: '需求',
                  name: 'require',
                  type: 'select',
                  className: 'requireClass',
                  options: getSelectOptions(dataCopy, 'require'),
                },
                {
                  label: '状态',
                  name: 'status',
                  type: 'select',
                  options: getSelectOptions(itemStatus, 'key'),
                },
              ]}
              onSearch={onSearch}
            ></SearchGroup>
            <br />
            <Button
              type="primary"
              shape="round"
              size="middle"
              style={{ marginRight: 20 }}
              onClick={() => {
                setEditValue(
                  {
                    reference: '',
                    item: '',
                    destination: '',
                    origin: '',
                    quantity: '',
                    status: '',
                    startdate: '',
                    enddate: '',
                  },
                  'add'
                );
              }}
            >
              新建
            </Button>
            <Select
              style={{ width: 200 }}
              placeholder="状态修改"
              allowClear
              value={itemStatusValue}
              onSelect={(value) => {
                console.log(value, '=====');
                setItemStatusValue(value);
                selectedRowKeys.forEach((name) => {
                  data.forEach((target) => {
                    if (target.reference == name) {
                      target.status = value;
                    }
                  });
                });
                setData(data);
              }}
              onClear={() => {
                setItemStatusValue('');
              }}
            >
              {itemStatus.map((item) => {
                // TODO:
                return (
                  <Select.Option value={item.key} key={item.key}>
                    {item.value}
                  </Select.Option>
                );
              })}
            </Select>
            <Button
              type="primary"
              shape="round"
              size="middle"
              style={{ marginLeft: 20 }}
              onClick={async () => {
                try {
                  console.log('selectedRowKeys-----------', selectedRowKeys);
                  console.log('itemStatusValue-----------', itemStatusValue);

                  const res = await saveOrders(selectedRowKeys, itemStatusValue);
                  console.log('save success');
                  if (res.data.status == 'success') {
                    message.success('操作成功！');
                    productlist();
                    locationlist();
                    applierlist();
                  } else {
                    message.error('服务异常！');
                  }
                } catch (e) {
                  console.log(e);
                  message.error('服务异常！');
                }
              }}
            >
              发布/回传
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              type="primary"
              shape="round"
              size="middle"
              danger
              onClick={() => {
                setOptType('deleteBatch');
                showDeleteConfirm('deleteBatch');
              }}
            >
              删除
            </Button>
            <br />
            <br />
          </div>
        </div>
        <div className="content-body">
          <Table
            size="small"
            scroll={{ x: '100vw' }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.reference}
            bordered
            style={{
              height: getDomHeight(),
            }}
            scroll={{
              y: getDomHeight(),
              // x: '100vw',
            }}
            pagination={{
              pageSize: 15,
            }}
          />
        </div>
      </div>
    </div>
  );
}
