import React, { Component } from 'react';
import * as echarts from 'echarts';

let color = ['#ffffff', '#36fff6', '#ffe93a', '#67f95f'];
let oneArr = [{
    name: '地理信息中台',
    type: 0,
    symbol: 'path://M38.16448 558.0288v419.90144c0 33.01376 51.2 33.01376 51.2 0V558.0288c0-33.01376-51.2-33.01376-51.2 0zM327.39328 420.70016v557.23008c0 33.01376 51.2 33.01376 51.2 0V420.70016c0-33.01888-51.2-33.01888-51.2 0zM629.21216 558.0288v419.90144c0 33.01376 51.2 33.01376 51.2 0V558.0288c0-33.01376-51.2-33.01376-51.2 0zM936.92928 420.70016v557.23008c0 33.01376 51.2 33.01376 51.2 0V420.70016c0-33.01888-51.2-33.01888-51.2 0zM81.86368 414.58176l277.27872-277.27872h-36.20352c95.55968 95.5648 191.11424 191.1296 286.65856 286.69952 9.86112 9.86112 26.3424 9.86112 36.19328 0 99.6864-99.67616 199.35744-199.35232 299.02848-299.02848 23.36256-23.36768-12.83584-59.5712-36.1984-36.20352-99.68128 99.67616-199.35744 199.35232-299.02336 299.02848h36.19328L359.13728 101.09952c-9.86112-9.86112-26.3424-9.86112-36.20352 0L45.65504 378.37824c-23.36256 23.36768 12.84096 59.5712 36.20864 36.20352zM779.06944 87.3728h190.208l-25.6-25.6v178.70336c0 33.01888 51.2 33.01888 51.2 0V61.7728c0-13.95712-11.63776-25.6-25.6-25.6h-190.208c-33.01376 0-33.01376 51.2 0 51.2z',
    symbolSize: 190,
    itemStyle: {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0,
                color: '#ffe93a' // 0% 处的颜色
            }, {
                offset: 1,
                color: '#36fff6' // 100% 处的颜色
            }],
            global: false // 缺省为 false
        }
    }
}];
let erArr = [{
    name: '市城市管理局',
    type: 1
}, {
    name: '科大讯飞',
    type: 2
}, {
    name: '市水利局',
    type: 1
}, {
    name: '市政法委',
    type: 1
}, {
    name: '市自然资源和规划局',
    type: 1
}, {
    name: '市生态环境局',
    type: 1
}, {
    name: '市大数据局',
    type: 3
}, ];
let allArr = [...oneArr, ...erArr],
    dataArr = [];
// 点
allArr.forEach((el, ind) => {
    if (el.type > 0) {
        el.symbolSize = 50;
        el.symbol = "path://M544 552.325V800a32 32 0 0 1-32 32 31.375 31.375 0 0 1-32-32V552.325L256 423.037a32 32 0 0 1-11.525-43.512A31.363 31.363 0 0 1 288 368l224 128 222.075-128a31.363 31.363 0 0 1 43.525 11.525 31.988 31.988 0 0 1-11.525 43.513L544 551.038z m0 0,M64 256v512l448 256 448-256V256L512 0z m832 480L512 960 128 736V288L512 64l384 224z m0 0";
        el.itemStyle = {
            color: color[el.type],
        };
    }
    el.label = {
        normal: {
            show: true,
            position: "bottom",
            distance: 10,
            color: color[el.type],
        },
    };

});

// 圆形分区
function group(arr, r) {
    const newArray = [];
    const {
        length: arLen
    } = arr;
    arr.forEach((e, ind) => {
        // 按角度均匀分布
        const ang = 90 - (360 / arLen).toFixed(2) * (ind + 1);
        const x = (Math.cos(ang * Math.PI / 180)).toFixed(2) * r;
        const y = (Math.sin(ang * Math.PI / 180)).toFixed(2) * r;
        const x1 = (Math.cos(ang * Math.PI / 180)).toFixed(2) * (r - 5);
        const y1 = (Math.sin(ang * Math.PI / 180)).toFixed(2) * (r - 5);
        const x0 = (Math.cos(ang * Math.PI / 180)).toFixed(2) * (r - 30);
        const y0 = (Math.sin(ang * Math.PI / 180)).toFixed(2) * (r - 30);
        e.value = [x.toFixed(2), y.toFixed(2)]
        e.twoPoint = [
            [x1.toFixed(2), y1.toFixed(2)],
            [x0.toFixed(2), y0.toFixed(2)]
        ];
        newArray.push(e);
    });
    return newArray;
}

// 线配置
function linesConfig(arr) {
    const [dataArr, targetCoord] = [
        [],
        [0, 0]
    ];
    arr.forEach((el) => {
        function getFormatItem(start, end) {
            let item = [{
                    coord: el.twoPoint[start]
                }, // 起点
                {
                    coord: el.twoPoint[end],
                    lineStyle: {
                        color: color[el.type],
                        curveness: el.type === 3 ? 0.1 : 0,
                    },
                    effect: {
                        color: color[el.type]
                    }
                }, // 终点
            ]
            return item
        }
        switch (el.type) {
            case 0:
                break;
            case 1:
                dataArr.push(getFormatItem(0, 1));
                break;
            case 2:
                dataArr.push(getFormatItem(1, 0));
                break;
            case 3:
                dataArr.push(getFormatItem(0, 1));
                dataArr.push(getFormatItem(1, 0));
                break;
        }
    });
    return dataArr;
}

// 点分布
oneArr = group(oneArr, 0);
erArr = group(erArr, 40);

allArr = [...oneArr, ...erArr];
// 线坐标和配置
dataArr = linesConfig(allArr);

function generateData(totalNum, bigvalue, smallvalue, color) {
    let dataArr = [];
    for (var i = 0; i < totalNum; i++) {
        if (i % 2 === 0) {
            dataArr.push({
                name: (i + 1).toString(),
                value: bigvalue,
                itemStyle: {
                    normal: {
                        color: color,
                        borderWidth: 0,
                    }
                }
            });
        } else {
            dataArr.push({
                name: (i + 1).toString(),
                value: smallvalue,
                itemStyle: {
                    normal: {
                        color: "rgba(0,0,0,0)",
                        borderWidth: 0,
                    }
                }
            });
        }

    }
    return dataArr;
}

let dolitData = generateData(100, 25, 20, 'rgb(126,190,255)');
let threeData = generateData(6, 40, 10, '#2dc0c9');

function getOption(startAngle, radius) {
    let option = {
        backgroundColor: 'none',
        title: {
            text: 'title',
            left: 'center',
            textStyle: {
                color: '#fff'
            }
        },
        xAxis: {
            show: false,
            type: "value",
            max: 50,
            min: -51,
        },
        grid: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
        },
        yAxis: {
            show: false,
            type: "value",
            max: 50,
            min: -50,
        },
        series: [{
                name: "节点",
                type: "graph",
                silent: false,
                hoverAnimation: false, // 鼠标悬浮高亮
                cursor: 'default',
                coordinateSystem: "cartesian2d",
                z: 3,
                itemStyle: {
                    normal: {
                        shadowColor: "none",
                    },
                },
                emphasis: {
                    scale: false
                },
                data: allArr,
            },
            {
                name: "线图",
                type: "lines",
                hoverAnimation: false,
                silent: false,
                cursor: 'default',
                coordinateSystem: "cartesian2d",
                polyline: false, // 多线段
                z: 1,
                lineStyle: {
                    width: 2,
                    type: 'dashed',
                    curveness: 0,
                },
                effect: {
                    show: true,
                    period: 8, //箭头指向速度，值越小速度越快
                    trailLength: 0, //特效尾迹长度[0,1]值越大，尾迹越长重
                    symbol: 'arrow', //箭头图标
                    symbolSize: 6
                },
                emphasis: {
                    lineStyle: {
                        type: 'dashed',
                    }
                },
                data: dataArr,
            },
            {
                name: '不动外圆',
                type: 'pie',
                zlevel: 4,
                silent: true,
                radius: ['60%', '59%'],
                label: {
                    normal: {
                        show: false
                    },
                },
                labelLine: {
                    normal: {
                        show: false
                    }
                },
                data: dolitData
            },
            {
                type: 'pie',
                name: '旋转圆',
                zlevel: 20,
                silent: true,
                radius: ['50%', '49%'],
                hoverAnimation: false,
                startAngle: startAngle,
                data: threeData,
                label: {
                    normal: {
                        show: false
                    },
                },
                labelLine: {
                    normal: {
                        show: false
                    }
                },
            },
            {
                name: '缩放圆',
                type: 'pie',
                zlevel: 4,
                silent: true,
                radius: [(radius + 1) + '%', radius + '%'],
                label: {
                    normal: {
                        show: false
                    },
                },
                labelLine: {
                    normal: {
                        show: false
                    }
                },
                data: dolitData
            },
        ],
    };
    return option;
}

let startAngle = 50; // 初始旋转角度
let [minradius, radius, maxradius] = [24, 24, 28] // 初始缩放尺寸
let isBig = true // 缩放动画 标识

function draw(myChart) {
  startAngle = startAngle - 5
  if (isBig) {
      radius = radius + 0.5
      if (radius > maxradius) {
          isBig = false
      }
  } else {
      radius = radius - 0.5
      if (radius < minradius) {
          isBig = true
      }
  }
  let option = getOption(startAngle, radius)
  myChart.setOption(option, true);
}
class Echarts extends Component {
  state = {};

  chart = null;
  timer = null;

  componentDidMount() {
    const { chartId, option } = this.props; //继承父级传递的值
    const chartIdDiv = document.getElementById(chartId);
    if (chartIdDiv) {
      this.chart = echarts.init(chartIdDiv); //实例化echats画布
      if (this.chart) {
        this.renderChart(this.chart, option); //加载数据
        this.handleResize()
      }
    }
    window.addEventListener('resize', this.handleResize, false); //监听改变画布大小
  }

  /**
   * @description: 根据父级传递的值，是否更新画布
   * @param {type}
   * @return {type}
   */
  componentDidUpdate(prevProps) {
    console.log('echart componentDidUpdate')
    const { option } = this.props;
    if (prevProps.option !== option && this.chart) {
      this.renderChart(this.chart, option);
    }
  }
  componentWillReceiveProps() {
    console.log('echart componentWillReceiveProps')

  }

  /**
   * @description: 注销时，去除监听事件以及销毁echats
   * @param {type}
   * @return {type}
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false);
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }

  // 放大缩小重新布局
  handleResize = () => {
    if (this.chart) {
      setTimeout(() => {
        this.chart.resize();
      });
    }
  };
  // 图表配置及渲染
  renderChart = (chart, option) => {
    const { handleClick } = this.props;
    chart.clear();
    draw(chart)
    this.timer = setInterval(()=>{
      draw(chart)
    }, 200);
    // chart.setOption(option,true);
    chart.off('click'); // 要是不加上这行，事件会重复n次
    chart.on('click', (params) => {
      if (handleClick) {
        handleClick(params);
      }
    });
  };

  render() {
    const { chartId } = this.props;
    return <div id={chartId} style={{ width: '100%', height: '100%' }} />;
  }
}

export default Echarts;
