import React from 'react';
import moment from 'moment';
import http from '../../../http';
import filterItem from '../../../utils/filterItem';
import { Table, message, Button, Modal, Form, Input, Select } from 'antd';
import BreadNav from '../../../components/breadNav';
import { getDomHeight } from '../../../utils/common';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss';
const { confirm } = Modal;
const { Search } = Input;

export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      content: { title: '' },
      type: '',
      staticData: [],
      data: [],
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      password1: '',
      password2: '',
      whichItem: '',
      status: '',
    };
    this.formRef = React.createRef();

    //表头
    this.columns = [
      {
        title: '系统名称',
        dataIndex: 'name',
      },
      {
        title: '数量类型',
        dataIndex: 'dataType',
      },
      {
        title: '导入方式',
        dataIndex: 'importTypeStr',
      },
      {
        title: '服务器地址',
        dataIndex: 'address',
      },
      {
        title: '操作',
        dataIndex: '',
        render: (text, record) => {
          let { status } = this.state;
          return (
            <div>
              <Button
                type="link"
                onClick={(e) => {
                  if (!status) return;
                  let { id, name, dataType, importType, address } = record;
                  this.setState(
                    {
                      name,
                      dataType,
                      importType,
                      address,
                      content: { title: '编辑用户信息' },
                      type: 'edit',
                      isModalVisible: true,
                      status: 'edit',
                      whichItem: id,
                    },
                    () => {
                      if (this.formRef.current && this.formRef.current.setFieldsValue) {
                        this.formRef.current.setFieldsValue({
                          name,
                          dataType,
                          importType,
                          address,
                        });
                      }
                    }
                  );
                }}
              >
                编辑
              </Button>
              <Button
                type="link"
                danger
                onClick={(e) => {
                  this.showDeleteConfirm(record);
                }}
              >
                删除
              </Button>
            </div>
          );
        },
      },
    ];
  }

  //对话框
  showDeleteConfirm = (record) => {
    let _this = this;
    confirm({
      title: '您确定要删除该项数据吗？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk() {
        _this.deleteItem(record.id);
      },
      onCancel() {},
    });
  };
  //删除
  deleteItem = async (id) => {
    const response = await http.customPost(`/nkapi/schedulesync/importconfigDelete/${id}`);
    if (response.data.Data) {
      message.success('操作成功！');
      this.itemList();
    } else {
      message.error('操作失败！');
    }
  };
  //列表
  itemList = () => {
    let _this = this;

    http.get(
      'data/schedulesync/importconfig/',
      {
        format: 'json',
        _search: false,
        rows: 10000,
        page: 1,
        sidx: 'id',
        sord: 'asc',
      },
      function (response) {
        if (response.data && response.data.rows) {
          let data = response.data.rows;
          let owners = filterItem(data, 'name');
          owners.push('');
          _this.setState({
            staticData: data,
            data,
            owners,
            status: 'ready',
          });
        }
      }
    );
  };
  //新建
  addItem = () => {
    let { name, dataType, importType, address } = this.state;
    http.post(
      'data/schedulesync/importconfig/',
      {
        _save: '保存',
        name,
        dataType,
        importType,
        address,
      },
      (response) => {
        console.log(response, 'response');
        if (response.data.status == 'success') {
          message.success('操作成功！');
          this.setState(
            {
              isModalVisible: false,
            },
            () => {
              this.itemList();
            }
          );
        } else {
          message.error('请填写完整信息！');
        }
      }
    );
  };
  //编辑
  editItem = () => {
    let { name, dataType, importType, address, whichItem } = this.state;
    let _this = this;
    http.post(
      `data/schedulesync/importconfig/update/`,
      {
        // csrfmiddlewaretoken: 'JjlcBIltcrLPzyrqlz1aZjRNlMiLdK65GjoVJKf75vHAnKMPRQtU88T47HBAqWjo',
        // _save: '保存',
        id: whichItem,
        name,
        dataType,
        importType,
        address,
      },
      function (response) {
        console.log(response);
        if (response.data && response.data.status == 'success') {
          message.success('操作成功！');
          _this.setState(
            {
              isModalVisible: false,
            },
            () => {
              _this.itemList();
            }
          );
        } else {
          message.error('请填写完整信息！');
        }
      }
    );
  };
  //表单提交
  handleOK = () => {
    let { type } = this.state;
    if (type === 'add') {
      this.addItem();
    } else if (type === 'edit') {
      this.editItem();
    }
  };
  //模糊查询
  handleSearch = (searched) => {
    let { staticData } = this.state;
    let arr = staticData.filter((item) => item.username.includes(searched));
    this.setState({
      data: arr,
    });
  };

  componentDidMount() {
    this.itemList();
  }

  render() {
    let {
      username,
      first_name,
      last_name,
      email,
      password1,
      password2,
      isModalVisible,
      content,
      data,
    } = this.state;
    return (
      <div className="modal-list">
        <Modal
          title={content.title}
          visible={isModalVisible}
          maskClosable={false}
          okText="提交"
          cancelText="取消"
          onOk={this.handleOK}
          onCancel={() => {
            this.setState({ isModalVisible: false });
          }}
        >
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            ref={this.formRef}
            initialValues={{
              username,
              first_name,
              last_name,
              email,
            }}
            onValuesChange={(changedValues, allValues) => {
              this.setState({
                ...changedValues,
              });
            }}
          >
            <Form.Item
              label="名称"
              name="name"
              // rules={[{ required: true, message: '请输入名称' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="数据类型" name="dataType">
              <Input />
            </Form.Item>
            <Form.Item label="导入类型" name="importType">
              <Select>
                {[
                  { name: '导入', value: 0 },
                  { name: '不导入', value: 1 },
                ].map((item) => (
                  <Select.Option value={item.value} key={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="服务器地址" name="address">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
        <div className="layout-content">
          <div className="content-header">
            <BreadNav />
            <Button
              type="primary"
              shape="round"
              size="middle"
              style={{ marginBottom: 16 }}
              onClick={() => {
                this.setState(
                  {
                    name: '',
                    dataType: '',
                    importType: '',
                    address: '',
                    content: { title: '新增用户信息' },
                    type: 'add',
                    isModalVisible: true,
                    status: 'add',
                    // whichItem: id,
                  },
                  () => {
                    if (this.formRef.current && this.formRef.current.setFieldsValue) {
                      this.formRef.current.setFieldsValue({
                        name: '',
                        dataType: '',
                        importType: '',
                        address: '',
                      });
                    }
                  }
                );
              }}
            >
              新增
            </Button>
          </div>
          <div className="content-body">
            <Table
              size="small"
              pagination={{
                pageSize: 15,
              }}
              columns={this.columns}
              dataSource={data}
              style={{
                height: getDomHeight(),
              }}
              scroll={{
                y: getDomHeight(),
                // x: '100vw',
              }}
              rowKey={(record) => record.id}
              bordered
            />
          </div>
        </div>
      </div>
    );
  }
}
