import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { logReducer } from './reducer';

const defaultState = {
  authMenus: [
    'baseInfo',
    'calendars',
    'workshift',
    'user',
    'BOM',
    'customer',
    'location',
    'production',
    'applier',
    'productionApplier',
    'inventory',
    'execution',
    'planningList',
    'skill',
    'distribution',
    'resource',
    'dispatchEngine',
    'whatif',
    'orders',
    'productionPlan',
    'capacityDispatch',
    'planningGantt',
    'materialPlan',
    'purchaseorder',
    'report',
    'inventoryPlanning',
    'productionLine',
    'productionList',
    'resourceReport',
    'logisticsCenter',
    'system',
    'importConfig',
    'device',
    'people'
  ],
  currentMenus: [{}],
};

export const store = createStore(logReducer, defaultState, compose(applyMiddleware(thunk, logger)));
