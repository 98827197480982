import React from 'react';
import * as echarts from 'echarts';
import { Table, Progress, Button } from 'antd';
import BreadNav from '../../../components/breadNav';
import SearchGroup from '../../../components/SearchGroup';
import http from '../../../http';
import ExportJsonExcel from 'js-export-excel';
import { searchEngine, getDomHeight } from '../../../utils/common';
import './index.scss';
export default class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      at: 0,
      size: 10,
      data: [],
      dataCopy: [],
      loadPercent: 0,
      echartDate0: [],
      echartRepertory0: [],
      echartSafety0: [],
      echartDate1: [],
      echartRepertory1: [],
      echartSafety1: [],
      echartDate2: [],
      echartRepertory2: [],
      echartSafety2: [],
      echartDate3: [],
      echartRepertory3: [],
      echartSafety3: [],
      echartDate4: [],
      echartRepertory4: [],
      echartSafety4: [],
      echartDate5: [],
      echartRepertory5: [],
      echartSafety5: [],
      echartDate6: [],
      echartRepertory6: [],
      echartSafety6: [],
      echartDate7: [],
      echartRepertory7: [],
      echartSafety7: [],
      echartDate8: [],
      echartRepertory8: [],
      echartSafety8: [],
      echartDate9: [],
      echartRepertory9: [],
      echartSafety9: [],
    };
    this.columns = [
      {
        width: 200,
        title: '产品名称',
        dataIndex: 'item',
        key: 'item',
      },
      {
        width: 300,
        title: '地点',
        dataIndex: 'location',
        key: 'location',
      },
      {
        width: 1100,
        title: '库存数据',
        key: 'graph',
        classNmae: 'report-chart',
        // render: () => <div className={`echart`} ></div>,
        render: (text, record, idx) => {
          if (idx === 0) {
            return (
              <div className="echart_cont">
                <div className="echart_left">
                  <ul>
                    <li>日期</li>
                    <li>库存</li>
                    <li>安全库存</li>
                  </ul>
                </div>
                <div className="echart_right">
                  <ul>
                    <li>
                      <div></div>
                      {this.state.echartDate0.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartRepertory0.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartSafety0.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            );
          } else if (idx === 1) {
            return (
              <div className="echart_cont">
                <div className="echart_left">
                  <ul>
                    <li>日期</li>
                    <li>库存</li>
                    <li>安全库存</li>
                  </ul>
                </div>
                <div className="echart_right">
                  <ul>
                    <li>
                      <div></div>
                      {this.state.echartDate1.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartRepertory1.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartSafety1.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            );
          } else if (idx === 2) {
            return (
              <div className="echart_cont">
                <div className="echart_left">
                  <ul>
                    <li>日期</li>
                    <li>库存</li>
                    <li>安全库存</li>
                  </ul>
                </div>
                <div className="echart_right">
                  <ul>
                    <li>
                      <div></div>
                      {this.state.echartDate2.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartRepertory2.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartSafety2.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            );
          } else if (idx === 3) {
            return (
              <div className="echart_cont">
                <div className="echart_left">
                  <ul>
                    <li>日期</li>
                    <li>库存</li>
                    <li>安全库存</li>
                  </ul>
                </div>
                <div className="echart_right">
                  <ul>
                    <li>
                      <div></div>
                      {this.state.echartDate3.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartRepertory3.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartSafety3.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            );
          } else if (idx === 4) {
            return (
              <div className="echart_cont">
                <div className="echart_left">
                  <ul>
                    <li>日期</li>
                    <li>库存</li>
                    <li>安全库存</li>
                  </ul>
                </div>
                <div className="echart_right">
                  <ul>
                    <li>
                      <div></div>
                      {this.state.echartDate4.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartRepertory4.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartSafety4.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            );
          } else if (idx === 5) {
            return (
              <div className="echart_cont">
                <div className="echart_left">
                  <ul>
                    <li>日期</li>
                    <li>库存</li>
                    <li>安全库存</li>
                  </ul>
                </div>
                <div className="echart_right">
                  <ul>
                    <li>
                      <div></div>
                      {this.state.echartDate5.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartRepertory5.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartSafety5.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            );
          } else if (idx === 6) {
            return (
              <div className="echart_cont">
                <div className="echart_left">
                  <ul>
                    <li>日期</li>
                    <li>库存</li>
                    <li>安全库存</li>
                  </ul>
                </div>
                <div className="echart_right">
                  <ul>
                    <li>
                      <div></div>
                      {this.state.echartDate6.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartRepertory6.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartSafety6.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            );
          } else if (idx === 7) {
            return (
              <div className="echart_cont">
                <div className="echart_left">
                  <ul>
                    <li>日期</li>
                    <li>库存</li>
                    <li>安全库存</li>
                  </ul>
                </div>
                <div className="echart_right">
                  <ul>
                    <li>
                      <div></div>
                      {this.state.echartDate7.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartRepertory7.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartSafety7.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            );
          } else if (idx === 8) {
            return (
              <div className="echart_cont">
                <div className="echart_left">
                  <ul>
                    <li>日期</li>
                    <li>库存</li>
                    <li>安全库存</li>
                  </ul>
                </div>
                <div className="echart_right">
                  <ul>
                    <li>
                      <div></div>
                      {this.state.echartDate8.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartRepertory8.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartSafety8.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            );
          } else if (idx === 9) {
            return (
              <div className="echart_cont">
                <div className="echart_left">
                  <ul>
                    <li>日期</li>
                    <li>库存</li>
                    <li>安全库存</li>
                  </ul>
                </div>
                <div className="echart_right">
                  <ul>
                    <li>
                      <div></div>
                      {this.state.echartDate9.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartRepertory9.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                    <li>
                      <div></div>
                      {this.state.echartSafety9.map((item, index) => {
                        return <div key={index}>{item}</div>;
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            );
          }
        },
      },
    ];
  }
  // timer = null;
  //获取资源数据
  itemList = () => {
    let _this = this;
    http.get(
      'buffer/',
      {
        mode: 'graph',
        format: 'json',
        _search: false,
        rows: 1000,
        page: 1,
        sidx: 'resource',
        sord: 'asc',
      },
      function (response) {
        if (!response.data.rows) return;

        _this.setState(
          {
            data: response.data.rows,
          },
          () => {
            _this.cellChart();
            _this.setDataCopy(response.data.rows);
          }
        );
      }
    );
  };
  setDataCopy = (data) => {
    this.setState({
      dataCopy: data,
    });
  };

  //  绘制图表
  paintChart = (ele, data) => {
    let myChart = echarts.init(ele);
    let option;
    let { dates, safe, init, production, used, final } = data;

    var arr = dates.splice(-dates.length + 14);
    dates = arr;

    option = {
      tooltip: {
        //存在问题
        show: true,
        trigger: 'axis',
        formatter: function (params) {
          let index = params[0].dataIndex;
          let date = params[0].axisValue;
          //期初、期末、安全库存
          let res = `<div>
                     <div>${date}</div>
                     库存    ${init[index]}</br>
                     安全库存     ${safe[index]}</br>`;

          //  处理没有date情况乱码问题
          if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
            return res;
          } else return '';
        },
      },
      grid: {
        top: '40px',
        left: '30px',
        right: '60px',
        bottom: '15px',
        containLabel: true,
      },
      xAxis: {
        name: '日期',
        type: 'category',
        data: dates,
        // show: false,
        axisLabel: {
          interval: 16, //设置间隔为0
          showMaxLabel: true,
        },
      },
      yAxis: {
        name: '数量',
        type: 'value',
        splitLine: { show: false },
        splitNumber: 1,
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
      },
      series: [
        {
          name: '安全库存',
          data: safe,
          type: 'line',
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                color: 'red',
              },
            },
          },
        },
        {
          name: '开始库存',
          data: init,
          type: 'line',
          symbol: 'none',
          itemStyle: {
            normal: {
              color: 'green',
              lineStyle: {
                color: 'green',
              },
            },
          },
        },
        {
          name: '生产数',
          data: production,
          type: 'line',
          symbol: 'none',
          itemStyle: {
            normal: {
              color: 'blue',
              lineStyle: {
                color: 'blue',
              },
            },
          },
        },
        {
          name: '消耗库存',
          data: used,
          type: 'line',
          symbol: 'none',
          itemStyle: {
            normal: {
              color: 'yellow',
              lineStyle: {
                color: 'yellow',
              },
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  };

  //  处理数据
  handleData = (data) => {
    let dates = [];
    let safe = [];
    let init = [];
    let production = [];
    let used = [];
    let final = [];
    for (let k in data) {
      dates.push(k);
      init.push(data[k][0]);
      safe.push(data[k][2]);
      used.push(data[k][4]);
      production.push(data[k][7]);
      final.push(data[k][11]);
    }
    return { dates, safe, init, production, used, final };
  };

  //单元格中生成图表
  cellChart = () => {
    let dataNew = [];
    let { at, size, data } = this.state;
    if (!data) {
      return false;
    }
    // console.log('at',at);//0 10 20
    // console.log('size',size);//10 10 10

    let echartDate0 = [];
    let echartRepertory0 = [];
    let echartSafety0 = [];
    let echartDate1 = [];
    let echartRepertory1 = [];
    let echartSafety1 = [];
    let echartDate2 = [];
    let echartRepertory2 = [];
    let echartSafety2 = [];
    let echartDate3 = [];
    let echartRepertory3 = [];
    let echartSafety3 = [];
    let echartDate4 = [];
    let echartRepertory4 = [];
    let echartSafety4 = [];
    let echartDate5 = [];
    let echartRepertory5 = [];
    let echartSafety5 = [];
    let echartDate6 = [];
    let echartRepertory6 = [];
    let echartSafety6 = [];
    let echartDate7 = [];
    let echartRepertory7 = [];
    let echartSafety7 = [];
    let echartDate8 = [];
    let echartRepertory8 = [];
    let echartSafety8 = [];
    let echartDate9 = [];
    let echartRepertory9 = [];
    let echartSafety9 = [];
    data = dataNew.concat(data);
    dataNew = data.splice(at, size);
    // console.log('at',at);
    // console.log('size',size);
    data = dataNew.concat(data);
    data.map((item, index) => {
      if (index === 0) {
        Object.keys(item).forEach((key) => {
          //过滤出为日期格式的数据
          if (parseInt(key)) {
            echartDate0.push(key);
            echartRepertory0.push(item[key][0]);
            echartSafety0.push(item[key][2]);
          }
        });
      }
      if (index === 1) {
        Object.keys(item).forEach((key) => {
          //过滤出为日期格式的数据
          if (parseInt(key)) {
            echartDate1.push(key);
            echartRepertory1.push(item[key][0]);
            echartSafety1.push(item[key][2]);
          }
        });
      }
      if (index === 2) {
        Object.keys(item).forEach((key) => {
          //过滤出为日期格式的数据
          if (parseInt(key)) {
            echartDate2.push(key);
            echartRepertory2.push(item[key][0]);
            echartSafety2.push(item[key][2]);
          }
        });
      }
      if (index === 3) {
        Object.keys(item).forEach((key) => {
          //过滤出为日期格式的数据
          if (parseInt(key)) {
            echartDate3.push(key);
            echartRepertory3.push(item[key][0]);
            echartSafety3.push(item[key][2]);
          }
        });
      }
      if (index === 4) {
        Object.keys(item).forEach((key) => {
          //过滤出为日期格式的数据
          if (parseInt(key)) {
            echartDate4.push(key);
            echartRepertory4.push(item[key][0]);
            echartSafety4.push(item[key][2]);
          }
        });
      }
      if (index === 5) {
        Object.keys(item).forEach((key) => {
          //过滤出为日期格式的数据
          if (parseInt(key)) {
            echartDate5.push(key);
            echartRepertory5.push(item[key][0]);
            echartSafety5.push(item[key][2]);
          }
        });
      }
      if (index === 6) {
        Object.keys(item).forEach((key) => {
          //过滤出为日期格式的数据
          if (parseInt(key)) {
            echartDate6.push(key);
            echartRepertory6.push(item[key][0]);
            echartSafety6.push(item[key][2]);
          }
        });
      }
      if (index === 7) {
        Object.keys(item).forEach((key) => {
          //过滤出为日期格式的数据
          if (parseInt(key)) {
            echartDate7.push(key);
            echartRepertory7.push(item[key][0]);
            echartSafety7.push(item[key][2]);
          }
        });
      }
      if (index === 8) {
        Object.keys(item).forEach((key) => {
          //过滤出为日期格式的数据
          if (parseInt(key)) {
            echartDate8.push(key);
            echartRepertory8.push(item[key][0]);
            echartSafety8.push(item[key][2]);
          }
        });
      }
      if (index === 9) {
        Object.keys(item).forEach((key) => {
          //过滤出为日期格式的数据
          if (parseInt(key)) {
            echartDate9.push(key);
            echartRepertory9.push(item[key][0]);
            echartSafety9.push(item[key][2]);
          }
        });
      }
    });
    this.setState({
      echartDate0: echartDate0,
      echartRepertory0: echartRepertory0,
      echartSafety0: echartSafety0,
      echartDate1: echartDate1,
      echartRepertory1: echartRepertory1,
      echartSafety1: echartSafety1,
      echartDate2: echartDate2,
      echartRepertory2: echartRepertory2,
      echartSafety2: echartSafety2,
      echartDate3: echartDate3,
      echartRepertory3: echartRepertory3,
      echartSafety3: echartSafety3,
      echartDate4: echartDate4,
      echartRepertory4: echartRepertory4,
      echartSafety4: echartSafety4,
      echartDate5: echartDate5,
      echartRepertory5: echartRepertory5,
      echartSafety5: echartSafety5,
      echartDate6: echartDate6,
      echartRepertory6: echartRepertory6,
      echartSafety6: echartSafety6,
      echartDate7: echartDate7,
      echartRepertory7: echartRepertory7,
      echartSafety7: echartSafety7,
      echartDate8: echartDate8,
      echartRepertory8: echartRepertory8,
      echartSafety8: echartSafety8,
      echartDate9: echartDate9,
      echartRepertory9: echartRepertory9,
      echartSafety9: echartSafety9,
    });
  };

  componentDidMount() {
    this.itemList();

    this.timer = setInterval(() => {
      this.setState({ loadPercent: (this.state.loadPercent += 1) });
    }, 100);
  }

  componentDidUpdate() {
    // console.log('componentDidUpdate');
    if (this.state.data.length == 0 && this.state.loadPercent == 99) {
      clearInterval(this.timer);
    }

    if (this.state.data.length > 0 && this.state.loadPercent == 99) {
      this.setState({ loadPercent: 100 });
    }
  }
  //导出
  downloadExcel = () => {
    const datas = this.state.data ? this.state.data : ''; //表格数据
    var option = {};
    let dataTable = [];
    var keyArr = [];
    if (datas) {
      //过滤出日期数据
      var newData = datas[0];
      Object.keys(newData).forEach((key) => {
        if (parseInt(key)) {
          keyArr.push(key);
        }
      });
      datas.map((val) => {
        if (val) {
          let obj = {
            item_val: val.item,
            location_val: val.location,
          };
          Object.keys(val).forEach((key) => {
            if (parseInt(key)) {
              obj[key] = `库存：${String(val[key][0])}安全库存：${String(val[key][2])}`;
            }
          });
          dataTable.push(obj);
        }
      });
    }
    option.fileName = '库存报告数据';
    // console.log(keyArr);

    option.datas = [
      {
        sheetData: dataTable,
        sheetName: 'sheet',
        sheetFilter: ['item_val', 'location_val', ...keyArr],
        sheetHeader: ['产品名称', '地点', ...keyArr],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  };
  //进度条
  progressFun = () => {
    let { data, loadPercent } = this.state;
    if (data.length == 0 && loadPercent < 100) {
      return (
        <div className="load-percent">
          {loadPercent < 100 && data.length == 0 ? '统计中...' : '完成'}
          <Progress percent={loadPercent} />
        </div>
      );
    }
  };
  render() {
    let { data, loadPercent } = this.state;
    const onSearch = (keys) => {
      console.log('onSearch');
      if (keys.every((key) => !key.value)) {
        this.setState({ data: this.state.dataCopy });
        this.cellChart();
      } else {
        const arr = searchEngine(this.state.dataCopy, keys);
        let that = this;
        this.setState(
          {
            data: arr,
          },
          function () {
            that.cellChart();
          }
        );
      }
    };
    return (
      <div className="inventory-report">
        {this.progressFun()}
        <div className="layout-content">
          <div className="content-header">
            <BreadNav />
            <SearchGroup
              items={[
                {
                  label: '产品名称',
                  name: 'item',
                },
                {
                  label: '地点',
                  name: 'location',
                },
              ]}
              onSearch={onSearch}
            ></SearchGroup>
            <Button
              type="primary"
              shape="round"
              onClick={this.downloadExcel}
              style={{ position: 'absolute', top: '54px', left: '650px' }}
            >
              导出
            </Button>
            <br />
          </div>
          <div className="content-body">
            {loadPercent >= 100 && data.length >= 0 && (
              <Table
                columns={this.columns}
                dataSource={data}
                size="small"
                rowKey={(record) => {
                  return record.buffer;
                }}
                style={{
                  height: getDomHeight(),
                }}
                scroll={{
                  y: getDomHeight(),
                  // x: '100vw',
                }}
                onChange={(pagination, filters, sorter, extra) => {
                  // console.log('change');
                  if (extra.action === 'paginate') {
                    this.setState(
                      {
                        at: (pagination.current - 1) * pagination.pageSize,
                      },
                      () => {
                        this.cellChart();
                      }
                    );
                  }
                }}
                bordered
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
